import React, { useEffect, useState } from "react";

import { TfiEmail } from "react-icons/tfi";
import { Button, Form, Input } from "antd";
import { DataRequestAction } from "../../redux/actions/CommonHttp";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const Index = () => {
  const navigate = useNavigate();
  const CookiesData = Cookies.get("userInfo");

  const [ip, setIp] = useState();

  const getIp = async () => {
    // Connect ipapi.co with fetch()
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    // Set the IP address to the constant `ip`
    setIp(data.ip);
  };
  useEffect(() => {
    getIp();
  }, []);

  const dispatch = useDispatch();
  const {
    finalResult,
    addCalculationSpin,
    addNewCalculation_success,
    ResultObj,
    materialCalculationLinks,
    staticLanguage,
    resultGuid,
  } = useSelector((state) => state.questionR);
  console.log("====staticLanguage====", staticLanguage);
  const seeInvoive = (fullName, email) => {
    let currentDate = new Date();
    const options = {
      timeZone: "Europe/Stockholm", // Swedish time zone (GMT+2)
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };

    const swedishTime = new Intl.DateTimeFormat("sv-SE", options).format(currentDate);

    let obj = CookiesData ? JSON.parse(CookiesData) : null;
    const data = {
      calculationObj: finalResult,
      orgObj: ResultObj,
      fullName: obj && obj.fullName ? obj.fullName : fullName ? fullName : "",
      email: obj && obj.email ? obj.email : email ? email : "",
      userData: {
        ip: ip,
        date: currentDate,
        swedishTime: swedishTime,
      },
    };

    if (resultGuid) {
      data["guid"] = resultGuid;
      dispatch(DataRequestAction("PUT", "updateCalculation", data, "addNewCalculation", "", "", false));
    } else {
      dispatch(DataRequestAction("POST", "addNewCalculation", data, "addNewCalculation", "", "", false));
    }

    dispatch({ type: "wizardComplete_RESET" });
  };

  const onFinish = (values) => {
    Cookies.set(
      "userInfo",
      JSON.stringify({
        fullName: values.fullName,
        email: values.email,
      }),
      { expires: 360 }
    );

    seeInvoive(values.fullName, values.email);
  };
  if (addNewCalculation_success) {
    if (materialCalculationLinks !== null) {
      navigate(`/result/${materialCalculationLinks}`);
      dispatch({ type: "addNewCalculation_RESET" });
    }
  }
  return (
    <div className="px-10 xl:container mx-auto">
      <div className="mt-10 md:w-[600px] w-full mx-auto">
        <div className="bg-black mx-auto rounded-full h-16 w-16 flex flex-col items-center justify-center">
          <TfiEmail className="text-white" size={20} />
        </div>
        <h1 className="font-bold text-3xl mt-12 text-center">{staticLanguage.cookiesPageTitle}</h1>
        <p className="text-center font-regular mt-3 mb-10">{staticLanguage.cookiesPageContent}</p>
        <Form onFinish={onFinish} className="mx-auto" layout="vertical" style={{ width: "300px" }}>
          <Form.Item
            label={staticLanguage.nameLabel}
            name="fullName"
            rules={[{ required: true, message: staticLanguage.nameErrorMessage }]}
          >
            <Input placeholder={staticLanguage.namePlaceholder} className="font-regular  h-10" />
          </Form.Item>
          <Form.Item
            label={staticLanguage.emailLabel}
            name="email"
            rules={[
              { required: true, message: staticLanguage.emailRequiredError },
              {
                type: "email",
                message: staticLanguage.emailTypeError,
              },
            ]}
          >
            <Input placeholder={staticLanguage.emailPlaceholder} className="font-regular  h-10" />
          </Form.Item>
          <Button
            className="w-full bg-black mb-8"
            type="primary"
            htmlType="submit"
            size="large"
            loading={addCalculationSpin}
            disabled={addCalculationSpin}
          >
            {staticLanguage.seeResult}
          </Button>
          <p
            onClick={() => {
              seeInvoive();
            }}
            className="font-bold text-center mb-10 cursor-pointer"
          >
            {staticLanguage.skipButton}
          </p>
        </Form>
      </div>
    </div>
  );
};

export default Index;
