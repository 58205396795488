import { Modal } from "antd";
import React from "react";
import Questions from "../questions";
import { IoMdCloseCircle } from "react-icons/io";
const Index = ({ open, setShowModal }) => {
  return (
    <>
      {open && (
        <Modal
          closeIcon={
            <IoMdCloseCircle onClick={() => setShowModal(!open)} size={30} />
          }
          footer={null}
          width={"1000px"}
          open={open}
        >
          <Questions width="w-full" popover={open} />
        </Modal>
      )}
    </>
  );
};

export default Index;
