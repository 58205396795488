import React from "react";
import CementImage from "../../assets/images/download.jpeg";
const Card = ({
  Item,
  onClick,
  imageUrl,
  className = "",
  bg = "bg-[#FFFFFF]",
}) => {
  const { title, cardDescription } = Item;
  return (
    <div
      onClick={onClick}
      className={`border border-[#00000033] hover:border-[#00000099] border rounded-lg overflow-hidden cursor-pointer ${className}`}
    >
      <div>
        <img
          src={imageUrl !== "" ? imageUrl : CementImage}
          alt=""
          width="100%"
         
        />
      </div>
      <div className={`${className} ${bg} p-6`}>
        <p className="text-[16px] text-[#000000cc]">{title}</p>
        {cardDescription && (
          <p className="text-[12px] text-[#000000cc]">{cardDescription}</p>
        )}
      </div>
    </div>
  );
};

export default Card;
