import React from "react";
import { Checkbox } from "antd";
import wallIcon from "../../../assets/images/wallIcon.png";
import { useDispatch } from "react-redux";
import floorIcon from "../../../assets/images/Floor.png";
import cellingIcon from "../../../assets/images/celling.png";

const CheckBoxCard = ({ detail, renderButtons }) => {
  const { question, content, options, questionType } = detail;
  const dispatch = useDispatch();
  const radioButtons = (content, checkboxName, icon, checked) => {
    return (
      <Checkbox
        className={`relative  rounded-lg w-full h-16 flex items-center px-4 gap-2 cursor-pointer ${
          checked
            ? "border-black  border-2 bg-[#E6EEF5]"
            : "bg-white border border-black"
        }`}
        id={checkboxName}
        checked={checked}
        value={checkboxName}
        onChange={(e) => {
          if (questionType) {
            dispatch({
              type: `${questionType}_SUCCESS`,
              payload: {
                _id: e.target.value,
                checked: e.target.checked,
              },
              detail,
            });
          } else {
            dispatch({
              type: "SelectedValueCheckbox",
              payload: { id: e.target.value, checked: e.target.checked },
            });
          }
        }}
      >
        {content}
        <img className="absolute top-3 right-4" src={icon} alt="" />
      </Checkbox>
    );
  };
  const renderButton = () => {
    if (options && options.length > 0) {
      const ImagesArray = [wallIcon, floorIcon, cellingIcon];
      return options.map((Item, index) => {
        const { title, _id, checked } = Item;
        return radioButtons(title, _id, ImagesArray[index], checked);
      });
    }
  };
  return (
    <div className="roll-out-div-fade">
      <h2 className="md:text-2xl text-lg font-bold roll-out-text">
        {question}
      </h2>
      <div className="roll-out-div">
        <div className="grid gap-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 my-10">
          {renderButton()}
        </div>
        <p className="my-8 text-[#00000099]">{content}</p>
        {renderButtons()}
      </div>
    </div>
  );
};

export default CheckBoxCard;
