import React, { useEffect, useState } from "react";
import { Form, InputNumber, Slider } from "antd";
import { useDispatch, useSelector } from "react-redux";

const SelectRange = ({ detail, renderButtons }) => {
  const { staticLanguage } = useSelector((state) => state.questionR);
  const [form] = Form.useForm();
  const [rangeValue, setRangeValue] = useState(0);
  const { question, content, _id } = detail;
  const dispatch = useDispatch();

  const onValueChange = (value) => {
    dispatch({
      type: "SetRangeValue",
      payload: { id: _id, value: value },
    });
  };

  useEffect(() => {
    dispatch({
      type: "SetRangeValue",
      payload: { id: _id, value: 0 },
    });
  }, []);
  return (
    <div className="roll-out-div-fade">
      <h2 className="md:text-[32px] text-[24px] font-bold mb-6 roll-out-text">
        {question}
      </h2>
      <div className="roll-out-div">
        <Form
          layout="vertical"
          className="flex flex-col justify-center items-center"
        >
          <p className="font-regular text-2xl ">
            {rangeValue} {staticLanguage.sqm}
          </p>
          <Form.Item className="md:w-[600px] w-full" name="sqm">
            <Slider
              min={0}
              max={150}
              onChange={(e) => {
                onValueChange(e);
                setRangeValue(e);
              }}
            />
          </Form.Item>
          {/* <Form.Item className="md:w-[200px]  w-full" name="sqm">
          <InputNumber
            defaultValue={50}
            className="w-full"
            prefix="Sqm : "
            onChange={(e) => onValueChange(e)}
          />
        </Form.Item> */}
        </Form>
        <p className="text-[#00000099] md:w-[600px] w-full mb-10 mx-auto">
          {content}
        </p>
        {renderButtons()}
      </div>
    </div>
  );
};

export default SelectRange;
