import {
  getNextQuestion,
  changeSelectedCheckValue,
  changeSelectedRadioValue,
  changeRangeValue,
  getNextQuestionByNextId,
  mergeObj,
  setValueInQuestion,
  calculateMaterial,
  performRecommendation,
  setCheckBoxValue,
  getCheckBoxValue,
  getCopyOfOriginalQuestion,
  addDataInQuestionArray,
  getStaticObj,
} from "./commonFunctions";

const initialState = {
  wizardComplete: false,
  mainServices: null,
  subServices: [],
  language: localStorage.getItem("language") ? JSON.parse(localStorage.getItem("language")) : "se",
  currentSubServices: null,
  selectedRecord: null,
  previousQuestionId: -1,
  questionData: [],
  selectedServices: [],
  selectedServicesQuestionAndOption: null,
  startedServices: null,
  SelectedQuestion: null,
  ResultObj: null,
  finalResult: null,
  totalPage: 0,
  questionNum: 1,
  questionArray: null,
  materialCalculationLinks: null,
  addCalculationSpin: false,
  calculatedObj: null,
  resultGuid: null,
  staticLanguage: localStorage.getItem("language")
    ? getStaticObj(JSON.parse(localStorage.getItem("language")))
    : getStaticObj("se"),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "Change_Language": {
      console.log("===here====");
      const staticLanguageData = getStaticObj(action.payload);
      const questionLanguage = getCopyOfOriginalQuestion(action.payload);
      localStorage.setItem("language", JSON.stringify(action.payload));
      return {
        ...state,
        language: localStorage.getItem("language") ? JSON.parse(localStorage.getItem("language")) : action.payload,
        questionData: questionLanguage,
        staticLanguage: staticLanguageData,
        staticLanguageData: questionLanguage[0].staticObj,
      };
    }

    case "Add_More_Surface": {
      console.log("===here 1====");
      const getQuestion = getCopyOfOriginalQuestion(state.language);
      const staticLanguageData = localStorage.getItem("language")
        ? getStaticObj(JSON.parse(localStorage.getItem("language")))
        : getStaticObj("se");
      return {
        ...state,
        ResultObj: action.resultObj,
        questionData: getQuestion,
        SelectedQuestion: getQuestion[0],
        staticLanguage: staticLanguageData,
      };
    }

    case "addNewCalculation_SUCCESS": {
      console.log("===here 2====");
      return {
        ...state,
        materialCalculationLinks: action.payload.Record.materialCalculationLinks,
        addNewCalculation_success: true,
        addCalculationSpin: false,
      };
    }
    case "addNewCalculation_RESET": {
      console.log("===here 3====");
      return {
        ...state,
        addNewCalculation_success: false,
      };
    }
    case "getCalculation_SUCCESS": {
      console.log("===here 4====");
      return {
        ...state,
        finalResultCalculationObj: action.payload.Record.calculationObj,
        calculatedObj: action.payload.Record.orgObj,
        resultGuid: action.payload.Record.materialCalculationLinks,
        finalResultSpin: false,
      };
    }
    case "addNewCalculation_START": {
      console.log("===here 5====");
      return {
        ...state,
        addCalculationSpin: true,
      };
    }
    case "addNewCalculation_START": {
      console.log("===here 6====");
      return {
        ...state,
        addCalculationSpin: true,
      };
    }
    case "getCalculation_START": {
      console.log("===here 7====");
      return {
        ...state,
        finalResultSpin: true,
      };
    }
    case "mainServices_SUCCESS": {
      console.log("===here 8====");
      return {
        ...state,
      };
    }

    case "ShowNextQuestion": {
      console.log("===here 9====");
      let nextQuestion = getNextQuestion(
        state.SelectedQuestion,
        state.startedServices,
        state.selectedServices,
        state.selectedServicesQuestionAndOption,
        state.questionData
      );
      return {
        ...state,
        SelectedQuestion: nextQuestion.newQuestion,
        startedServices: nextQuestion.serviceStartedId,
        selectedServicesQuestionAndOption: nextQuestion.selectedServicesQuestionAndOptionNew,
      };
    }

    case "SetValueInSelectedQuestion": {
      console.log("===here 10====");
      let valueChange = setValueInQuestion(state.SelectedQuestion, action.payload);
      return {
        ...state,
        SelectedQuestion: valueChange,
      };
    }
    case "SetRangeValue": {
      console.log("===here 11====");
      let valueChange = changeRangeValue(state.SelectedQuestion, action.payload);
      return {
        ...state,
        SelectedQuestion: valueChange,
      };
    }

    // case "SelectedValueRadio": {
    //   let valueChange = changeSelectedRadioValue(
    //     state.SelectedQuestion,
    //     action.payload
    //   );
    //   return {
    //     ...state,
    //     SelectedQuestion: valueChange,
    //   };
    // }

    case "SelectedValueCheckbox": {
      console.log("===here 12====");
      let valueChange = changeSelectedCheckValue(state.SelectedQuestion, action.payload);

      return {
        ...state,
        SelectedQuestion: { ...valueChange, value: action.payload },
      };
    }

    //======= New Cases =======

    case "CheckBoxCard_SUCCESS": {
      console.log("===here 13====");
      let getValueForCheckBox = getCheckBoxValue(state.SelectedQuestion);

      let nexQ = getNextQuestionByNextId(state.questionData, action.payload.nextQuestionId);

      let keyForResult = action.payload.key;
      if (state.currentSubServices) {
        keyForResult = keyForResult + "_" + state.currentSubServices.title;
      }

      state.ResultObj[state.mainServices][keyForResult] = getValueForCheckBox;

      let qArray = addDataInQuestionArray(
        state.mainServices,
        state.currentSubServices,
        state.SelectedQuestion._id,
        state.questionArray
      );

      return {
        ...state,
        SelectedQuestion: nexQ,
        ResultObj: state.ResultObj,
        questionNum: state.questionNum + 1,
        questionArray: qArray,
        language: localStorage.getItem("language") ? JSON.parse(localStorage.getItem("language")) : "se",
      };
    }

    case "checkBoxCardCheck_SUCCESS": {
      console.log("===here 14====");
      const selectedValue = setCheckBoxValue(state.SelectedQuestion, action.payload);

      if (selectedValue["value"]) {
        selectedValue["value"] = [...selectedValue["value"], action.payload];
      } else {
        selectedValue["value"] = [action.payload];
      }

      return {
        ...state,
        SelectedQuestion: selectedValue,
      };
    }

    case "checkSubServiceLoop_SUCCESS": {
      console.log("===here 15====");
      let questionData = state.questionData;
      let keyForResult = action.detail.key;
      let questionNum = state.questionNum + 1;
      if (state.currentSubServices) {
        keyForResult = keyForResult + "_" + state.currentSubServices.title;
      }

      let valueChange = changeSelectedRadioValue(state.SelectedQuestion, state.questionData, action.payload);

      let mainServicesObj = state.ResultObj[state.mainServices];
      let reObj = null;
      if (mainServicesObj) {
        state.ResultObj[state.mainServices][keyForResult] = action.payload;
        reObj = state.ResultObj;
      } else {
        reObj = { [state.mainServices]: { [keyForResult]: action.payload } };
      }

      let newQuestion = valueChange.newSelectedQuestion;
      let qArray = addDataInQuestionArray(
        state.mainServices,
        state.currentSubServices,
        state.SelectedQuestion._id,
        state.questionArray
      );
      if (state.currentSubServices) {
        let checkCurrentServiceIndex = state.subServices.findIndex(
          (Item) => Item.title === state.currentSubServices.title
        );

        if (checkCurrentServiceIndex < state.subServices.length - 1) {
          let currentService = state.subServices[checkCurrentServiceIndex + 1];
          let nextQuestion = getNextQuestionByNextId(state.questionData, 20);
          newQuestion = nextQuestion;
          state.currentSubServices = currentService;
          state.totalPage = nextQuestion.totalPage;
          if (!nextQuestion.totalPage) {
            state.totalPage = 8;
          }

          questionNum = 1;
          questionData = getCopyOfOriginalQuestion(state.language);
        } else {
          state.currentSubServices = null;
        }
      } else {
        state.currentSubServices = null;
      }

      return {
        ...state,
        SelectedQuestion: newQuestion,
        previousQuestionId: valueChange.previousId,
        ResultObj: { ...reObj },
        questionNum: questionNum,
        questionArray: qArray,
        questionData: questionData,
        staticLanguage: questionData[0].staticObj,
        currentSubServices: state.currentSubServices,
        totalPage: state.totalPage,
        language: localStorage.getItem("language") ? JSON.parse(localStorage.getItem("language")) : "se",
      };
    }

    case "newOrExistingQuestion_SUCCESS": {
      let value = action.payload;
      if (action.payload && action.payload.defaultChecked) {
        value = action.payload.defaultChecked;
      }

      state.ResultObj[state.mainServices]["bathroomType"] = value;

      let nexQ = getNextQuestionByNextId(state.questionData, state.SelectedQuestion.nextQuestionId);

      let qArray = addDataInQuestionArray(
        state.mainServices,
        state.currentSubServices,
        state.SelectedQuestion._id,
        state.questionArray
      );

      return {
        ...state,
        SelectedQuestion: nexQ,
        ResultObj: state.ResultObj,
        questionNum: state.questionNum + 1,
        questionArray: qArray,
      };
    }

    case "justMoveToNext_SUCCESS": {
      console.log("===here 17====");
      let nexQ = getNextQuestionByNextId(state.questionData, action.payload.nextQuestionId);

      let keyForResult = action.payload.keyForResult;
      if (state.currentSubServices) {
        keyForResult = keyForResult + "_" + state.currentSubServices.title;
      }

      if (!action.skip) {
        state.ResultObj[state.mainServices][keyForResult] = action.payload[action.payload.keyForResultValue];
      }

      let qArray = addDataInQuestionArray(
        state.mainServices,
        state.currentSubServices,
        state.SelectedQuestion._id,
        state.questionArray
      );

      return {
        ...state,
        SelectedQuestion: nexQ,
        previousQuestionId: action.payload._id,
        ResultObj: state.ResultObj,
        questionNum: state.questionNum + 1,
        questionArray: qArray,
        language: localStorage.getItem("language") ? JSON.parse(localStorage.getItem("language")) : "se",
      };
    }

    case "DependOnOption_SUCCESS": {
      let options = action.detail.options.filter((x) => x.value === action.payload);
      let nexQ = getNextQuestionByNextId(state.questionData, options[0].nextQuestionId);

      let keyForResult = null;
      if (action.payload.keyForResult) {
        keyForResult = action.payload.keyForResult;
      } else {
        keyForResult = action.detail.keyForResult;
      }
      if (state.currentSubServices) {
        keyForResult = keyForResult + "_" + state.currentSubServices.title;
      }

      state.ResultObj[state.mainServices][keyForResult] = action.payload;
      if (options[0].addInTotal) {
        state.totalPage = state.totalPage + 1;
      }

      let qArray = addDataInQuestionArray(
        state.mainServices,
        state.currentSubServices,
        state.SelectedQuestion._id,
        state.questionArray
      );

      return {
        ...state,
        SelectedQuestion: nexQ,
        previousQuestionId: action.detail._id,
        ResultObj: state.ResultObj,
        questionNum: state.questionNum + 1,
        totalPage: state.totalPage,
        questionArray: qArray,
      };
    }

    case "rangeQuestionStore_SUCCESS": {
      let nextQ = null;
      let pId = state.SelectedQuestion._id;

      if (state.mainServices === 1) {
        let bathroomType = state.ResultObj[state.mainServices]["bathroomType"];
        let qData = state.questionData[0]["options"][0]["dependencyObj"];
        let nextQId = qData[bathroomType];
        nextQ = getNextQuestionByNextId(state.questionData, nextQId);
      } else {
        let qData = state.questionData[0]["options"];
        let filterSelectedQ = qData.filter((Item) => Item._id === state.mainServices);

        if (filterSelectedQ && filterSelectedQ.length > 0) {
          let nextQId = filterSelectedQ[0]["dependencyObj"];
          nextQ = getNextQuestionByNextId(state.questionData, nextQId);
        }
      }

      let keyForResult = "sqm";
      if (state.currentSubServices) {
        keyForResult = keyForResult + "_" + state.currentSubServices.title;
      }

      state.ResultObj[state.mainServices][keyForResult] = action.payload.value;

      let qArray = addDataInQuestionArray(
        state.mainServices,
        state.currentSubServices,
        state.SelectedQuestion._id,
        state.questionArray
      );

      return {
        ...state,
        SelectedQuestion: nextQ,
        previousQuestionId: pId,
        ResultObj: state.ResultObj,
        questionNum: state.questionNum + 1,
        questionArray: qArray,
      };
    }

    case "subServiceStore_SUCCESS": {
      let SelectedSubServices = [];
      let totalPage = state.totalPage;
      SelectedSubServices = state.SelectedQuestion.options.filter((Item) => Item.checked);
      let currentService = null;
      if (SelectedSubServices && SelectedSubServices.length > 0) {
        currentService = SelectedSubServices[0];
      }

      const nextQuestion = getNextQuestionByNextId(state.questionData, state.SelectedQuestion.nextQuestionId);

      if (currentService.addInTotal) {
        totalPage = totalPage + 1;
      }

      state.ResultObj[state.mainServices]["bathroomService"] = SelectedSubServices;

      let qArray = addDataInQuestionArray(
        state.mainServices,
        state.currentSubServices,
        state.SelectedQuestion._id,
        state.questionArray
      );

      return {
        ...state,
        currentSubServices: currentService,
        SelectedQuestion: nextQuestion,
        subServices: SelectedSubServices,
        ResultObj: state.ResultObj,
        questionArray: qArray,
        questionNum: state.questionNum + 1,
        totalPage: totalPage,
      };
    }

    case "subServices_SUCCESS": {
      let selectedQuestion = state.SelectedQuestion;
      selectedQuestion.options.map((Item) => {
        if (Item._id === action.payload._id) {
          Item.checked = action.payload.checked;
        }
      });
      selectedQuestion["value"] = action.payload;
      return {
        ...state,
        SelectedQuestion: selectedQuestion,
      };
    }

    case "SelectedValueRadio": {
      let keyForResult = action.detail.key;
      if (state.currentSubServices) {
        keyForResult = keyForResult + "_" + state.currentSubServices.title;
      }

      let valueChange = changeSelectedRadioValue(state.SelectedQuestion, state.questionData, action.payload);
      let mainServicesObj = state.ResultObj[state.mainServices];
      let reObj = null;
      if (mainServicesObj) {
        state.ResultObj[state.mainServices][keyForResult] = action.payload;
        reObj = state.ResultObj;
      } else {
        reObj = { [state.mainServices]: { [keyForResult]: action.payload } };
      }
      let qArray = addDataInQuestionArray(
        state.mainServices,
        state.currentSubServices,
        state.SelectedQuestion._id,
        state.questionArray
      );

      return {
        ...state,
        SelectedQuestion: valueChange.newSelectedQuestion,
        previousQuestionId: valueChange.previousId,
        ResultObj: { ...reObj },
        questionNum: state.questionNum + 1,
        questionArray: qArray,
      };
    }

    case "Selected_services": {
      const selectedNextQuestion = getNextQuestionByNextId(
        state.questionData,
        action.payload.nextQuestionId,
        action.payload.title
      );

      const rObj = mergeObj(state.ResultObj, {
        [action.payload._id]: { ServiceTitle: action.payload.title },
      });

      const newQuestionArrayBack = addDataInQuestionArray(
        action.payload._id,
        state.currentSubServices,
        state.SelectedQuestion._id,
        state.questionArray
      );

      return {
        ...state,
        mainServices: action.payload._id,
        ResultObj: { ...rObj },
        SelectedQuestion: selectedNextQuestion,
        questionArray: newQuestionArrayBack,
        previousQuestionId: 0,
        totalPage: action.payload.totalPage,
      };
    }

    case "calculator": {
      let data = {
        1: {
          ServiceTitle: "Bathroom",
          bathroomType: "existing",
          bathroomService: [
            {
              _id: 21,
              title: "Walls",
              value: "walls",
              nextQuestionId: 3,
              numberOfPages: 10,
              checked: true,
            },
            {
              _id: 22,
              title: "Floor",
              value: "floor",
              nextQuestionId: 6,
              numberOfPages: 11,
              checked: true,
            },
          ],
          sqm_Walls: 24,
          preparation_layer_Walls: "A",
          moreKonkralBase_Walls: 0.5,
          colorSelected_Walls: {
            Name: "Mineral",
            HEX: "#E2D7C5",
            Collection: "Konkral",
          },
          finalFinish_Walls: "skip",
          makeApplicationEasier_Walls: [
            {
              _id: 161,
              cardTopLink: "Prolongs the open-time",
              title: "Microcement Retarder",
              value: "F",
              checked: true,
            },
            {
              cardTopLink: "Good for for horisontal surfaces",
              _id: 162,
              title: "Microcement Thinner",
              value: "G",
              checked: true,
            },
          ],
          surfaceProtection_Walls: "H",
          extraMaterial_Walls: "L",
          microcement_sil_Floor: "P1",
          sqm_Floor: 18,
          preparation_layer_Floor: "A",
          moreKonkralBase_Floor: 0.5,
          colorSelected_Floor: {
            Name: "Mineral",
            HEX: "#E2D7C5",
            Collection: "Konkral",
          },
          finalFinish_Floor: "skip",
          makeApplicationEasier_Floor: [
            {
              _id: 161,
              cardTopLink: "Prolongs the open-time",
              title: "Microcement Retarder",
              value: "F",
              checked: true,
            },
            {
              cardTopLink: "Good for for horisontal surfaces",
              _id: 162,
              title: "Microcement Thinner",
              value: "G",
              checked: true,
            },
          ],
          surfaceProtection_Floor: "H",
          extraMaterial_Floor: "M",
        },
      };
      let calculatedMaterial = calculateMaterial(data);
      let getRecommendationList = performRecommendation(calculatedMaterial);
      let finalObj = {
        collapse: calculatedMaterial,
        recommendedList: getRecommendationList.recommendationList,
        total: getRecommendationList.totalPrice,
      };
      return {
        ...state,
      };
    }

    case "finalQuestion_SUCCESS": {
      let neq = null;
      let wizardComplete = false;
      let calculatedMaterial = null;
      let getRecommendationList = [];

      let questionData = state.questionData;

      if (action.payload === "moreArea") {
        neq = getNextQuestionByNextId(state.questionData, 0);
        questionData = getCopyOfOriginalQuestion(state.language);
      } else {
        calculatedMaterial = calculateMaterial(state.ResultObj);
        getRecommendationList = performRecommendation(calculatedMaterial);

        neq = null;
        wizardComplete = true;
      }

      return {
        ...state,
        finalResult: {
          collapse: calculatedMaterial,
          recommendedList: getRecommendationList.recommendationList,
          total: getRecommendationList.totalPrice,
        },
        SelectedQuestion: neq,
        wizardComplete,
        questionData: questionData,
        staticLanguage: questionData[0].staticObj,
        questionNum: 1,
        totalPage: 0,
      };
    }
    case "wizardComplete_RESET": {
      return {
        ...state,
        wizardComplete: false,
      };
    }
    case "Finish_Later": {
      const getQuestion = getCopyOfOriginalQuestion(state.language);
      return {
        ...initialState,
        questionData: getQuestion,
        SelectedQuestion: getQuestion[0],
        staticLanguage: getQuestion[0].staticObj,
      };
    }
    case "PreviousQuestion": {
      let totalPage = state.totalPage;
      let questionNum = state.questionNum;
      let currentSubServices = state.currentSubServices;
      let question = null;
      let lastQId = 0;
      if (state.mainServices === 1) {
        if (!currentSubServices && state.subServices.length > 0) {
          currentSubServices = state.subServices[state.subServices.length - 1];
        }
        //======= If current Sub Service is not null then go in if
        if (currentSubServices) {
          //===== if currentSubServices.title exit iq Question Array and also has length grater then 0
          if (
            state.questionArray &&
            state.questionArray[state.mainServices] &&
            state.questionArray[state.mainServices][currentSubServices.title] &&
            state.questionArray[state.mainServices][currentSubServices.title].length > 0
          ) {
            let questionIdsArray = state.questionArray[state.mainServices][currentSubServices.title];
            questionNum = state.questionNum - 1;
            lastQId = questionIdsArray.pop();
            state.questionArray[state.mainServices][currentSubServices.title] = questionIdsArray;
            question = getNextQuestionByNextId(state.questionData, lastQId, "");
            //======= Check if the current sub server has complete back then shift to the other sub service selected
            if (questionIdsArray.length === 0) {
              let findIndexOfCurrentService = state.subServices.findIndex((x) => x.title === currentSubServices.title);
              console.log(
                "====== findIndexOfCurrentService",
                findIndexOfCurrentService,
                state.subServices,
                currentSubServices
              );
              if (findIndexOfCurrentService > 0) {
                let otherSubServiceIndex = findIndexOfCurrentService - 1;
                let otherSubService = state.subServices[otherSubServiceIndex];

                if (
                  state.questionArray[state.mainServices] &&
                  state.questionArray[state.mainServices][otherSubService.title] &&
                  state.questionArray[state.mainServices][otherSubService.title].length > 0
                ) {
                  currentSubServices = otherSubService;

                  questionNum = totalPage = state.questionArray[state.mainServices][otherSubService.title].length;

                  if (otherSubServiceIndex === 0) {
                    questionNum = totalPage = totalPage + 3;
                  }
                } else {
                  currentSubServices = null;
                }
              } else {
                console.log("======== question ======", question);
                if (question._id === 2) {
                  console.log("====== questinId if===", question._id);
                  currentSubServices = null;
                } else {
                  console.log("====== questinId else===", question._id, state.currentSubServices);
                  currentSubServices = state.currentSubServices;
                }
              }
            }
            console.log("===== In If ======", questionIdsArray);
          } else {
            console.log("===== In else ======", currentSubServices);
            currentSubServices = null;
            let questionIdsArray = state.questionArray[state.mainServices]["questionData"];
            questionNum = state.questionNum - 1;
            lastQId = questionIdsArray.pop();
            state.questionArray[state.mainServices]["questionData"] = questionIdsArray;
            question = getNextQuestionByNextId(state.questionData, lastQId, "");
            if (questionIdsArray.length === 0) {
              totalPage = 0;
              questionNum = 1;
            }
          }
        } else {
          let questionIdsArray = state.questionArray[state.mainServices]["questionData"];
          questionNum = state.questionNum - 1;
          lastQId = questionIdsArray.pop();
          state.questionArray[state.mainServices]["questionData"] = questionIdsArray;
          question = getNextQuestionByNextId(state.questionData, lastQId, "");
          if (questionIdsArray.length === 0) {
            totalPage = 0;
            questionNum = 1;
          }
        }
      } else {
        let questionIdsArray = state.questionArray[state.mainServices];
        questionNum = state.questionNum - 1;
        lastQId = questionIdsArray.pop();
        state.questionArray[state.mainServices] = questionIdsArray;
        question = getNextQuestionByNextId(state.questionData, lastQId, "");
        if (questionIdsArray.length === 0) {
          totalPage = 0;
          questionNum = 1;
        }
      }

      let ResultObj = state.ResultObj;
      console.log("===== lastQId", lastQId);
      if (lastQId === 0) {
        let newResultObj = {};
        Object.entries(state.ResultObj).map(([key, value]) => {
          console.log("====== ", parseInt(key) !== parseInt(state.mainServices));
          if (parseInt(key) !== parseInt(state.mainServices)) {
            newResultObj[key] = value;
          }
        });
        ResultObj = newResultObj;
      }

      return {
        ...state,
        SelectedQuestion: question,
        totalPage: totalPage,
        questionNum: questionNum,
        currentSubServices: currentSubServices,
        ResultObj,
      };
    }

    case "checkServicesForQuestionId_SUCCESS": {
      //check the current sub service
      let currentSubServicesTitle = state.currentSubServices.title;
      const payload = action.payload;
      let nextQId = payload[`${currentSubServicesTitle}_nextQuestionId`];
      let neq = getNextQuestionByNextId(state.questionData, nextQId);
     
      return {
        ...state,
        SelectedQuestion: neq,
      };
    
    }

    default: {
      return state;
    }
  }
};
