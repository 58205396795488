import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../components/Card";
import { Collapse } from "antd";
import { CaretUpOutlined } from "@ant-design/icons";

const OtherTypeCards = ({ detail, renderButtons }) => {
  const { staticLanguage } = useSelector((state) => state.questionR);
  const { Panel } = Collapse;
  const {
    question,
    options,
    otherOptions,
    otherOptionLinkContent,
    linkContentTopLeft,
    linkUrl,
    content,
  } = detail;
  const dispatch = useDispatch();

  const onChange = (value) => {
    dispatch({
      type: "SelectedValueRadio",
      payload: value,
      detail,
    });
  };

  const renderCardRadioButtons = () => {
    if (options && options.length > 0) {
      return options.map((Item) => {
        const { value, checked, type, imageUrl } = Item;
        if (type === "main") {
          return (
            <Card
              className={
                checked
                  ? "drop-shadow-2xl bg-white border-black h-full"
                  : "border-zinc-200 h-full"
              }
              imageUrl={
                imageUrl ? require(`../../../assets/images/${imageUrl}`) : ""
              }
              onClick={() => onChange(value)}
              Item={Item}
            />
          );
        }
      });
    }
  };
  const renderOtherCardRadioButtons = () => {
    if (options && options.length > 0) {
      return options.map((Item) => {
        const { value, checked, type, imageUrl } = Item;
        if (type === "other") {
          return (
            <Card
              className={
                checked
                  ? "drop-shadow-2xl bg-white border-black"
                  : "border-zinc-200"
              }
              imageUrl={
                imageUrl ? require(`../../../assets/images/${imageUrl}`) : ""
              }
              onClick={() => onChange(value)}
              Item={Item}
            />
          );
        }
      });
    }
  };
  return (
    <div className="roll-out-div-fade">
      <h2 className="md:text-[32px] text-[24px] font-bold mb-3 roll-out-text">
        {question}
      </h2>
      {content && (
        <p className="mb-10 text-[#00000099] mb-3 roll-out-text">{content}</p>
      )}
      {linkContentTopLeft && (
        <p className="text-blue-500 font-semiBold mb-3 roll-out-text">
          {linkContentTopLeft}
        </p>
      )}
      <div className="roll-out-div">
        <div className="grid gap-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mb-10">
          {renderCardRadioButtons()}
        </div>
        <Collapse
          expandIcon={({ isActive }) => (
            <CaretUpOutlined rotate={isActive ? 180 : 0} />
          )}
        >
          <Panel className="bg-white" header={staticLanguage.otherOptions}>
            <div className="grid gap-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-10 mb-5">
              {renderOtherCardRadioButtons()}
            </div>
           
          </Panel>
        </Collapse>
        {otherOptionLinkContent && (
              <p
                onClick={() => {
                  if (linkUrl) {
                    window.open(linkUrl, "_blank");
                  }
                }}
                className="text-blue-500 font-semiBold cursor-pointer py-5"
              >
                {otherOptionLinkContent}
              </p>
            )}
        {renderButtons()}
      </div>
    </div>
  );
};

export default OtherTypeCards;
