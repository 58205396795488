import React, { useEffect, useState } from "react";
import konkral from "../../assets/images/XXXXX-Start-page-XXXXXXX-min.jpg";
// import BorderButton from "../../components/Button/BorderButton";
import FillButton from "../../components/Button/FillButton";
import { useNavigate } from "react-router-dom";
import PopoverView from "../popoverView";
import { useDispatch, useSelector } from "react-redux";
import LogoSvg from "../../components/Logo";
import { Select } from "antd";

const Index = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState();
  const [SelecedLang, setSelecedLang] = useState("");
  const navigate = useNavigate();

  const { staticLanguage } = useSelector((state) => state.questionR);

  useEffect(() => {
    if (localStorage.getItem("language")) {
      setSelecedLang(JSON.parse(localStorage.getItem("language")));
      dispatch({
        type: "Change_Language",
        payload: JSON.parse(localStorage.getItem("language")),
      });
    } else {
      dispatch({ type: "Change_Language", payload: "se" });
      setSelecedLang("se");
    }

    dispatch({ type: "SetStaticLang" });
  }, []);
  return (
    <>
      {staticLanguage && (
        <div className="grid md:grid-cols-2 grid-cols-1">
          <div className="px-5">
            <div className="flex justify-between items-center">
              <LogoSvg />
              <Select
                onChange={(e) => {
                  setSelecedLang(e);
                  dispatch({ type: "Change_Language", payload: e });
                }}
                className="w-[150px] "
                value={SelecedLang}
              >
                <Select.Option
                  className="flex flex-col ml-[35px] bg-white "
                  value="eng"
                >
                  English
                  <div className="rounded absolute top-1 left-[-20px]">🇬🇧</div>
                </Select.Option>
                <Select.Option className="flex flex-col ml-[35px]" value="se">
                  Swedish
                  <div className="rounded absolute top-1 left-[-20px]">🇸🇪</div>
                </Select.Option>
              </Select>
            </div>

            <div className="flex flex-col justify-center h-screen mt-[-80px]">
              <h1 className="font-bold text-[30px]">
                {staticLanguage.mainPageTitle}
              </h1>
              <p className="font-medium text-[14px] my-5 pr-4">
                {staticLanguage.mainPageContent}
              </p>

              {/* <FillButton
          onClick={() => {
            dispatch({ type: "Finish_Later" });
            setShowModal(true);
          }}
          bg="bg-[#D678C0]"
          className="sm:w-[300px] mb-5"
        >
          Open in a Pop-up
        </FillButton> */}
              <FillButton
                onClick={() => {
                  dispatch({
                    type: "Finish_Later",
                  });
                  navigate("/questions");
                }}
                bg="bg-[#5C3BF3]"
                className="sm:w-[300px] mb-5 active:bg-[#3E2EE8] hover:bg-[#3E2EE8]"
              >
                {staticLanguage.start}
              </FillButton>
              {/* <BorderButton
          onClick={() => {
            navigate("/questions");
            dispatch({ type: "Finish_Later" });
          }}
          className="sm:w-[300px]"
        >
          Open in a New Page
        </BorderButton> */}
            </div>
          </div>
          <div className="bg-white md:flex hidden items-center justify-center h-screen">
            <img src={konkral} className="w-full h-full" alt="" />
          </div>
        </div>
      )}
      <PopoverView open={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default Index;
