import React from "react";

const LogoSvg = ({ width = "100px", height = "80px" }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 1281.000000 283.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>
        Created by potrace 1.16, written by Peter Selinger 2001-2019
      </metadata>
      <g
        transform="translate(0.000000,283.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M165 2808 c-59 -30 -114 -86 -141 -143 l-24 -50 0 -1282 0 -1283 289
0 290 0 3 518 3 518 27 41 c29 45 130 103 177 103 37 -1 107 -34 158 -76 55
-45 87 -88 487 -646 l327 -458 360 0 c273 0 358 3 353 12 -4 6 -274 378 -600
826 l-593 815 462 469 c254 258 502 511 550 563 l89 95 -374 0 -373 -1 -525
-554 -525 -554 -3 554 -2 555 -188 0 c-169 -1 -191 -3 -227 -22z"
        />
        <path
          d="M6700 1440 l0 -1390 285 0 285 0 0 414 c0 397 1 414 20 439 48 61
128 31 203 -76 24 -34 131 -223 237 -419 l192 -358 339 0 340 0 -12 23 c-7 12
-176 301 -376 641 l-364 619 24 26 c13 14 180 193 370 398 191 204 347 374
347 377 0 3 -152 6 -338 6 l-338 0 -319 -366 -320 -366 -5 618 c-5 612 -5 619
-27 660 -26 48 -92 111 -139 130 -24 10 -82 14 -219 14 l-185 0 0 -1390z"
        />
        <path
          d="M12250 1441 l0 -1391 280 0 280 0 0 873 c0 481 -3 1071 -7 1312 l-6
438 -31 46 c-17 25 -53 59 -80 76 l-49 30 -194 3 -193 3 0 -1390z"
        />
        <path
          d="M3200 2243 c-103 -16 -196 -39 -261 -64 -298 -115 -474 -375 -530
-784 -17 -119 -17 -425 0 -545 79 -573 398 -850 976 -850 567 0 888 272 969
823 24 160 22 472 -5 628 -80 473 -320 724 -756 788 -67 10 -338 13 -393 4z
m303 -503 c123 -38 215 -152 252 -313 53 -230 34 -527 -45 -704 -78 -173 -254
-257 -435 -208 -141 38 -227 139 -277 323 -32 119 -37 403 -10 535 43 205 133
326 278 371 70 21 161 19 237 -4z"
        />
        <path
          d="M5314 2235 c-388 -60 -606 -273 -679 -664 -13 -70 -15 -196 -15 -802
l0 -719 285 0 285 0 0 719 c0 687 1 722 20 782 11 35 33 81 50 101 78 94 268
135 418 89 146 -44 207 -126 222 -297 5 -54 9 -345 10 -645 0 -581 1 -590 49
-658 11 -15 43 -40 73 -57 53 -29 53 -29 251 -32 l197 -4 0 619 c-1 897 -9
984 -106 1181 -106 217 -312 349 -609 392 -95 13 -352 11 -451 -5z"
        />
        <path
          d="M10786 2239 c-95 -14 -205 -47 -286 -86 -94 -46 -220 -168 -268 -259
-23 -45 -82 -224 -82 -251 0 -2 127 -3 283 -3 l282 0 20 36 c40 75 145 114
302 114 102 0 180 -13 233 -40 115 -59 133 -181 38 -254 -64 -48 -137 -68
-349 -96 -458 -61 -689 -177 -798 -402 -126 -260 -79 -604 108 -788 161 -158
407 -226 731 -200 472 38 747 202 866 516 57 153 59 175 59 724 0 495 0 506
-22 576 -27 86 -87 179 -151 234 -100 87 -292 157 -495 179 -111 13 -385 13
-471 0z m594 -1242 c0 -149 -12 -222 -51 -303 -82 -173 -325 -275 -520 -218
-171 51 -215 299 -74 423 57 50 122 76 290 116 83 20 193 51 245 69 52 18 98
34 103 34 4 1 7 -53 7 -121z"
        />
        <path
          d="M9556 2204 c-402 -54 -664 -249 -782 -580 -70 -195 -74 -249 -74
-950 l0 -624 285 0 285 0 0 603 c0 652 3 684 55 786 49 97 143 165 263 190 29
6 114 11 187 11 l135 0 0 290 0 290 -127 -1 c-71 -1 -173 -7 -227 -15z"
        />
      </g>
    </svg>
  );
};

export default LogoSvg;
