import React, { useState } from "react";
import Card from "../../../components/Card";
import { useDispatch } from "react-redux";
import ServiceOne from "../../../assets/images/Bathroom.jpg";
import ServiceTwo from "../../../assets/images/Floor-outside-of-wetroom.jpg";
import ServiceThree from "../../../assets/images/Wall-outside-of-wetroom.jpg";
import ServiceFour from "../../../assets/images/Kitchen-splash-back.jpg";
import ServiceFive from "../../../assets/images/Kitchen-Counter-Top.jpg";
import ServiceSix from "../../../assets/images/Staircase.jpg";
import ServiceSeven from "../../../assets/images/Furniture.jpg";
const Services = ({ detail, renderButtons }) => {
  const { question, options, topContent_1 } = detail;
  const [selectedId, setSelectedId] = useState(null);
  const dispatch = useDispatch();
  const selectedCards = (data) => {
    dispatch({ type: "Selected_services", payload: data });
  };
  const imagesArray = [
    ServiceOne,
    ServiceTwo,
    ServiceThree,
    ServiceFour,
    ServiceFive,
    ServiceSix,
    ServiceSeven,
  ];
  const renderOptions = () => {
    if (options && options.length > 0) {
      return options.map((Item, index) => {
        return (
          <Card
            imageUrl={imagesArray[index]}
            bg="bg-[#ffffff]"
            className={
              Item._id === selectedId
                ? "drop-shadow-2xl bg-white border-black "
                : "border-zinc-200"
            }
            onClick={() => {
              setSelectedId(Item._id);
              selectedCards(Item);
            }}
            Item={Item}
          />
        );
      });
    }
  };

  return (
    <div className="roll-out-div-fade">
      <h2 className="md:text-[32px] text-[24px] font-bold roll-out-text">
        {question}
      </h2>
      {topContent_1 && (
        <p className="text-[#00000099] w-full mb-4 roll-out-text">
          {topContent_1}
        </p>
      )}
      <div className="roll-out-div">
        <div className="grid gap-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 my-10">
          {renderOptions()}
        </div>
        {renderButtons()}
      </div>
    </div>
  );
};

export default Services;
