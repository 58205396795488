import React from "react";
import { ConfigProvider } from "antd";
import Routing from "./routing";
import { Provider } from "react-redux";
import store from "./redux/store";

const App = () => {
  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#000000",
          },
        }}
      >
        <Routing />
      </ConfigProvider>
    </Provider>
  );
};

export default App;
