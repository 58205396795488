import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Main from "./pages/main";
import Questions from "./pages/questions";
import Invoice from "./pages/invoicePage";
import CaptureEmail from "./pages/almostThere";
const Routing = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/questions" element={<Questions />} />
        <Route path="/result/:query" element={<Invoice />} />
        <Route path="/captureEmail" element={<CaptureEmail />} />
      </Routes>
    </Router>
  );
};

export default Routing;
