const colorOptions = [
  {
    Name: "S 0300-N",
    HEX: "#FFFFFC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0500-N",
    HEX: "#FAF9F7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0502-B",
    HEX: "#F2F8FB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0502-B50G",
    HEX: "#F1F7F9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0502-G",
    HEX: "#F1F6F4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0502-G50Y",
    HEX: "#F6F9F1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0502-R",
    HEX: "#FAF4F3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0502-R50B",
    HEX: "#F5F3F8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0502-Y",
    HEX: "#FBFAEF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0502-Y20R",
    HEX: "#F9F7ED",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0502-Y50R",
    HEX: "#FAF5EF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0502-Y80R",
    HEX: "#F9F6F5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-B",
    HEX: "#E6F2F7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-B20G",
    HEX: "#E9F6F9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-B50G",
    HEX: "#E9F6F7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-B80G",
    HEX: "#EEF9F6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-G",
    HEX: "#EEF8F2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-G10Y",
    HEX: "#F0F8F1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-G20Y",
    HEX: "#EFF7EE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-G30Y",
    HEX: "#F1F7EB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-G40Y",
    HEX: "#F6FBED",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-G50Y",
    HEX: "#F8FCEB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-G60Y",
    HEX: "#F7FAEA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-G70Y",
    HEX: "#F6F7E5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-G80Y",
    HEX: "#F8F7E4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-G90Y",
    HEX: "#FDFCE7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-R",
    HEX: "#FCF1EF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-R10B",
    HEX: "#F8ECEE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-R20B",
    HEX: "#F9EFF2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-R30B",
    HEX: "#F9F1F7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-R40B",
    HEX: "#F4EDF4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-R50B",
    HEX: "#F2EFF6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-R60B",
    HEX: "#F1F2FA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-R70B",
    HEX: "#EDF1F8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-R80B",
    HEX: "#EBF2F9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-R90B",
    HEX: "#E9F1F7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-Y",
    HEX: "#FEFAE6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-Y10R",
    HEX: "#FEF7E1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-Y20R",
    HEX: "#FFF7DF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-Y30R",
    HEX: "#FFF2DB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-Y40R",
    HEX: "#FFF2DE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-Y50R",
    HEX: "#FDEFDE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-Y60R",
    HEX: "#FFF1E5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-Y70R",
    HEX: "#FEF0E8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-Y80R",
    HEX: "#FCF1EB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0505-Y90R",
    HEX: "#FCEFEA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0507-B",
    HEX: "#E5F3FA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0507-B20G",
    HEX: "#E4F5F9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0507-B80G",
    HEX: "#E8F9F5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0507-G",
    HEX: "#ECFAF0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0507-G40Y",
    HEX: "#F2F8E5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0507-G80Y",
    HEX: "#F8F7DF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0507-R",
    HEX: "#FDEDEC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0507-R20B",
    HEX: "#FAEBF0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0507-R40B",
    HEX: "#F6EDF6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0507-R60B",
    HEX: "#F0EFFB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0507-R80B",
    HEX: "#E7F0FB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0507-Y",
    HEX: "#FEF8DD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0507-Y20R",
    HEX: "#FFF3D5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0507-Y40R",
    HEX: "#FFEED8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0507-Y60R",
    HEX: "#FFEEE0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0507-Y80R",
    HEX: "#FFEDE6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-B",
    HEX: "#DBEEF6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-B10G",
    HEX: "#D9F1F6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-B30G",
    HEX: "#D8F1F3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-B50G",
    HEX: "#E0F6F5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-B70G",
    HEX: "#DCF5F1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-B90G",
    HEX: "#E2F7EE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-G",
    HEX: "#E4F8EA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-G10Y",
    HEX: "#E8F9EA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-G20Y",
    HEX: "#E9F6E5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-G30Y",
    HEX: "#EAF7DF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-G40Y",
    HEX: "#F1FADF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-G50Y",
    HEX: "#F0F6DD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-G60Y",
    HEX: "#F2F6DA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-G70Y",
    HEX: "#F8FAD6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-G80Y",
    HEX: "#F9F8D5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-G90Y",
    HEX: "#FCF8D5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-R",
    HEX: "#FEE5E3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-R10B",
    HEX: "#FDE8E9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-R20B",
    HEX: "#F8E4E9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-R30B",
    HEX: "#F9E7F0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-R40B",
    HEX: "#F5E9F4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-R50B",
    HEX: "#F2EBF7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-R60B",
    HEX: "#EAEAF8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-R70B",
    HEX: "#E5EBF8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-R80B",
    HEX: "#E0ECF8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-R90B",
    HEX: "#E0F0FB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-Y",
    HEX: "#FBF2CE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-Y10R",
    HEX: "#FFF3CB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-Y20R",
    HEX: "#FFEFCC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-Y30R",
    HEX: "#FFEBCC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-Y40R",
    HEX: "#FFE7CB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-Y50R",
    HEX: "#FFE7D1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-Y60R",
    HEX: "#FFE6D5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-Y70R",
    HEX: "#FFE3D2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-Y80R",
    HEX: "#FFE6DC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0510-Y90R",
    HEX: "#FFE5DF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-B",
    HEX: "#CDE9F6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-B20G",
    HEX: "#C9ECF2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-B50G",
    HEX: "#CEF1F0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-B80G",
    HEX: "#D0F3EA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-G",
    HEX: "#D8F6E4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-G20Y",
    HEX: "#DCF2D9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-G40Y",
    HEX: "#EAF7D4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-G60Y",
    HEX: "#F0F6CB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-G80Y",
    HEX: "#F8F6C8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-G90Y",
    HEX: "#FDF6C5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-R",
    HEX: "#FFDDDC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-R10B",
    HEX: "#FCDEE2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-R20B",
    HEX: "#FADDE7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-R40B",
    HEX: "#F6E3F4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-R60B",
    HEX: "#E3E2F7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-R80B",
    HEX: "#D4E5F9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-R90B",
    HEX: "#D1E6F6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-Y",
    HEX: "#FFF4C4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-Y10R",
    HEX: "#FFEDBB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-Y20R",
    HEX: "#FFE9BA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-Y30R",
    HEX: "#FFE1B8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-Y40R",
    HEX: "#FFDCB9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-Y50R",
    HEX: "#FFDCC0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-Y60R",
    HEX: "#FFDBC6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-Y70R",
    HEX: "#FFDBCB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-Y80R",
    HEX: "#FFDBD0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0515-Y90R",
    HEX: "#FFDAD4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-B",
    HEX: "#C2E8F7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-B10G",
    HEX: "#BBE7F2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-B30G",
    HEX: "#BCEAEE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-B40G",
    HEX: "#BBEAEB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-B50G",
    HEX: "#BFEEEC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-B60G",
    HEX: "#BDEEE9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-B70G",
    HEX: "#BFF1E8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-B90G",
    HEX: "#C6F3E1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-G",
    HEX: "#CDF5DD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-G10Y",
    HEX: "#CEF4D5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-G20Y",
    HEX: "#D6F3D2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-G30Y",
    HEX: "#DBF0C9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-G40Y",
    HEX: "#E5F5C4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-G50Y",
    HEX: "#EEFAC4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-G60Y",
    HEX: "#ECF4BD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-G70Y",
    HEX: "#F7FABE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-G80Y",
    HEX: "#FBF8B9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-G90Y",
    HEX: "#FCF3B7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-R",
    HEX: "#FFCECD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-R10B",
    HEX: "#FDD2D7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-R20B",
    HEX: "#FAD5DF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-R30B",
    HEX: "#FCD9EA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-R40B",
    HEX: "#F3D7EE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-R50B",
    HEX: "#E6D8F2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-R60B",
    HEX: "#DDDCF5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-R70B",
    HEX: "#D4DEF9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-R80B",
    HEX: "#CAE0F7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-R90B",
    HEX: "#C5E2F6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-Y",
    HEX: "#FFF1B7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-Y10R",
    HEX: "#FFEDB4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-Y20R",
    HEX: "#FFE2A8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-Y30R",
    HEX: "#FFDDAF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-Y40R",
    HEX: "#FFD5AC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-Y50R",
    HEX: "#FFD1AF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-Y60R",
    HEX: "#FFD0B4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-Y70R",
    HEX: "#FFD0BB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-Y80R",
    HEX: "#FFCFC3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0520-Y90R",
    HEX: "#FFCFC7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0525-R60B",
    HEX: "#D4D0F3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0525-R70B",
    HEX: "#CCD7F9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-B",
    HEX: "#A2DBF4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-B10G",
    HEX: "#99DBEC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-B30G",
    HEX: "#98E1E7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-B40G",
    HEX: "#9BE4E7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-B50G",
    HEX: "#A0EBE8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-B60G",
    HEX: "#9EE7E1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-B70G",
    HEX: "#9FE9DD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-B90G",
    HEX: "#A8EFD5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-G",
    HEX: "#ACEBC7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-G10Y",
    HEX: "#B5ECC0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-G20Y",
    HEX: "#BDEDB9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-G30Y",
    HEX: "#C7ECB1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-G40Y",
    HEX: "#D9F0AF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-G50Y",
    HEX: "#E0F1A6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-G60Y",
    HEX: "#E6F1A1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-G70Y",
    HEX: "#F3F8A4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-G80Y",
    HEX: "#F9F5A3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-G90Y",
    HEX: "#FFF39C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-R",
    HEX: "#FFBBBB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-R10B",
    HEX: "#FFBFCA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-R20B",
    HEX: "#FFC5D7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-R30B",
    HEX: "#F5C0DA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-R40B",
    HEX: "#ECC5E6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-R50B",
    HEX: "#DEC4ED",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-R80B",
    HEX: "#B2D0F4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-R90B",
    HEX: "#A8D3F3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-Y",
    HEX: "#FFEB94",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-Y10R",
    HEX: "#FFDF94",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-Y20R",
    HEX: "#FFD793",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-Y30R",
    HEX: "#FFCF93",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-Y40R",
    HEX: "#FFC694",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-Y50R",
    HEX: "#FFC299",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-Y60R",
    HEX: "#FFBD9B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-Y70R",
    HEX: "#FFBDA4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-Y80R",
    HEX: "#FFBCAC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0530-Y90R",
    HEX: "#FFBBB4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-B",
    HEX: "#82CCEA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-B10G",
    HEX: "#77D3EA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-B30G",
    HEX: "#74DAE3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-G",
    HEX: "#91E6BC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-G10Y",
    HEX: "#9FE6AE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-G20Y",
    HEX: "#A9E8A6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-G30Y",
    HEX: "#B4E598",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-G40Y",
    HEX: "#CCEB94",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-G50Y",
    HEX: "#D5EA8D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-G60Y",
    HEX: "#E5F38A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-G70Y",
    HEX: "#F0F589",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-G80Y",
    HEX: "#F7F286",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-G90Y",
    HEX: "#FFF087",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-R",
    HEX: "#FFA6A8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-R10B",
    HEX: "#FFA9B8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-R20B",
    HEX: "#FAAAC3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-R30B",
    HEX: "#FAB0D6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-R90B",
    HEX: "#8EC6F3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-Y",
    HEX: "#FFE886",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-Y10R",
    HEX: "#FFDC7F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-Y20R",
    HEX: "#FFCC78",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-Y30R",
    HEX: "#FFC077",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-Y40R",
    HEX: "#FFB779",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-Y50R",
    HEX: "#FFB27F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-Y60R",
    HEX: "#FFAE87",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-Y70R",
    HEX: "#FFA98C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-Y80R",
    HEX: "#FFA897",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0540-Y90R",
    HEX: "#FFA79F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0550-G10Y",
    HEX: "#83E19A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0550-G20Y",
    HEX: "#95DE8D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0550-G30Y",
    HEX: "#AAE181",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0550-G40Y",
    HEX: "#C1E579",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0550-G50Y",
    HEX: "#CDE773",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0550-G60Y",
    HEX: "#DCEB6C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0550-G70Y",
    HEX: "#ECF26C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0550-G80Y",
    HEX: "#F2ED67",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0550-G90Y",
    HEX: "#FDEB67",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0550-R",
    HEX: "#FA8D92",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0550-R10B",
    HEX: "#F890A2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0550-Y",
    HEX: "#FFE16B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0550-Y10R",
    HEX: "#FFD562",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0550-Y20R",
    HEX: "#FFC564",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0550-Y30R",
    HEX: "#FFB867",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0550-Y40R",
    HEX: "#FFAB66",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0550-Y50R",
    HEX: "#FFA267",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0550-Y60R",
    HEX: "#FF9D71",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0550-Y70R",
    HEX: "#FF9C7A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0550-Y80R",
    HEX: "#FF9381",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0550-Y90R",
    HEX: "#FE9088",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0560-G10Y",
    HEX: "#62D482",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0560-G20Y",
    HEX: "#82DB76",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0560-G30Y",
    HEX: "#9CDB69",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0560-G40Y",
    HEX: "#B3DC5F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0560-G50Y",
    HEX: "#C7E355",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0560-G60Y",
    HEX: "#D8E84C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0560-G70Y",
    HEX: "#E5EB4B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0560-G80Y",
    HEX: "#F1E94B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0560-G90Y",
    HEX: "#F8E441",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0560-R",
    HEX: "#F3737D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0560-Y",
    HEX: "#FFD642",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0560-Y10R",
    HEX: "#FFCA42",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0560-Y20R",
    HEX: "#FFBD4E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0560-Y30R",
    HEX: "#FFB151",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0560-Y40R",
    HEX: "#FFA351",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0560-Y50R",
    HEX: "#FF9A5B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0560-Y60R",
    HEX: "#FF9562",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0560-Y70R",
    HEX: "#FF8664",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0560-Y80R",
    HEX: "#FF806D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0560-Y90R",
    HEX: "#FA7670",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0565-G10Y",
    HEX: "#46CC76",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0565-G50Y",
    HEX: "#BCD935",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0565-R",
    HEX: "#F1616E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0570-G20Y",
    HEX: "#5DCA56",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0570-G30Y",
    HEX: "#87D24C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0570-G40Y",
    HEX: "#9FD332",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0570-G60Y",
    HEX: "#CDE01E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0570-G70Y",
    HEX: "#DFE616",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0570-G80Y",
    HEX: "#EAE207",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0570-G90Y",
    HEX: "#FCE407",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0570-Y",
    HEX: "#FEDC00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0570-Y10R",
    HEX: "#FFCB03",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0570-Y20R",
    HEX: "#FFB629",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0570-Y30R",
    HEX: "#FFA437",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0570-Y40R",
    HEX: "#FF973C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0570-Y50R",
    HEX: "#FF8836",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0570-Y60R",
    HEX: "#FF8346",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0570-Y70R",
    HEX: "#FF724A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0570-Y80R",
    HEX: "#FE6851",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0570-Y90R",
    HEX: "#F25C5A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0575-G20Y",
    HEX: "#4DC53F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0575-G40Y",
    HEX: "#A1D414",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0575-G60Y",
    HEX: "#CDE000",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0575-G70Y",
    HEX: "#DBDF00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0575-G90Y",
    HEX: "#FAE100",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0580-G30Y",
    HEX: "#73C818",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0580-Y",
    HEX: "#FFD300",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0580-Y10R",
    HEX: "#FFC700",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0580-Y20R",
    HEX: "#FFB200",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0580-Y30R",
    HEX: "#FF9C00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0580-Y40R",
    HEX: "#FF8F00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0580-Y50R",
    HEX: "#FF7A00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0580-Y60R",
    HEX: "#FE6907",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0580-Y70R",
    HEX: "#FD5D28",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0580-Y80R",
    HEX: "#EE482B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0580-Y90R",
    HEX: "#ED3837",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0585-Y20R",
    HEX: "#FFB200",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0585-Y30R",
    HEX: "#FF9C00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0585-Y40R",
    HEX: "#FD8B00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0585-Y50R",
    HEX: "#FF8000",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0585-Y60R",
    HEX: "#F96900",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0585-Y70R",
    HEX: "#F65400",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0585-Y80R",
    HEX: "#EE2E00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0601-B",
    HEX: "#F3F6F8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0601-G",
    HEX: "#F4F6F6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0601-R",
    HEX: "#F6F5F6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0601-Y",
    HEX: "#F7F6F2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0603-G40Y",
    HEX: "#F0F3EC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0603-G80Y",
    HEX: "#F5F5EA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0603-R20B",
    HEX: "#F6EFF3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0603-R40B",
    HEX: "#F2EFF4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0603-R60B",
    HEX: "#EFEFF5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0603-R80B",
    HEX: "#EDF0F6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0603-Y20R",
    HEX: "#F8F3E6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0603-Y40R",
    HEX: "#F9F2E7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0603-Y60R",
    HEX: "#FBF3EB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0603-Y80R",
    HEX: "#F6F0EE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0804-B50G",
    HEX: "#E5EFF0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0804-G20Y",
    HEX: "#E6ECE5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0804-G60Y",
    HEX: "#ECEEE2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0804-G90Y",
    HEX: "#F2EFDF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0804-R10B",
    HEX: "#F3EAEB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0804-R30B",
    HEX: "#F0E8ED",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0804-R50B",
    HEX: "#EDEAF1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0804-R70B",
    HEX: "#E8EAF1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0804-R90B",
    HEX: "#E7ECF1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0804-Y10R",
    HEX: "#F5F0E0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0804-Y30R",
    HEX: "#F5EDDD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0804-Y50R",
    HEX: "#F5EADE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0804-Y70R",
    HEX: "#F5EBE6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0804-Y90R",
    HEX: "#F4EAE7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0907-B20G",
    HEX: "#D8E8EC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0907-B80G",
    HEX: "#DBEBE8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0907-G20Y",
    HEX: "#DFE9DD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0907-G60Y",
    HEX: "#E8EBD7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0907-G90Y",
    HEX: "#EEEAD1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0907-R10B",
    HEX: "#EFDFE2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0907-R30B",
    HEX: "#EFE3EA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0907-R50B",
    HEX: "#E7E2ED",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0907-R70B",
    HEX: "#E0E5EF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0907-R90B",
    HEX: "#DBE6EE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0907-Y10R",
    HEX: "#F4EACF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0907-Y30R",
    HEX: "#F5E5CD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0907-Y50R",
    HEX: "#F5E2D1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0907-Y70R",
    HEX: "#F4E0D6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 0907-Y90R",
    HEX: "#F2E1DE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1000-N",
    HEX: "#EAE9E9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1001-B",
    HEX: "#E6E8EB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1001-G",
    HEX: "#E8EAEA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1001-R",
    HEX: "#EAE8E9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1001-Y",
    HEX: "#EBEBE8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1002-B",
    HEX: "#E1E7EB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1002-B50G",
    HEX: "#E2E8E8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1002-G",
    HEX: "#E2E8E5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1002-G50Y",
    HEX: "#E8EAE4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1002-R",
    HEX: "#E9E5E5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1002-R50B",
    HEX: "#E6E5E9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1002-Y",
    HEX: "#E7E6DD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1002-Y20R",
    HEX: "#EDEAE1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1002-Y50R",
    HEX: "#ECE7E1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1002-Y80R",
    HEX: "#EBE5E3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-B",
    HEX: "#DBE4E9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-B20G",
    HEX: "#D5E3E6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-B50G",
    HEX: "#DAE6E7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-B80G",
    HEX: "#DAE6E4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-G",
    HEX: "#DDE7E2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-G10Y",
    HEX: "#E0EAE3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-G20Y",
    HEX: "#DEE7DC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-G30Y",
    HEX: "#E1E8DD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-G40Y",
    HEX: "#E2E7DA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-G50Y",
    HEX: "#E3E7D7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-G60Y",
    HEX: "#E7E9DB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-G70Y",
    HEX: "#E9EADB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-G80Y",
    HEX: "#EAE9D7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-G90Y",
    HEX: "#EAE7D3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-R",
    HEX: "#E9DEDD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-R10B",
    HEX: "#ECE2E3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-R20B",
    HEX: "#E8DDE0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-R30B",
    HEX: "#E9E1E7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-R40B",
    HEX: "#E7E1E8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-R50B",
    HEX: "#E2E0E6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-R60B",
    HEX: "#E1E2EA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-R70B",
    HEX: "#E0E4EC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-R80B",
    HEX: "#DCE2EA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-R90B",
    HEX: "#DBE3EA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-Y",
    HEX: "#ECE7D1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-Y10R",
    HEX: "#EBE5D2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-Y20R",
    HEX: "#EDE3CD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-Y30R",
    HEX: "#EBE1CF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-Y40R",
    HEX: "#EEE2D2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-Y50R",
    HEX: "#EFE1D2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-Y60R",
    HEX: "#ECDFD4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-Y70R",
    HEX: "#EBDED6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-Y80R",
    HEX: "#EBDFD9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1005-Y90R",
    HEX: "#EADDDA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-B",
    HEX: "#CADEE6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-B10G",
    HEX: "#CDE0E5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-B30G",
    HEX: "#CBE3E6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-B50G",
    HEX: "#CEE6E6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-B70G",
    HEX: "#CFE4E1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-B90G",
    HEX: "#D0E6DD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-G",
    HEX: "#CDE3D6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-G10Y",
    HEX: "#D1E3D4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-G20Y",
    HEX: "#D6E5D3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-G30Y",
    HEX: "#DAE5CF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-G40Y",
    HEX: "#DDE6CD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-G50Y",
    HEX: "#E1E7CC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-G60Y",
    HEX: "#E1E6C9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-G70Y",
    HEX: "#E2E4C6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-G80Y",
    HEX: "#E7E6C5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-G90Y",
    HEX: "#EBE5C3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-R",
    HEX: "#E7D2D1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-R10B",
    HEX: "#EBD5D7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-R20B",
    HEX: "#E9D5DC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-R30B",
    HEX: "#ECDAE3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-R40B",
    HEX: "#E5D9E5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-R50B",
    HEX: "#E0DAE7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-R60B",
    HEX: "#DBDAE8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-R70B",
    HEX: "#D6DBE8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-R80B",
    HEX: "#D2DDE9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-R90B",
    HEX: "#CEDEEA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-Y",
    HEX: "#EDE4C0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-Y10R",
    HEX: "#EFE2BE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-Y20R",
    HEX: "#F1DFBD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-Y30R",
    HEX: "#F2DEC0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-Y40R",
    HEX: "#F2DABE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-Y50R",
    HEX: "#F4D8C0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-Y60R",
    HEX: "#F1D7C5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-Y70R",
    HEX: "#F3D5C6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-Y80R",
    HEX: "#F1D6CD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1010-Y90R",
    HEX: "#F1D8D3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-B",
    HEX: "#C0DBE8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-B20G",
    HEX: "#BBDEE4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-B50G",
    HEX: "#BFE1E0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-B80G",
    HEX: "#C1E3DA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-G",
    HEX: "#C5E4D1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-G20Y",
    HEX: "#CDE3C9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-G40Y",
    HEX: "#D8E4C3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-G60Y",
    HEX: "#E0E6BE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-G80Y",
    HEX: "#E9E6B9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-G90Y",
    HEX: "#EEE6B7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-R",
    HEX: "#EFCBCA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-R10B",
    HEX: "#EDCDD1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-R20B",
    HEX: "#EBCED7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-R40B",
    HEX: "#E4D2E3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-R60B",
    HEX: "#D7D5EA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-R80B",
    HEX: "#C8D9EC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-R90B",
    HEX: "#C3D8E9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-Y",
    HEX: "#EFE3B5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-Y10R",
    HEX: "#F4E2B4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-Y20R",
    HEX: "#F7DEB1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-Y30R",
    HEX: "#F8D9B1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-Y40R",
    HEX: "#F7D5B3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-Y50R",
    HEX: "#F7D2B6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-Y60R",
    HEX: "#F6D1BA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-Y70R",
    HEX: "#F5CEBD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-Y80R",
    HEX: "#F4CDC2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1015-Y90R",
    HEX: "#F2CCC6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-B",
    HEX: "#B1D6E7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-B10G",
    HEX: "#AAD7E2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-B30G",
    HEX: "#ABDADE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-B40G",
    HEX: "#AEDCDE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-B50G",
    HEX: "#ABDBDA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-B60G",
    HEX: "#B1DFDB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-B70G",
    HEX: "#AEE0D7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-B90G",
    HEX: "#B3E1CF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-G",
    HEX: "#B5E0C7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-G10Y",
    HEX: "#BDE1C3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-G20Y",
    HEX: "#C3E2BF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-G30Y",
    HEX: "#C9E1BA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-G40Y",
    HEX: "#CEDEB2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-G50Y",
    HEX: "#DBE6B4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-G60Y",
    HEX: "#DDE4AE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-G70Y",
    HEX: "#E2E5AF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-G80Y",
    HEX: "#E7E4AA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-G90Y",
    HEX: "#F0E6AC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-R",
    HEX: "#F3C2C2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-R10B",
    HEX: "#ECC2C7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-R20B",
    HEX: "#EBC3CF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-R30B",
    HEX: "#E8C4D5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-R40B",
    HEX: "#DFC5DC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-R50B",
    HEX: "#D8C9E3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-R60B",
    HEX: "#D1CEEB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-R70B",
    HEX: "#C4CFEB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-R80B",
    HEX: "#BCD0E7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-R90B",
    HEX: "#B6D3EA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-Y",
    HEX: "#F1E2A9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-Y10R",
    HEX: "#F4DDA6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-Y20R",
    HEX: "#F4D7A4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-Y30R",
    HEX: "#FAD3A1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-Y40R",
    HEX: "#FACBA2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-Y50R",
    HEX: "#FCCBA8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-Y60R",
    HEX: "#F4C4A9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-Y70R",
    HEX: "#F7C5B0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-Y80R",
    HEX: "#F3C2B4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1020-Y90R",
    HEX: "#F2C3BB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-B",
    HEX: "#95CBE3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-B10G",
    HEX: "#8ACCDF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-B30G",
    HEX: "#89D0D7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-B40G",
    HEX: "#8BD5D7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-B50G",
    HEX: "#8ED5D3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-B60G",
    HEX: "#90D9D3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-B70G",
    HEX: "#97DBD1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-B90G",
    HEX: "#99DCC4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-G",
    HEX: "#9CD9B8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-G10Y",
    HEX: "#A4D9B0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-G20Y",
    HEX: "#AEDAAA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-G30Y",
    HEX: "#B4D7A1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-G40Y",
    HEX: "#C4DB9E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-G50Y",
    HEX: "#CDDD98",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-G60Y",
    HEX: "#D6DF94",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-G70Y",
    HEX: "#DCE193",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-G80Y",
    HEX: "#E5E190",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-G90Y",
    HEX: "#EDE191",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-R",
    HEX: "#F1AEB1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-R10B",
    HEX: "#EEB0B9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-R20B",
    HEX: "#EAB1C2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-R30B",
    HEX: "#E8B4CD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-R40B",
    HEX: "#DEB4D6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-R50B",
    HEX: "#D3B8DD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-R60B",
    HEX: "#C3BEE9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-R70B",
    HEX: "#B4C1EA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-R80B",
    HEX: "#A8C3EA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-R90B",
    HEX: "#9EC5E5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-Y",
    HEX: "#EED98B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-Y10R",
    HEX: "#F5D48B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-Y20R",
    HEX: "#FDD18C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-Y30R",
    HEX: "#FCC78B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-Y40R",
    HEX: "#FFC18D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-Y50R",
    HEX: "#FFBA8F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-Y60R",
    HEX: "#FBB794",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-Y70R",
    HEX: "#FBB59B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-Y80R",
    HEX: "#F4AD9D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1030-Y90R",
    HEX: "#F0ACA4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-B",
    HEX: "#70BEDF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-B10G",
    HEX: "#67C6DD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-B20G",
    HEX: "#6CC9DA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-B30G",
    HEX: "#64CAD4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-B40G",
    HEX: "#5CCBD0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-B50G",
    HEX: "#63CFCD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-B60G",
    HEX: "#65D3CB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-B70G",
    HEX: "#65D2C5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-B80G",
    HEX: "#71D6C1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-B90G",
    HEX: "#77D6B9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-G",
    HEX: "#7ED3AC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-G10Y",
    HEX: "#8DD6A1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-G20Y",
    HEX: "#9BD595",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-G30Y",
    HEX: "#AAD88F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-G40Y",
    HEX: "#BAD586",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-G50Y",
    HEX: "#C4D980",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-G60Y",
    HEX: "#CEDA79",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-G70Y",
    HEX: "#D8DC76",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-G80Y",
    HEX: "#E3DF76",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-G90Y",
    HEX: "#EBDD78",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-R",
    HEX: "#F29A9E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-R10B",
    HEX: "#EA97A5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-R20B",
    HEX: "#EF9CB6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-R30B",
    HEX: "#E69CC3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-R40B",
    HEX: "#DDA4D3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-R50B",
    HEX: "#CDA7E0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-R60B",
    HEX: "#BAACE8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-R70B",
    HEX: "#A4B1EB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-R80B",
    HEX: "#92B7EB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-R90B",
    HEX: "#83B8E4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-Y",
    HEX: "#F8DC7A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-Y10R",
    HEX: "#FED273",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-Y20R",
    HEX: "#FEC871",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-Y30R",
    HEX: "#FFBC70",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-Y40R",
    HEX: "#FCB275",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-Y50R",
    HEX: "#FFAE7A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-Y60R",
    HEX: "#FEA981",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-Y70R",
    HEX: "#F99F82",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-Y80R",
    HEX: "#F89B8A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1040-Y90R",
    HEX: "#F69D94",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-B",
    HEX: "#44B3DD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-B10G",
    HEX: "#21B9DA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-B20G",
    HEX: "#00BCD1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-B30G",
    HEX: "#0DC3D0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-B40G",
    HEX: "#00C4C9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-B50G",
    HEX: "#00C5C2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-B60G",
    HEX: "#0CCCC2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-B70G",
    HEX: "#00CFBF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-B80G",
    HEX: "#35CFB5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-B90G",
    HEX: "#43CDA9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-G",
    HEX: "#57CE9C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-G10Y",
    HEX: "#72CF8D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-G20Y",
    HEX: "#88CE82",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-G30Y",
    HEX: "#98CF76",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-G40Y",
    HEX: "#ABCF6B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-G50Y",
    HEX: "#BDD567",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-G60Y",
    HEX: "#CBD963",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-G70Y",
    HEX: "#D7DB60",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-G80Y",
    HEX: "#DBD559",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-G90Y",
    HEX: "#EAD95A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-R",
    HEX: "#EC8286",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-R10B",
    HEX: "#ED8294",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-R20B",
    HEX: "#E780A3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-R30B",
    HEX: "#E581B5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-R40B",
    HEX: "#D689C9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-R90B",
    HEX: "#5DAEEA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-Y",
    HEX: "#F6D65B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-Y10R",
    HEX: "#FDCC5A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-Y20R",
    HEX: "#FEBD5A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-Y30R",
    HEX: "#FEB05A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-Y40R",
    HEX: "#FCA55C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-Y50R",
    HEX: "#FD9F63",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-Y60R",
    HEX: "#F89466",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-Y70R",
    HEX: "#FA8D69",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-Y80R",
    HEX: "#F88A76",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1050-Y90R",
    HEX: "#F2837D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1055-B90G",
    HEX: "#00C8A0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-B",
    HEX: "#00A7DA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-G",
    HEX: "#00C387",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-G10Y",
    HEX: "#49C375",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-G20Y",
    HEX: "#6AC564",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-G30Y",
    HEX: "#8ACA5D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-G40Y",
    HEX: "#A3CB53",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-G50Y",
    HEX: "#B7D04A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-G60Y",
    HEX: "#C3D33F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-G70Y",
    HEX: "#CED238",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-G80Y",
    HEX: "#DBD240",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-G90Y",
    HEX: "#E7D239",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-R",
    HEX: "#E66772",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-R10B",
    HEX: "#E76782",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-R20B",
    HEX: "#E15E8C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-R30B",
    HEX: "#DB5CA1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-Y",
    HEX: "#F9D43E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-Y10R",
    HEX: "#FFC637",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-Y20R",
    HEX: "#FFB53F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-Y30R",
    HEX: "#FFA43E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-Y40R",
    HEX: "#FB9746",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-Y50R",
    HEX: "#F98C48",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-Y60R",
    HEX: "#F58351",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-Y70R",
    HEX: "#F37B57",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-Y80R",
    HEX: "#EF705D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1060-Y90R",
    HEX: "#EC6C67",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1070-G10Y",
    HEX: "#00B65E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1070-G20Y",
    HEX: "#4FBD4E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1070-G30Y",
    HEX: "#75BB3D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1070-G40Y",
    HEX: "#96C12D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1070-G50Y",
    HEX: "#AAC30F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1070-G60Y",
    HEX: "#BACB00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1070-G70Y",
    HEX: "#CACE04",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1070-G80Y",
    HEX: "#DAD100",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1070-G90Y",
    HEX: "#E1CB00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1070-R",
    HEX: "#DD4052",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1070-R10B",
    HEX: "#DB3E64",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1070-R20B",
    HEX: "#D73776",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1070-Y",
    HEX: "#F5CB00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1070-Y10R",
    HEX: "#FBBC00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1070-Y20R",
    HEX: "#FFAC0A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1070-Y30R",
    HEX: "#FA971B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1070-Y40R",
    HEX: "#F98B23",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1070-Y50R",
    HEX: "#F57E28",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1070-Y60R",
    HEX: "#F27333",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1070-Y70R",
    HEX: "#EE673D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1070-Y80R",
    HEX: "#E75947",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1070-Y90R",
    HEX: "#E24C4A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1075-G20Y",
    HEX: "#3CB235",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1075-G40Y",
    HEX: "#8DBA00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1075-G50Y",
    HEX: "#A2BF00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1075-G60Y",
    HEX: "#BAC800",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1075-G70Y",
    HEX: "#CDCD00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1075-G80Y",
    HEX: "#D7CA00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1075-G90Y",
    HEX: "#E2C600",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1080-G30Y",
    HEX: "#64B400",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1080-R",
    HEX: "#C4002B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1080-Y",
    HEX: "#F1C500",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1080-Y10R",
    HEX: "#FBBC00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1080-Y20R",
    HEX: "#F9A800",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1080-Y30R",
    HEX: "#EE8F00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1080-Y40R",
    HEX: "#EC7E00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1080-Y50R",
    HEX: "#E97200",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1080-Y60R",
    HEX: "#E46000",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1080-Y70R",
    HEX: "#E74F0D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1080-Y80R",
    HEX: "#E03C1E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1080-Y90R",
    HEX: "#D31F25",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1085-Y80R",
    HEX: "#DC2900",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1085-Y90R",
    HEX: "#CA0000",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1500-N",
    HEX: "#DBDBD9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1502-B",
    HEX: "#D2D9DC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1502-B50G",
    HEX: "#D4DADA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1502-G",
    HEX: "#D2D8D5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1502-G50Y",
    HEX: "#D8DAD4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1502-R",
    HEX: "#DBD7D6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1502-R50B",
    HEX: "#D9D7DB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1502-Y",
    HEX: "#DBDAD1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1502-Y20R",
    HEX: "#DEDBD3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1502-Y50R",
    HEX: "#DDD8D1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1502-Y80R",
    HEX: "#E0DBD9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-B",
    HEX: "#CFD8DD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-B20G",
    HEX: "#CDD8DA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-B50G",
    HEX: "#CFDADB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-B80G",
    HEX: "#D0DBD9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-G",
    HEX: "#D2DBD5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-G20Y",
    HEX: "#D4DBD3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-G50Y",
    HEX: "#D8DCCF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-G80Y",
    HEX: "#DADACA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-G90Y",
    HEX: "#DCDAC9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-R",
    HEX: "#DED4D3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-R10B",
    HEX: "#DFD4D6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-R20B",
    HEX: "#DDD3D6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-R30B",
    HEX: "#DDD5DA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-R40B",
    HEX: "#DBD5DC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-R50B",
    HEX: "#D8D6DD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-R60B",
    HEX: "#D6D6DE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-R70B",
    HEX: "#D3D7DE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-R80B",
    HEX: "#D2D8DF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-Y",
    HEX: "#DEDAC6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-Y10R",
    HEX: "#DFD9C6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-Y20R",
    HEX: "#DFD7C5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-Y30R",
    HEX: "#E2D7C5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-Y40R",
    HEX: "#E0D4C5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-Y50R",
    HEX: "#E1D4C7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-Y60R",
    HEX: "#E0D3C8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-Y70R",
    HEX: "#DFD3CC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-Y80R",
    HEX: "#DFD2CE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1505-Y90R",
    HEX: "#DDD2CF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-B",
    HEX: "#BFD1D9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-B20G",
    HEX: "#BCD3D7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-B50G",
    HEX: "#BED4D4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-B80G",
    HEX: "#C0D8D2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-G",
    HEX: "#C3D7CB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-G20Y",
    HEX: "#C7D6C6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-G40Y",
    HEX: "#CFD8C1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-G60Y",
    HEX: "#D4D8BD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-G80Y",
    HEX: "#DBD9BA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-G90Y",
    HEX: "#DDD8B8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-R",
    HEX: "#DFC9C8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-R10B",
    HEX: "#DEC9CC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-R20B",
    HEX: "#DECAD0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-R40B",
    HEX: "#D6CBD6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-R60B",
    HEX: "#CFCEDD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-R80B",
    HEX: "#C4D0DD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-R90B",
    HEX: "#C2CFDB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-Y",
    HEX: "#E1D8B7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-Y10R",
    HEX: "#E3D6B5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-Y20R",
    HEX: "#E4D3B3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-Y30R",
    HEX: "#E6D1B3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-Y40R",
    HEX: "#E7CEB5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-Y50R",
    HEX: "#E7CCB7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-Y60R",
    HEX: "#E4CBBA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-Y70R",
    HEX: "#E3C9BC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-Y80R",
    HEX: "#E2C9C0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1510-Y90R",
    HEX: "#DFC7C1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-B",
    HEX: "#B2CDDA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-B20G",
    HEX: "#AFD0D6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-B50G",
    HEX: "#B0D3D2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-B80G",
    HEX: "#B2D5CC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-G",
    HEX: "#B6D5C3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-G20Y",
    HEX: "#BED4BD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-G40Y",
    HEX: "#C8D5B4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-G60Y",
    HEX: "#D1D7B0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-G80Y",
    HEX: "#D9D7AD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-G90Y",
    HEX: "#DED6A9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-R",
    HEX: "#E2C0BF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-R10B",
    HEX: "#DEBFC4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-R20B",
    HEX: "#DCC0C9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-R40B",
    HEX: "#D6C3D5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-R60B",
    HEX: "#C9C8DD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-R80B",
    HEX: "#BACADD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-R90B",
    HEX: "#B6CBDC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-Y",
    HEX: "#E3D6A9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-Y10R",
    HEX: "#E5D3A7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-Y20R",
    HEX: "#E8D0A5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-Y30R",
    HEX: "#EACBA5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-Y40R",
    HEX: "#EBC8A7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-Y50R",
    HEX: "#E9C4A9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-Y60R",
    HEX: "#E9C3AD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-Y70R",
    HEX: "#E6C0AF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-Y80R",
    HEX: "#E6C0B6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1515-Y90R",
    HEX: "#E3BEB8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1550-R70B",
    HEX: "#8395DD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1550-R80B",
    HEX: "#669BDE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1555-B10G",
    HEX: "#00A7C5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1555-R70B",
    HEX: "#7888D7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1555-R80B",
    HEX: "#588ED8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1560-R90B",
    HEX: "#008BCE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1565-B",
    HEX: "#008BB7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1565-G",
    HEX: "#00A86D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1575-R10B",
    HEX: "#B90043",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1580-R",
    HEX: "#A50017",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1580-Y80R",
    HEX: "#C92A11",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 1580-Y90R",
    HEX: "#C0020C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2000-N",
    HEX: "#CECECB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2002-B",
    HEX: "#C5CACC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2002-B50G",
    HEX: "#C7CDCE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2002-G",
    HEX: "#C7CBC9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2002-G50Y",
    HEX: "#CBCEC9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2002-R",
    HEX: "#CCC8C8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2002-R50B",
    HEX: "#CBCACE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2002-Y",
    HEX: "#CDCBC3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2002-Y20R",
    HEX: "#D2D0CA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2002-Y50R",
    HEX: "#D0CAC3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2002-Y80R",
    HEX: "#D0CCCA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-B",
    HEX: "#BEC8CD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-B20G",
    HEX: "#BECACD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-B50G",
    HEX: "#C0CBCB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-B80G",
    HEX: "#BECAC7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-G",
    HEX: "#BFCAC5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-G10Y",
    HEX: "#C2CBC4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-G20Y",
    HEX: "#C2CBC2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-G30Y",
    HEX: "#C4CBC1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-G40Y",
    HEX: "#C6CCC0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-G50Y",
    HEX: "#C5CABC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-G60Y",
    HEX: "#C8CBBE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-G70Y",
    HEX: "#CACCBC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-G80Y",
    HEX: "#CFCFBB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-G90Y",
    HEX: "#D0CEBD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-R",
    HEX: "#D0C5C4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-R10B",
    HEX: "#CEC4C6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-R20B",
    HEX: "#CDC2C5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-R30B",
    HEX: "#CDC5CA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-R40B",
    HEX: "#CCC6CC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-R50B",
    HEX: "#C6C3CB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-R60B",
    HEX: "#C7C7CF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-R70B",
    HEX: "#C4C8CF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-R80B",
    HEX: "#C4C9D0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-R90B",
    HEX: "#C2CBD1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-Y",
    HEX: "#D0CBB9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-Y10R",
    HEX: "#D3CBB7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-Y20R",
    HEX: "#D1CBBA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-Y30R",
    HEX: "#D3C9B8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-Y40R",
    HEX: "#D3C6B6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-Y50R",
    HEX: "#D0C5B9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-Y60R",
    HEX: "#D2C6BC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-Y70R",
    HEX: "#D2C4BC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-Y80R",
    HEX: "#CEC2BD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2005-Y90R",
    HEX: "#CFC4C1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-B",
    HEX: "#B0C1CA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-B10G",
    HEX: "#B2C3C8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-B30G",
    HEX: "#AEC5C8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-B50G",
    HEX: "#B0C7C7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-B70G",
    HEX: "#B0C7C4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-B90G",
    HEX: "#B2CBC2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-G",
    HEX: "#B4C9BC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-G10Y",
    HEX: "#B8CBBD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-G20Y",
    HEX: "#BAC9B8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-G30Y",
    HEX: "#BCC9B6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-G40Y",
    HEX: "#C1C9B5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-G50Y",
    HEX: "#C5CBB4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-G60Y",
    HEX: "#C5C8AF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-G70Y",
    HEX: "#C8CAAD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-G80Y",
    HEX: "#CCCBAC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-G90Y",
    HEX: "#D0CAA9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-R",
    HEX: "#D1BCBB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-R10B",
    HEX: "#D2BCBF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-R20B",
    HEX: "#CEBBC2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-R30B",
    HEX: "#CBBAC3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-R40B",
    HEX: "#C9BDC8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-R50B",
    HEX: "#C7BFCD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-R60B",
    HEX: "#C2C1CF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-R70B",
    HEX: "#BBC2D2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-R80B",
    HEX: "#B5C0CF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-R90B",
    HEX: "#B6C4CF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-Y",
    HEX: "#D1C8A9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-Y10R",
    HEX: "#D3C6A7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-Y20R",
    HEX: "#D3C2A5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-Y30R",
    HEX: "#DDC9AC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-Y40R",
    HEX: "#D8BEA4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-Y50R",
    HEX: "#D8BDA7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-Y60R",
    HEX: "#D6BDAC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-Y70R",
    HEX: "#D9BEB1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-Y80R",
    HEX: "#D2B8AF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2010-Y90R",
    HEX: "#D2B9B3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-B",
    HEX: "#99BDCF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-B10G",
    HEX: "#91BBC8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-B30G",
    HEX: "#93C3C9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-B40G",
    HEX: "#93C1C3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-B50G",
    HEX: "#92C0BF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-B60G",
    HEX: "#95C4C0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-B70G",
    HEX: "#92C3BC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-B90G",
    HEX: "#99C3B5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-G",
    HEX: "#9DC6B0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-G10Y",
    HEX: "#A2C5A9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-G20Y",
    HEX: "#A6C4A4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-G30Y",
    HEX: "#B0C6A2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-G40Y",
    HEX: "#B6C59C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-G50Y",
    HEX: "#B9C598",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-G60Y",
    HEX: "#BFC595",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-G70Y",
    HEX: "#C6C894",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-G80Y",
    HEX: "#CAC691",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-G90Y",
    HEX: "#D1C795",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-R",
    HEX: "#D3A7A8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-R10B",
    HEX: "#D2AAB0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-R20B",
    HEX: "#CFA8B4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-R30B",
    HEX: "#CFADBE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-R40B",
    HEX: "#C7ACC2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-R50B",
    HEX: "#BEADCA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-R60B",
    HEX: "#B4B1CF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-R70B",
    HEX: "#ABB3CF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-R80B",
    HEX: "#A1B4CF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-R90B",
    HEX: "#9DB8CF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-Y",
    HEX: "#D2C390",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-Y10R",
    HEX: "#D6C090",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-Y20R",
    HEX: "#D9BB8A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-Y30R",
    HEX: "#DEBA90",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-Y40R",
    HEX: "#DBB28D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-Y50R",
    HEX: "#DCAD8E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-Y60R",
    HEX: "#D8AC93",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-Y70R",
    HEX: "#DCAC99",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-Y80R",
    HEX: "#D8AA9E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2020-Y90R",
    HEX: "#DAA9A1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-B",
    HEX: "#7DB1CC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-B10G",
    HEX: "#79B5C8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-B30G",
    HEX: "#6DB5BD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-B40G",
    HEX: "#70B9BC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-B50G",
    HEX: "#72BAB8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-B60G",
    HEX: "#75BEB8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-B70G",
    HEX: "#73BCB3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-B90G",
    HEX: "#7BBFA9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-G",
    HEX: "#81BD9D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-G10Y",
    HEX: "#88BD97",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-G20Y",
    HEX: "#91BC8F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-G30Y",
    HEX: "#9DBE8A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-G40Y",
    HEX: "#A9BE83",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-G50Y",
    HEX: "#B3C384",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-G60Y",
    HEX: "#BBC37E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-G70Y",
    HEX: "#C1C37D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-G80Y",
    HEX: "#C8C279",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-G90Y",
    HEX: "#CFC17A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-R",
    HEX: "#D69294",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-R10B",
    HEX: "#D2959F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-R20B",
    HEX: "#CE94A7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-R30B",
    HEX: "#D098B3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-R40B",
    HEX: "#C197BB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-R50B",
    HEX: "#BB9EC5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-R60B",
    HEX: "#AAA1CC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-R70B",
    HEX: "#99A4CD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-R80B",
    HEX: "#8DA6CE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-R90B",
    HEX: "#84ABCB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-Y",
    HEX: "#D2BC76",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-Y10R",
    HEX: "#DABB78",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-Y20R",
    HEX: "#DBB376",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-Y30R",
    HEX: "#E1AC73",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-Y40R",
    HEX: "#DFA374",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-Y50R",
    HEX: "#E3A079",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-Y60R",
    HEX: "#E19F7F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-Y70R",
    HEX: "#E19B84",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-Y80R",
    HEX: "#DD9586",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2030-Y90R",
    HEX: "#D8938D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-B",
    HEX: "#5CA3C6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-B10G",
    HEX: "#4EA7C1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-B20G",
    HEX: "#45ADBE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-B30G",
    HEX: "#44ADB7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-B40G",
    HEX: "#47B1B7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-B50G",
    HEX: "#4CB3B2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-B60G",
    HEX: "#42B6AF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-B70G",
    HEX: "#43B3A7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-B80G",
    HEX: "#4AB7A3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-B90G",
    HEX: "#52B69B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-G",
    HEX: "#61B691",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-G10Y",
    HEX: "#6EB885",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-G20Y",
    HEX: "#7FB77E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-G30Y",
    HEX: "#8CB974",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-G40Y",
    HEX: "#9FB96B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-G50Y",
    HEX: "#A9BB69",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-G60Y",
    HEX: "#B4BE67",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-G70Y",
    HEX: "#BCBE61",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-G80Y",
    HEX: "#C8C161",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-G90Y",
    HEX: "#D1C062",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-R",
    HEX: "#D57F83",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-R10B",
    HEX: "#D17C8C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-R20B",
    HEX: "#CE7C96",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-R30B",
    HEX: "#C77EA3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-R40B",
    HEX: "#BB82B4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-R50B",
    HEX: "#AE85BD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-R60B",
    HEX: "#9D8FC9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-R70B",
    HEX: "#8894CF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-R80B",
    HEX: "#779ACE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-R90B",
    HEX: "#6A9FCC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-Y",
    HEX: "#D8BC60",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-Y10R",
    HEX: "#DDB360",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-Y20R",
    HEX: "#E4AC5D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-Y30R",
    HEX: "#E3A45F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-Y40R",
    HEX: "#E49B60",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-Y50R",
    HEX: "#E19362",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-Y60R",
    HEX: "#E08D66",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-Y70R",
    HEX: "#DB876B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-Y80R",
    HEX: "#DE8473",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2040-Y90R",
    HEX: "#D6817B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-B",
    HEX: "#1998C4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-B10G",
    HEX: "#009EBD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-B20G",
    HEX: "#00A2B7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-B30G",
    HEX: "#00A6B3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-B40G",
    HEX: "#00A6AB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-B50G",
    HEX: "#00A7A5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-B60G",
    HEX: "#00A8A0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-B70G",
    HEX: "#00AB9D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-B80G",
    HEX: "#00AF97",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-B90G",
    HEX: "#00AD8C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-G",
    HEX: "#31AE7E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-G10Y",
    HEX: "#4DAE74",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-G20Y",
    HEX: "#64AE66",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-G30Y",
    HEX: "#7EAE5B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-G40Y",
    HEX: "#94B254",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-G50Y",
    HEX: "#A2B652",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-G60Y",
    HEX: "#ADB74C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-G70Y",
    HEX: "#BABD49",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-G80Y",
    HEX: "#BFB845",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-G90Y",
    HEX: "#CAB846",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-R",
    HEX: "#CD666D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-R10B",
    HEX: "#C76375",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-R20B",
    HEX: "#CC6288",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-R30B",
    HEX: "#C26494",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-R40B",
    HEX: "#B364A6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-R50B",
    HEX: "#A56DB6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-R60B",
    HEX: "#8D76C3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-R70B",
    HEX: "#7181C9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-R80B",
    HEX: "#5B87CD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-R90B",
    HEX: "#398FCB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-Y",
    HEX: "#D1B348",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-Y10R",
    HEX: "#DEAD44",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-Y20R",
    HEX: "#DFA245",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-Y30R",
    HEX: "#E19645",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-Y40R",
    HEX: "#E28F4A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-Y50R",
    HEX: "#DF854F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-Y60R",
    HEX: "#DC7D53",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-Y70R",
    HEX: "#D87354",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-Y80R",
    HEX: "#D67060",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2050-Y90R",
    HEX: "#D36C64",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2055-B10G",
    HEX: "#0093B0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-B",
    HEX: "#008BBA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-B50G",
    HEX: "#009C97",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-B70G",
    HEX: "#00A08E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-B90G",
    HEX: "#009E7A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-G",
    HEX: "#00A56F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-G10Y",
    HEX: "#0AA360",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-G20Y",
    HEX: "#4AA74F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-G30Y",
    HEX: "#6CA747",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-G40Y",
    HEX: "#84A93A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-G50Y",
    HEX: "#96AB34",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-G60Y",
    HEX: "#A8B32E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-G70Y",
    HEX: "#B0B221",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-G80Y",
    HEX: "#BEB529",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-G90Y",
    HEX: "#C8B022",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-R",
    HEX: "#C34752",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-R10B",
    HEX: "#BE3C5C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-R20B",
    HEX: "#BE3C6E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-R30B",
    HEX: "#BB3F84",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-R40B",
    HEX: "#AC4597",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-R70B",
    HEX: "#626BC6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-R80B",
    HEX: "#3776CB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-R90B",
    HEX: "#007FC4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-Y",
    HEX: "#CDAA1F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-Y10R",
    HEX: "#D7A422",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-Y20R",
    HEX: "#DE9A21",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-Y30R",
    HEX: "#DE8C30",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-Y40R",
    HEX: "#DA7F2E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-Y50R",
    HEX: "#D6712E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-Y60R",
    HEX: "#D76B38",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-Y70R",
    HEX: "#D26140",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-Y80R",
    HEX: "#CE5745",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2060-Y90R",
    HEX: "#C94E4B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2065-B",
    HEX: "#0078A1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2065-R20B",
    HEX: "#AB0B5B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2065-R90B",
    HEX: "#0073AE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2070-G10Y",
    HEX: "#009244",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2070-G20Y",
    HEX: "#299C36",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2070-G30Y",
    HEX: "#589C20",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2070-G40Y",
    HEX: "#769F07",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2070-G50Y",
    HEX: "#8CA200",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2070-G60Y",
    HEX: "#A2AB00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2070-G70Y",
    HEX: "#AEAE00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2070-G80Y",
    HEX: "#BAAA00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2070-G90Y",
    HEX: "#C2A800",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2070-R",
    HEX: "#B0072B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2070-R10B",
    HEX: "#A30037",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2070-Y",
    HEX: "#CAA500",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2070-Y10R",
    HEX: "#DBA200",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2070-Y20R",
    HEX: "#D78F00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2070-Y30R",
    HEX: "#DA8000",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2070-Y40R",
    HEX: "#D57100",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2070-Y50R",
    HEX: "#D6660F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2070-Y60R",
    HEX: "#CB560F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2070-Y70R",
    HEX: "#CA4B21",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2070-Y80R",
    HEX: "#C23C28",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2070-Y90R",
    HEX: "#B92F2E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2075-G20Y",
    HEX: "#009625",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2075-G30Y",
    HEX: "#4B9800",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2075-Y60R",
    HEX: "#CE5203",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2075-Y70R",
    HEX: "#C34100",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2500-N",
    HEX: "#C1C1BF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2502-B",
    HEX: "#B9BFC3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2502-B50G",
    HEX: "#BBC1C2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2502-G",
    HEX: "#B9BFBC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2502-G50Y",
    HEX: "#BFC2BD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2502-R",
    HEX: "#C4C0C0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2502-R50B",
    HEX: "#C0BFC3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2502-Y",
    HEX: "#C1BFB6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2502-Y20R",
    HEX: "#C4C1B9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2502-Y50R",
    HEX: "#C4BFB9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2502-Y80R",
    HEX: "#C3BEBB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2555-B20G",
    HEX: "#008EA1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2555-B30G",
    HEX: "#00929C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2555-B40G",
    HEX: "#009092",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2555-B60G",
    HEX: "#00978D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2555-B80G",
    HEX: "#00957F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2565-G",
    HEX: "#008953",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2565-R80B",
    HEX: "#0055AC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2570-G20Y",
    HEX: "#068B28",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2570-G30Y",
    HEX: "#4D8E11",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2570-R",
    HEX: "#8E001B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2570-Y30R",
    HEX: "#C77900",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2570-Y40R",
    HEX: "#C46A00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2570-Y50R",
    HEX: "#BE5B00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2570-Y60R",
    HEX: "#BE4C00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2570-Y70R",
    HEX: "#B43C0B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2570-Y80R",
    HEX: "#AF2A18",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 2570-Y90R",
    HEX: "#A41A1C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3000-N",
    HEX: "#B8B8B6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3005-B",
    HEX: "#A6B0B5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3005-B20G",
    HEX: "#A4B0B3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3005-B50G",
    HEX: "#A7B5B5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3005-B80G",
    HEX: "#A5B2AF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3005-G",
    HEX: "#A7B1AC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3005-G20Y",
    HEX: "#AAB3AB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3005-G50Y",
    HEX: "#AEB3A5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3005-G80Y",
    HEX: "#B7B7A6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3005-R",
    HEX: "#B7ADAC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3005-R20B",
    HEX: "#B8AFB2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3005-R50B",
    HEX: "#B1AEB6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3005-R80B",
    HEX: "#AAB1B8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3005-Y",
    HEX: "#B5B1A1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3005-Y20R",
    HEX: "#BAB09E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3005-Y50R",
    HEX: "#B8AB9F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3005-Y80R",
    HEX: "#BCAEA9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-B",
    HEX: "#99ACB5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-B10G",
    HEX: "#97AAB1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-B30G",
    HEX: "#95AEB2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-B50G",
    HEX: "#99AFAF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-B70G",
    HEX: "#9CB1AE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-B90G",
    HEX: "#9EB3AC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-G",
    HEX: "#9CB1A6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-G10Y",
    HEX: "#9CB0A1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-G20Y",
    HEX: "#A0B09F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-G30Y",
    HEX: "#A7B5A1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-G40Y",
    HEX: "#A7B09B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-G50Y",
    HEX: "#A9B098",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-G60Y",
    HEX: "#AEB298",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-G70Y",
    HEX: "#AFB197",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-G80Y",
    HEX: "#B1B096",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-G90Y",
    HEX: "#B6B093",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-R",
    HEX: "#B79F9D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-R10B",
    HEX: "#B8A3A5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-R20B",
    HEX: "#B5A2A8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-R30B",
    HEX: "#B3A0A9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-R40B",
    HEX: "#AFA2AE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-R50B",
    HEX: "#ADA4B3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-R60B",
    HEX: "#A7A7B6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-R70B",
    HEX: "#A6ABB9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-R80B",
    HEX: "#9DA8B7",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-R90B",
    HEX: "#9BA9B6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-Y",
    HEX: "#BBB091",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-Y10R",
    HEX: "#BDAF92",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-Y20R",
    HEX: "#BCAB90",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-Y30R",
    HEX: "#C2AC92",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-Y40R",
    HEX: "#C0A790",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-Y50R",
    HEX: "#C3A996",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-Y60R",
    HEX: "#BEA494",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-Y70R",
    HEX: "#BDA498",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-Y80R",
    HEX: "#BBA29B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3010-Y90R",
    HEX: "#BAA39E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-B",
    HEX: "#7F9EB1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-B10G",
    HEX: "#7DA5B2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-B30G",
    HEX: "#7CA7AD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-B40G",
    HEX: "#7BA8AB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-B50G",
    HEX: "#7EAAAA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-B60G",
    HEX: "#7AA9A6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-B70G",
    HEX: "#79A9A2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-B90G",
    HEX: "#7FAC9D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-G",
    HEX: "#7FA892",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-G10Y",
    HEX: "#8BAB91",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-G20Y",
    HEX: "#8DAB8D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-G30Y",
    HEX: "#96AC89",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-G40Y",
    HEX: "#9BAA83",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-G50Y",
    HEX: "#A5B084",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-G60Y",
    HEX: "#A6AB7F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-G70Y",
    HEX: "#AAAB7F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-G80Y",
    HEX: "#B1AC7C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-G90Y",
    HEX: "#B8AD7C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-R",
    HEX: "#B98F90",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-R10B",
    HEX: "#BA9096",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-R20B",
    HEX: "#B48F9B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-R30B",
    HEX: "#AE8EA0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-R40B",
    HEX: "#AC91A8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-R50B",
    HEX: "#A897B1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-R60B",
    HEX: "#9C98B4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-R70B",
    HEX: "#9099B3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-R80B",
    HEX: "#899BB6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-R90B",
    HEX: "#829CB4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-Y",
    HEX: "#BCAB7B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-Y10R",
    HEX: "#C1A97C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-Y20R",
    HEX: "#C0A276",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-Y30R",
    HEX: "#C59F76",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-Y40R",
    HEX: "#C19979",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-Y50R",
    HEX: "#CA9C7F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-Y60R",
    HEX: "#C2967F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-Y70R",
    HEX: "#C59785",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-Y80R",
    HEX: "#BE9185",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3020-Y90R",
    HEX: "#BE9089",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-B",
    HEX: "#6193B0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-B10G",
    HEX: "#5E9BAF",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-B30G",
    HEX: "#5EA0A8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-B40G",
    HEX: "#56A0A4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-B50G",
    HEX: "#5CA3A2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-B60G",
    HEX: "#56A29C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-B70G",
    HEX: "#59A39A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-B90G",
    HEX: "#5CA38F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-G",
    HEX: "#67A286",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-G10Y",
    HEX: "#73A982",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-G20Y",
    HEX: "#77A378",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-G30Y",
    HEX: "#83A573",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-G40Y",
    HEX: "#91A56F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-G50Y",
    HEX: "#9BA76D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-G60Y",
    HEX: "#A1A767",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-G70Y",
    HEX: "#A7A967",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-G80Y",
    HEX: "#AEA765",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-G90Y",
    HEX: "#B7A863",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-R",
    HEX: "#BA7A7D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-R10B",
    HEX: "#B87B86",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-R20B",
    HEX: "#B2798D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-R30B",
    HEX: "#B17A94",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-R40B",
    HEX: "#A77CA2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-R50B",
    HEX: "#9F81AB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-R60B",
    HEX: "#8E86B3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-R70B",
    HEX: "#808AB5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-R80B",
    HEX: "#718CB3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-R90B",
    HEX: "#6B92B5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-Y",
    HEX: "#B9A363",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-Y10R",
    HEX: "#C2A264",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-Y20R",
    HEX: "#C1995F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-Y30R",
    HEX: "#C69562",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-Y40R",
    HEX: "#C68E61",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-Y50R",
    HEX: "#C88A64",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-Y60R",
    HEX: "#C6886B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-Y70R",
    HEX: "#C5826C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-Y80R",
    HEX: "#BE7B6E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3030-Y90R",
    HEX: "#BD7A74",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-B",
    HEX: "#3389AE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-B10G",
    HEX: "#258FA9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-B20G",
    HEX: "#2293A5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-B30G",
    HEX: "#2197A2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-B40G",
    HEX: "#18969B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-B50G",
    HEX: "#229797",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-B60G",
    HEX: "#229C96",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-B70G",
    HEX: "#299C90",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-B80G",
    HEX: "#2A9B8A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-B90G",
    HEX: "#309A7F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-G",
    HEX: "#459A76",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-G10Y",
    HEX: "#549C6D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-G20Y",
    HEX: "#6,59E+68",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-G30Y",
    HEX: "#749E5F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-G40Y",
    HEX: "#869F59",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-G50Y",
    HEX: "#93A254",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-G60Y",
    HEX: "#9CA353",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-G70Y",
    HEX: "#A1A150",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-G80Y",
    HEX: "#ADA44E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-G90Y",
    HEX: "#B2A14C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-R",
    HEX: "#B66468",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-R10B",
    HEX: "#AF5E6D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-R20B",
    HEX: "#B05E78",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-R30B",
    HEX: "#AA6288",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-R40B",
    HEX: "#A26496",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-R50B",
    HEX: "#996CA3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-R60B",
    HEX: "#816FAC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-R70B",
    HEX: "#6C79B5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-R80B",
    HEX: "#5A7CB6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-R90B",
    HEX: "#4A85B3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-Y",
    HEX: "#B99D49",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-Y10R",
    HEX: "#C1984A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-Y20R",
    HEX: "#C4924A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-Y30R",
    HEX: "#C4894C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-Y40R",
    HEX: "#C5814D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-Y50R",
    HEX: "#C47A4F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-Y60R",
    HEX: "#C67752",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-Y70R",
    HEX: "#C17157",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-Y80R",
    HEX: "#C16C5D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3040-Y90R",
    HEX: "#BA6660",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-B",
    HEX: "#0078A1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-B10G",
    HEX: "#00829F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-B20G",
    HEX: "#008697",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-B30G",
    HEX: "#008791",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-B40G",
    HEX: "#008A8D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-B50G",
    HEX: "#008E8A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-B60G",
    HEX: "#008B83",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-B70G",
    HEX: "#008C7D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-B80G",
    HEX: "#008C7A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-B90G",
    HEX: "#009071",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-G",
    HEX: "#009068",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-G10Y",
    HEX: "#2A8F57",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-G20Y",
    HEX: "#4A9350",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-G30Y",
    HEX: "#629144",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-G40Y",
    HEX: "#779540",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-G50Y",
    HEX: "#87993A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-G60Y",
    HEX: "#959C36",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-G70Y",
    HEX: "#9A9B31",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-G80Y",
    HEX: "#A49A35",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-G90Y",
    HEX: "#AB9830",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-R",
    HEX: "#AC4952",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-R10B",
    HEX: "#A83F55",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-R20B",
    HEX: "#A83F64",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-R30B",
    HEX: "#A24074",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-R40B",
    HEX: "#923F84",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-R50B",
    HEX: "#8A4E99",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-R60B",
    HEX: "#6F55A5",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-R70B",
    HEX: "#545FAC",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-R80B",
    HEX: "#2F67AD",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-R90B",
    HEX: "#1A73AE",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-Y",
    HEX: "#B2912D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-Y10R",
    HEX: "#BE8E2D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-Y20R",
    HEX: "#C18830",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-Y30R",
    HEX: "#C17D36",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-Y40R",
    HEX: "#C47536",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-Y50R",
    HEX: "#BC6A37",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-Y60R",
    HEX: "#BB633C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-Y70R",
    HEX: "#B9593F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-Y80R",
    HEX: "#B45342",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3050-Y90R",
    HEX: "#B5524B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3055-B50G",
    HEX: "#00837E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3055-R30B",
    HEX: "#931361",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3055-R40B",
    HEX: "#882373",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3055-R50B",
    HEX: "#82398E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-B",
    HEX: "#00688E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-B10G",
    HEX: "#00728A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-B20G",
    HEX: "#007381",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-B30G",
    HEX: "#007C84",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-B40G",
    HEX: "#007F81",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-B70G",
    HEX: "#007B6C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-B90G",
    HEX: "#007C5C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-G",
    HEX: "#007D4E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-G10Y",
    HEX: "#008644",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-G20Y",
    HEX: "#298938",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-G30Y",
    HEX: "#528A2B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-G40Y",
    HEX: "#6A8B1C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-G50Y",
    HEX: "#7B8D0B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-G60Y",
    HEX: "#8C9100",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-G70Y",
    HEX: "#98991A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-G80Y",
    HEX: "#9E9300",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-G90Y",
    HEX: "#A59000",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-R",
    HEX: "#95172B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-R10B",
    HEX: "#970136",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-R20B",
    HEX: "#910549",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-R70B",
    HEX: "#3B45A4",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-R80B",
    HEX: "#004D98",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-R90B",
    HEX: "#006196",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-Y",
    HEX: "#B08D00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-Y10R",
    HEX: "#BA8800",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-Y20R",
    HEX: "#BA7C00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-Y30R",
    HEX: "#C07209",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-Y40R",
    HEX: "#BC6515",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-Y50R",
    HEX: "#BA5D14",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-Y60R",
    HEX: "#B9551F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-Y70R",
    HEX: "#AF4623",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-Y80R",
    HEX: "#AC3D2A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3060-Y90R",
    HEX: "#A22F2D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3065-G10Y",
    HEX: "#007934",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3065-G40Y",
    HEX: "#638601",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3065-G50Y",
    HEX: "#798900",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3065-G60Y",
    HEX: "#8A8F00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3065-R90B",
    HEX: "#005182",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3065-Y20R",
    HEX: "#BB7C00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3500-N",
    HEX: "#ABAAA8",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3502-B",
    HEX: "#A1A6A9",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3502-B50G",
    HEX: "#A4AAAA",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3502-G",
    HEX: "#A1A7A6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3502-G50Y",
    HEX: "#A6A8A2",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3502-R",
    HEX: "#AAA6A6",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3502-R50B",
    HEX: "#A8A7AB",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3502-Y",
    HEX: "#AAA8A1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3502-Y20R",
    HEX: "#AAA79F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3502-Y50R",
    HEX: "#ACA7A0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3502-Y80R",
    HEX: "#AAA5A3",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3555-B60G",
    HEX: "#00786E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3555-B80G",
    HEX: "#007661",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3555-R60B",
    HEX: "#4A2B85",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3560-G",
    HEX: "#00703F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3560-G10Y",
    HEX: "#006B2B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3560-G20Y",
    HEX: "#67525",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3560-G30Y",
    HEX: "#477B21",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3560-G40Y",
    HEX: "#5A7901",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3560-G50Y",
    HEX: "#708106",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3560-G60Y",
    HEX: "#7F8400",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3560-G70Y",
    HEX: "#848300",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3560-R",
    HEX: "#7D001A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3560-R80B",
    HEX: "#004087",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3560-R90B",
    HEX: "#004D7A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3560-Y",
    HEX: "#A68100",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3560-Y20R",
    HEX: "#AA7000",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3560-Y30R",
    HEX: "#A96400",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3560-Y40R",
    HEX: "#AF5C00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3560-Y50R",
    HEX: "#A95106",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3560-Y60R",
    HEX: "#A64613",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3560-Y70R",
    HEX: "#A13811",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3560-Y80R",
    HEX: "#982815",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 3560-Y90R",
    HEX: "#871510",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4000-N",
    HEX: "#9E9E9E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4005-B",
    HEX: "#90989D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4005-B20G",
    HEX: "#8C999C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4005-B50G",
    HEX: "#8E9A9A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4005-B80G",
    HEX: "#8F9C9A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4005-G",
    HEX: "#919B96",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4005-G20Y",
    HEX: "#939C94",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4005-G50Y",
    HEX: "#979B8D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4005-G80Y",
    HEX: "#9B9B8B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4005-R",
    HEX: "#A19594",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4005-R20B",
    HEX: "#9D9397",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4005-R50B",
    HEX: "#99969E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4005-R80B",
    HEX: "#9299A1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4005-Y",
    HEX: "#A19D90",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4005-Y20R",
    HEX: "#A49A8A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4005-Y50R",
    HEX: "#A3968B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4005-Y80R",
    HEX: "#A49792",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4010-B10G",
    HEX: "#82959C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4010-B30G",
    HEX: "#80969A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4010-B50G",
    HEX: "#849899",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4010-B70G",
    HEX: "#7F9895",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4010-B90G",
    HEX: "#819992",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4010-G10Y",
    HEX: "#869A8B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4010-G30Y",
    HEX: "#8D9A88",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4010-G50Y",
    HEX: "#949A87",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4010-G70Y",
    HEX: "#9A9C83",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4010-G90Y",
    HEX: "#9F997E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4010-R10B",
    HEX: "#A18B8F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4010-R30B",
    HEX: "#A08D97",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4010-R50B",
    HEX: "#97919E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4010-R70B",
    HEX: "#8D92A0",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4010-R90B",
    HEX: "#84929E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4010-Y10R",
    HEX: "#AA9C81",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4010-Y30R",
    HEX: "#AA9680",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4010-Y50R",
    HEX: "#AB9383",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4010-Y70R",
    HEX: "#A98E84",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4010-Y90R",
    HEX: "#A48D88",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-B",
    HEX: "#63879A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-B10G",
    HEX: "#658D9A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-B30G",
    HEX: "#618D93",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-B50G",
    HEX: "#659292",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-B70G",
    HEX: "#61928B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-B90G",
    HEX: "#679285",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-G",
    HEX: "#66907C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-G10Y",
    HEX: "#6F947A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-G30Y",
    HEX: "#7C9372",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-G50Y",
    HEX: "#8C956C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-G70Y",
    HEX: "#96976B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-G90Y",
    HEX: "#9E946A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-R",
    HEX: "#A37475",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-R10B",
    HEX: "#A1777E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-R20B",
    HEX: "#9D7582",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-R30B",
    HEX: "#9C788A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-R40B",
    HEX: "#957992",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-R50B",
    HEX: "#########",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-R60B",
    HEX: "#837E9B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-R70B",
    HEX: "#77809E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-R80B",
    HEX: "#71849F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-R90B",
    HEX: "#6B859E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-Y",
    HEX: "#A39164",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-Y10R",
    HEX: "#A58E62",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-Y20R",
    HEX: "#AD8E64",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-Y30R",
    HEX: "#AE8B66",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-Y40R",
    HEX: "#AC8464",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-Y50R",
    HEX: "#AE8166",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-Y60R",
    HEX: "#AC7E68",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-Y70R",
    HEX: "#AB7C6C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-Y80R",
    HEX: "#A8786D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4020-Y90R",
    HEX: "#A67873",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-B",
    HEX: "#457B97",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-B10G",
    HEX: "#3D8095",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-B30G",
    HEX: "#408790",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-B50G",
    HEX: "#3A8787",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-B70G",
    HEX: "#3D8C84",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-B90G",
    HEX: "#448978",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-G",
    HEX: "#49896C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-G10Y",
    HEX: "#558B67",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-G30Y",
    HEX: "#6B8B5E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-G50Y",
    HEX: "#7F8B53",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-G70Y",
    HEX: "#909054",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-G90Y",
    HEX: "#9D8D52",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-R",
    HEX: "#9F5E61",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-R10B",
    HEX: "#9C5F69",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-R20B",
    HEX: "#9,95E+74",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-R30B",
    HEX: "#965F7B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-R40B",
    HEX: "#8D6085",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-R50B",
    HEX: "#886792",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-R60B",
    HEX: "#736897",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-R70B",
    HEX: "#6874A1",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-R80B",
    HEX: "#57729E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-R90B",
    HEX: "#547B9E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-Y",
    HEX: "#A0894C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-Y10R",
    HEX: "#A7874B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-Y20R",
    HEX: "#AA814B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-Y30R",
    HEX: "#AF7E4F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-Y40R",
    HEX: "#AB754D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-Y50R",
    HEX: "#AC724F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-Y60R",
    HEX: "#AA6C51",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-Y70R",
    HEX: "#AA6955",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-Y80R",
    HEX: "#A76659",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4030-Y90R",
    HEX: "#A3645F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-B",
    HEX: "#006E92",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-B10G",
    HEX: "#006F87",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-B20G",
    HEX: "#007888",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-B30G",
    HEX: "#007C86",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-B40G",
    HEX: "#007C81",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-B50G",
    HEX: "#007D7C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-B60G",
    HEX: "#007D77",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-B70G",
    HEX: "#007B70",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-B80G",
    HEX: "#007E6C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-B90G",
    HEX: "#007E67",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-G",
    HEX: "#1A7F5C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-G10Y",
    HEX: "#337F52",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-G20Y",
    HEX: "#49834E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-G30Y",
    HEX: "#5B8448",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-G40Y",
    HEX: "#6C8442",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-G50Y",
    HEX: "#76853F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-G60Y",
    HEX: "#83883E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-G70Y",
    HEX: "#87883E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-G80Y",
    HEX: "#8F8636",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-G90Y",
    HEX: "#948231",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-R",
    HEX: "#9B4C4F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-R10B",
    HEX: "#934352",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-R20B",
    HEX: "#943F5D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-R30B",
    HEX: "#8D4168",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-R40B",
    HEX: "#834478",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-R50B",
    HEX: "#774A83",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-R60B",
    HEX: "#644F8C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-R70B",
    HEX: "#4E5A99",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-R80B",
    HEX: "#42639C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-R90B",
    HEX: "#2E6899",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-Y",
    HEX: "#9C8135",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-Y10R",
    HEX: "#A27C35",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-Y20R",
    HEX: "#A77834",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-Y30R",
    HEX: "#AA7238",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-Y40R",
    HEX: "#A66637",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-Y50R",
    HEX: "#AA6439",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-Y60R",
    HEX: "#A85E3A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-Y70R",
    HEX: "#A2563E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-Y80R",
    HEX: "#A15042",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4040-Y90R",
    HEX: "#9E4D48",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-B",
    HEX: "#006182",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-B10G",
    HEX: "#006278",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-B20G",
    HEX: "#006B79",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-B30G",
    HEX: "#006E76",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-B40G",
    HEX: "#006C6D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-B50G",
    HEX: "#006D68",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-B60G",
    HEX: "#006A61",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-B70G",
    HEX: "#006D60",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-B80G",
    HEX: "#006E5A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-B90G",
    HEX: "#006E54",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-G",
    HEX: "#006D44",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-G10Y",
    HEX: "#00733D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-G20Y",
    HEX: "#2A7535",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-G30Y",
    HEX: "#467630",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-G40Y",
    HEX: "#5F7B28",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-G50Y",
    HEX: "#6C7C23",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-G60Y",
    HEX: "#7A7F18",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-G70Y",
    HEX: "#83821D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-G80Y",
    HEX: "#847800",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-G90Y",
    HEX: "#8F7C15",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-R",
    HEX: "#8A222C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-R10B",
    HEX: "#770029",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-R20B",
    HEX: "#7D033B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-R30B",
    HEX: "#7E0750",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-R40B",
    HEX: "#751B62",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-R50B",
    HEX: "#692070",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-R60B",
    HEX: "#462F80",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-R70B",
    HEX: "#313D8C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-R80B",
    HEX: "#004484",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-R90B",
    HEX: "#005482",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-Y",
    HEX: "#997B10",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-Y10R",
    HEX: "#9E7108",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-Y20R",
    HEX: "#A26B11",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-Y30R",
    HEX: "#A5651A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-Y40R",
    HEX: "#A45A18",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-Y50R",
    HEX: "#9F511D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-Y60R",
    HEX: "#A04B1F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-Y70R",
    HEX: "#9A3F1F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-Y80R",
    HEX: "#913624",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4050-Y90R",
    HEX: "#8A2A27",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4055-B",
    HEX: "#005677",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4055-B40G",
    HEX: "#006463",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4055-R70B",
    HEX: "#1E2982",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4055-Y10R",
    HEX: "#A17400",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4500-N",
    HEX: "#939393",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4502-B",
    HEX: "#8D9195",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4502-B50G",
    HEX: "#8C9293",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4502-G",
    HEX: "#8C9290",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4502-G50Y",
    HEX: "#8F928D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4502-R",
    HEX: "#959091",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4502-R50B",
    HEX: "#919094",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4502-Y",
    HEX: "#94928A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4502-Y20R",
    HEX: "#969289",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4502-Y50R",
    HEX: "#97928B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4502-Y80R",
    HEX: "#94908D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-B",
    HEX: "#004C6A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-B20G",
    HEX: "#005964",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-B30G",
    HEX: "#005C61",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-B40G",
    HEX: "#005F5F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-B50G",
    HEX: "#005D58",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-B80G",
    HEX: "#005947",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-B90G",
    HEX: "#005C42",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-G",
    HEX: "#005F38",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-G10Y",
    HEX: "#00602A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-G20Y",
    HEX: "#0F611C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-G30Y",
    HEX: "#38671B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-G40Y",
    HEX: "#4F6706",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-G50Y",
    HEX: "#5F6C00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-G60Y",
    HEX: "#707307",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-G70Y",
    HEX: "#737104",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-R70B",
    HEX: "#1A2377",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-R80B",
    HEX: "#00306D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-R90B",
    HEX: "#004166",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-Y",
    HEX: "#8B6B00",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-Y30R",
    HEX: "#945700",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-Y40R",
    HEX: "#995000",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-Y50R",
    HEX: "#934500",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-Y60R",
    HEX: "#903F0C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-Y70R",
    HEX: "#8C3610",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-Y80R",
    HEX: "#84291C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 4550-Y90R",
    HEX: "#740B0D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5000-N",
    HEX: "#888889",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5005-B",
    HEX: "#7A8388",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5005-B20G",
    HEX: "#7B8689",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5005-B50G",
    HEX: "#7A8585",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5005-B80G",
    HEX: "#7B8986",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5005-G",
    HEX: "#7A8580",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5005-G20Y",
    HEX: "#7C867C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5005-G50Y",
    HEX: "#83877B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5005-G80Y",
    HEX: "#878778",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5005-R",
    HEX: "#8A7E7D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5005-R20B",
    HEX: "#8C8185",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5005-R50B",
    HEX: "#848189",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5005-R80B",
    HEX: "#7C8088",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5005-Y",
    HEX: "#8A8475",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5005-Y20R",
    HEX: "#908678",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5005-Y50R",
    HEX: "#8E8278",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5005-Y80R",
    HEX: "#8C807D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5010-B10G",
    HEX: "#6C7F86",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5010-B30G",
    HEX: "#6B8084",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5010-B50G",
    HEX: "#6D8384",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5010-B70G",
    HEX: "#6A8481",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5010-B90G",
    HEX: "#6B827A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5010-G10Y",
    HEX: "#6F8376",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5010-G30Y",
    HEX: "#778474",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5010-G50Y",
    HEX: "#7E8470",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5010-G70Y",
    HEX: "#84856E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5010-G90Y",
    HEX: "#8A836C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5010-R10B",
    HEX: "#8A767A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5010-R30B",
    HEX: "#8A7580",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5010-R50B",
    HEX: "#7F7585",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5010-R70B",
    HEX: "#767A8A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5010-R90B",
    HEX: "#6F7D8A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5010-Y10R",
    HEX: "#92836B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5010-Y30R",
    HEX: "#937C65",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5010-Y50R",
    HEX: "#937B6B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5010-Y70R",
    HEX: "#91756B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5010-Y90R",
    HEX: "#8C736F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-B",
    HEX: "#4F7084",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-B10G",
    HEX: "#4C7483",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-B30G",
    HEX: "#4D797F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-B50G",
    HEX: "#4B7A7B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-B70G",
    HEX: "#4A7A74",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-B90G",
    HEX: "#4D7B6C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-G",
    HEX: "#4E7965",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-G10Y",
    HEX: "#567A63",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-G30Y",
    HEX: "#677D5D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-G50Y",
    HEX: "#717A54",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-G70Y",
    HEX: "#7D7D55",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-G90Y",
    HEX: "#867B53",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-R",
    HEX: "#8A5E5F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-R10B",
    HEX: "#8B5D64",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-R20B",
    HEX: "#855C6A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-R30B",
    HEX: "#866074",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-R40B",
    HEX: "#7E5F78",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-R50B",
    HEX: "#75627F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-R60B",
    HEX: "#686383",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-R70B",
    HEX: "#646A88",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-R80B",
    HEX: "#576A88",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-R90B",
    HEX: "#567088",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-Y",
    HEX: "#8B7950",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-Y10R",
    HEX: "#8E7750",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-Y20R",
    HEX: "#94754E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-Y30R",
    HEX: "#916F4D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-Y40R",
    HEX: "#9,66E+52",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-Y50R",
    HEX: "#956C53",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-Y60R",
    HEX: "#946854",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-Y70R",
    HEX: "#966757",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-Y80R",
    HEX: "#926359",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5020-Y90R",
    HEX: "#8E5F5A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-B",
    HEX: "#27617D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-B10G",
    HEX: "#27687C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-B30G",
    HEX: "#206A73",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-B50G",
    HEX: "#196E6E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-B70G",
    HEX: "#0F6D65",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-B90G",
    HEX: "#1E6D5B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-G",
    HEX: "#2B6E53",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-G10Y",
    HEX: "#3A724F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-G30Y",
    HEX: "#527143",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-G50Y",
    HEX: "#69723F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-G70Y",
    HEX: "#777740",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-G90Y",
    HEX: "#847539",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-R",
    HEX: "#7F3D41",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-R10B",
    HEX: "#824550",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-R20B",
    HEX: "#7A3A51",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-R30B",
    HEX: "#7D4361",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-R40B",
    HEX: "#6F4267",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-R50B",
    HEX: "#6A4873",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-R60B",
    HEX: "#564C7B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-R70B",
    HEX: "#4E5684",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-R80B",
    HEX: "#3A5783",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-R90B",
    HEX: "#3,35E+84",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-Y",
    HEX: "#887039",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-Y10R",
    HEX: "#8F6F39",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-Y20R",
    HEX: "#916B39",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-Y30R",
    HEX: "#916438",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-Y40R",
    HEX: "#9,05E+41",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-Y50R",
    HEX: "#925A3A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-Y60R",
    HEX: "#8F5339",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-Y70R",
    HEX: "#905340",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-Y80R",
    HEX: "#8A4B3F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5030-Y90R",
    HEX: "#864843",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-B",
    HEX: "#004F6C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-B10G",
    HEX: "#00556A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-B20G",
    HEX: "#005E6B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-B30G",
    HEX: "#005E65",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-B40G",
    HEX: "#005F60",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-B50G",
    HEX: "#005D5A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-B60G",
    HEX: "#005D55",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-B70G",
    HEX: "#005D52",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-B80G",
    HEX: "#005E4E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-B90G",
    HEX: "#005E47",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-G",
    HEX: "#005F3D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-G10Y",
    HEX: "#56135",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-G20Y",
    HEX: "#2B6534",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-G30Y",
    HEX: "#40672D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-G40Y",
    HEX: "#526826",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-G50Y",
    HEX: "#5C6926",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-G60Y",
    HEX: "#66691D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-G70Y",
    HEX: "#6B6A1E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-G80Y",
    HEX: "#726810",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-G90Y",
    HEX: "#7A6610",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-R",
    HEX: "#75222A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-R10B",
    HEX: "#69142B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-R20B",
    HEX: "#690233",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-R30B",
    HEX: "#6A0E44",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-R40B",
    HEX: "#5B104F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-R50B",
    HEX: "#551E5E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-R60B",
    HEX: "#40266D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-R70B",
    HEX: "#273274",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-R80B",
    HEX: "#003E77",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-R90B",
    HEX: "#00436B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-Y",
    HEX: "#82650C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-Y10R",
    HEX: "#8,65E+03",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-Y20R",
    HEX: "#8F6017",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-Y30R",
    HEX: "#905919",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-Y40R",
    HEX: "#90511C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-Y50R",
    HEX: "#8C4719",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-Y60R",
    HEX: "#873F18",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-Y70R",
    HEX: "#863C22",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-Y80R",
    HEX: "#7C2F21",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5040-Y90R",
    HEX: "#752621",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5045-B10G",
    HEX: "#004B5C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5500-N",
    HEX: "#7E7E7D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5502-B",
    HEX: "#777C80",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5502-B50G",
    HEX: "#777D7D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5502-G",
    HEX: "#777D7B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5502-G50Y",
    HEX: "#7A7D78",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5502-R",
    HEX: "#807B7B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5502-R50B",
    HEX: "#7C7C80",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5502-Y",
    HEX: "#7E7C76",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5502-Y20R",
    HEX: "#7F7B74",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5502-Y50R",
    HEX: "#817C77",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5502-Y80R",
    HEX: "#807B79",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5540-B",
    HEX: "#00405A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5540-B10G",
    HEX: "#00495A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5540-B20G",
    HEX: "#004A52",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5540-B30G",
    HEX: "#004D51",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5540-B40G",
    HEX: "#004D4B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5540-B50G",
    HEX: "#00524F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5540-B80G",
    HEX: "#004C3A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5540-B90G",
    HEX: "#004C36",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5540-G",
    HEX: "#005230",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5540-G10Y",
    HEX: "#004D1F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5540-G20Y",
    HEX: "#004E11",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5540-G30Y",
    HEX: "#2C5413",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5540-G40Y",
    HEX: "#425910",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5540-G50Y",
    HEX: "#4C5500",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5540-G60Y",
    HEX: "#5A5B04",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5540-G70Y",
    HEX: "#5F5C09",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5540-R70B",
    HEX: "#51963",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5540-R90B",
    HEX: "#003557",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 5540-Y90R",
    HEX: "#651311",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6000-N",
    HEX: "#737373",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6005-B",
    HEX: "#6,56E+76",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6005-B20G",
    HEX: "#6,36E+73",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6005-B50G",
    HEX: "#626E6E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6005-B80G",
    HEX: "#66706F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6005-G",
    HEX: "#636F69",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6005-G20Y",
    HEX: "#667067",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6005-G50Y",
    HEX: "#6E7367",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6005-G80Y",
    HEX: "#737265",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6005-R",
    HEX: "#736666",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6005-R20B",
    HEX: "#76696E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6005-R50B",
    HEX: "#6F6B74",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6005-R80B",
    HEX: "#656B74",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6005-Y",
    HEX: "#756F62",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6005-Y20R",
    HEX: "#796F61",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6005-Y50R",
    HEX: "#7B6C62",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6005-Y80R",
    HEX: "#786C68",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6010-B10G",
    HEX: "#566870",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6010-B30G",
    HEX: "#566B6E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6010-B50G",
    HEX: "#556B6C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6010-B70G",
    HEX: "#516966",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6010-B90G",
    HEX: "#546B65",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6010-G10Y",
    HEX: "#586B5F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6010-G30Y",
    HEX: "#606D5C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6010-G50Y",
    HEX: "#686D59",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6010-G70Y",
    HEX: "#6F6F58",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6010-G90Y",
    HEX: "#726D5A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6010-R10B",
    HEX: "#745D61",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6010-R30B",
    HEX: "#705A66",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6010-R50B",
    HEX: "#6B5F71",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6010-R70B",
    HEX: "#606577",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6010-R90B",
    HEX: "#566370",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6010-Y10R",
    HEX: "#796A54",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6010-Y30R",
    HEX: "#806954",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6010-Y50R",
    HEX: "#7A6254",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6010-Y70R",
    HEX: "#785F55",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6010-Y90R",
    HEX: "#7A605C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-B",
    HEX: "#33566A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-B10G",
    HEX: "#2D5765",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-B30G",
    HEX: "#3,05E+67",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-B50G",
    HEX: "#2F5E5F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-B70G",
    HEX: "#2E605A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-B90G",
    HEX: "#2D5F53",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-G",
    HEX: "#345F4D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-G10Y",
    HEX: "#3B6047",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-G30Y",
    HEX: "#4B6242",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-G50Y",
    HEX: "#5D6542",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-G70Y",
    HEX: "#66653F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-G90Y",
    HEX: "#6E613C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-R",
    HEX: "#704143",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-R10B",
    HEX: "#70434B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-R20B",
    HEX: "#6A3D4D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-R30B",
    HEX: "#693F55",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-R40B",
    HEX: "#61415C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-R50B",
    HEX: "#5C4461",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-R60B",
    HEX: "#4B4669",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-R70B",
    HEX: "#444A6D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-R80B",
    HEX: "#374B6C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-R90B",
    HEX: "#34526D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-Y",
    HEX: "#73613A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-Y10R",
    HEX: "#775F3C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-Y20R",
    HEX: "#785935",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-Y30R",
    HEX: "#7A5838",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-Y40R",
    HEX: "#7C5437",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-Y50R",
    HEX: "#7C533A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-Y60R",
    HEX: "#7A4E39",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-Y70R",
    HEX: "#7A4C3C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-Y80R",
    HEX: "#77493F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6020-Y90R",
    HEX: "#754843",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-B",
    HEX: "#00435C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-B10G",
    HEX: "#00495B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-B30G",
    HEX: "#004F56",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-B50G",
    HEX: "#004B48",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-B70G",
    HEX: "#004B42",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-B90G",
    HEX: "#004E3C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-G",
    HEX: "#004F33",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-G10Y",
    HEX: "#105331",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-G30Y",
    HEX: "#37562A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-G50Y",
    HEX: "#4D5620",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-G70Y",
    HEX: "#5A581F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-G90Y",
    HEX: "#695821",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-R",
    HEX: "#6,01E+24",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-R10B",
    HEX: "#5B1726",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-R20B",
    HEX: "#5A0F30",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-R30B",
    HEX: "#570536",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-R40B",
    HEX: "#4C1543",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-R50B",
    HEX: "#461C4D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-R60B",
    HEX: "#322459",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-R70B",
    HEX: "#1B275D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-R80B",
    HEX: "#003062",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-R90B",
    HEX: "#003C5F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-Y",
    HEX: "#6B5311",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-Y10R",
    HEX: "#71531A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-Y20R",
    HEX: "#765020",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-Y30R",
    HEX: "#794D1D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-Y40R",
    HEX: "#784519",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-Y50R",
    HEX: "#784320",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-Y60R",
    HEX: "#733D22",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-Y70R",
    HEX: "#70351F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-Y80R",
    HEX: "#6D3023",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6030-Y90R",
    HEX: "#662721",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6035-B60G",
    HEX: "#00463E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6500-N",
    HEX: "#686767",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6502-B",
    HEX: "#606568",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6502-B50G",
    HEX: "#5F6565",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6502-G",
    HEX: "#606664",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6502-G50Y",
    HEX: "#646762",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6502-R",
    HEX: "#696262",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6502-R50B",
    HEX: "#646366",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6502-Y",
    HEX: "#686761",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6502-Y20R",
    HEX: "#6A6660",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6502-Y50R",
    HEX: "#6B655F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6502-Y80R",
    HEX: "#696361",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6530-B30G",
    HEX: "#003E41",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6530-B50G",
    HEX: "#00413D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6530-G10Y",
    HEX: "#00411A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 6530-G50Y",
    HEX: "#414A11",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7000-N",
    HEX: "#5D5C5C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7005-B",
    HEX: "#4B545B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7005-B20G",
    HEX: "#4A5458",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7005-B50G",
    HEX: "#4A5555",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7005-B80G",
    HEX: "#4A5653",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7005-G",
    HEX: "#48544E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7005-G20Y",
    HEX: "#4F5850",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7005-G50Y",
    HEX: "#52564C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7005-G80Y",
    HEX: "#5A594D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7005-R",
    HEX: "#5E4E4E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7005-R20B",
    HEX: "#605358",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7005-R50B",
    HEX: "#545159",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7005-R80B",
    HEX: "#4F545E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7005-Y",
    HEX: "#5A5448",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7005-Y20R",
    HEX: "#5C5449",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7005-Y50R",
    HEX: "#61554D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7005-Y80R",
    HEX: "#5E514D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7010-B10G",
    HEX: "#3C5058",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7010-B30G",
    HEX: "#3B5053",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7010-B50G",
    HEX: "#395254",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7010-B70G",
    HEX: "#36514E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7010-B90G",
    HEX: "#3C524C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7010-G10Y",
    HEX: "#415548",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7010-G30Y",
    HEX: "#465343",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7010-G50Y",
    HEX: "#4F5441",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7010-G70Y",
    HEX: "#52523D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7010-G90Y",
    HEX: "#5A513B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7010-R10B",
    HEX: "#5B3D41",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7010-R30B",
    HEX: "#58434F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7010-R50B",
    HEX: "#504756",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7010-R70B",
    HEX: "#43475A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7010-R90B",
    HEX: "#3D4A58",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7010-Y10R",
    HEX: "#625440",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7010-Y30R",
    HEX: "#624E3C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7010-Y50R",
    HEX: "#664C3D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7010-Y70R",
    HEX: "#5C4239",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7010-Y90R",
    HEX: "#604541",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-B",
    HEX: "#003248",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-B10G",
    HEX: "#043C4B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-B30G",
    HEX: "#043F46",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-B50G",
    HEX: "#004140",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-B70G",
    HEX: "#00413A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-B90G",
    HEX: "#003D2E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-G",
    HEX: "#00402A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-G10Y",
    HEX: "#194329",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-G30Y",
    HEX: "#2C4523",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-G50Y",
    HEX: "#3E451D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-G70Y",
    HEX: "#46451B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-G90Y",
    HEX: "#4E4015",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-R",
    HEX: "#4E1F1E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-R10B",
    HEX: "#48131D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-R20B",
    HEX: "#4,60E+30",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-R30B",
    HEX: "#441230",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-R40B",
    HEX: "#3,80E+35",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-R50B",
    HEX: "#371B40",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-R60B",
    HEX: "#251B44",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-R70B",
    HEX: "#202A4F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-R80B",
    HEX: "#09294D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-R90B",
    HEX: "#062F49",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-Y",
    HEX: "#533C09",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-Y10R",
    HEX: "#5B4215",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-Y20R",
    HEX: "#60431D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-Y30R",
    HEX: "#5E3C17",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-Y40R",
    HEX: "#613C1E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-Y50R",
    HEX: "#603514",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-Y60R",
    HEX: "#62361D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-Y70R",
    HEX: "#5B2B17",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-Y80R",
    HEX: "#58271B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7020-Y90R",
    HEX: "#4F1E16",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7500-N",
    HEX: "#505051",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7502-B",
    HEX: "#464A4E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7502-G",
    HEX: "#454A48",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7502-R",
    HEX: "#51494A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 7502-Y",
    HEX: "#504D48",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8000-N",
    HEX: "#3F3F3E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8005-B",
    HEX: "#28343B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8005-B20G",
    HEX: "#253336",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8005-B50G",
    HEX: "#283636",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8005-B80G",
    HEX: "#273532",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8005-G",
    HEX: "#293830",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8005-G20Y",
    HEX: "#2C362E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8005-G50Y",
    HEX: "#35392F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8005-G80Y",
    HEX: "#3C3B2F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8005-R",
    HEX: "#3D2B2B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8005-R20B",
    HEX: "#3C2E34",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8005-R50B",
    HEX: "#2B2733",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8005-R80B",
    HEX: "#2C323C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8005-Y",
    HEX: "#3F3829",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8005-Y20R",
    HEX: "#43392E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8005-Y50R",
    HEX: "#44362B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8005-Y80R",
    HEX: "#41312C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8010-B10G",
    HEX: "#102A31",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8010-B30G",
    HEX: "#062C2F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8010-B50G",
    HEX: "#102F2E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8010-B70G",
    HEX: "#0B302D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8010-B90G",
    HEX: "#0D2E25",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8010-G10Y",
    HEX: "#16301F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8010-G30Y",
    HEX: "#22321C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8010-G50Y",
    HEX: "#2D331F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8010-G70Y",
    HEX: "#373720",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8010-G90Y",
    HEX: "#3B3119",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8010-R10B",
    HEX: "#35131B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8010-R30B",
    HEX: "#2C1626",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8010-R50B",
    HEX: "#2A1C31",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8010-R70B",
    HEX: "#172238",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8010-R90B",
    HEX: "#0F2436",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8010-Y10R",
    HEX: "#44341D",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8010-Y30R",
    HEX: "#432D14",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8010-Y50R",
    HEX: "#442C1A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8010-Y70R",
    HEX: "#3F2112",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8010-Y90R",
    HEX: "#3A1D18",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8500-N",
    HEX: "#2D2C2C",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8502-B",
    HEX: "#23272B",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8502-G",
    HEX: "#1E2722",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8502-R",
    HEX: "#2E2323",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8502-Y",
    HEX: "#2D2A23",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8505-B",
    HEX: "#0F1D25",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8505-B20G",
    HEX: "#142225",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8505-B50G",
    HEX: "#0A1F1E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8505-B80G",
    HEX: "#152521",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8505-G",
    HEX: "#102419",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8505-G20Y",
    HEX: "#17261A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8505-G50Y",
    HEX: "#202612",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8505-G80Y",
    HEX: "#282717",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8505-R",
    HEX: "#26100F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8505-R20B",
    HEX: "#281922",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8505-R50B",
    HEX: "#251D28",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8505-R80B",
    HEX: "#06121E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8505-Y",
    HEX: "#271E0A",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8505-Y20R",
    HEX: "#2B200F",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8505-Y50R",
    HEX: "#332210",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 8505-Y80R",
    HEX: "#2B1A12",
    Collection: "NCS Index 2050",
  },
  {
    Name: "S 9000-N",
    HEX: "#0D0E0E",
    Collection: "NCS Index 2050",
  },
  {
    Name: "8183 Örtagård",
    HEX: "#B2A97F",
    Collection: "Jotun Lady",
  },
  {
    Name: "1109 Lysning",
    HEX: "#CDB385",
    Collection: "Jotun Lady",
  },
  {
    Name: "1276 Soft",
    HEX: "#DCD1BA",
    Collection: "Jotun Lady",
  },
  {
    Name: "1536 Burnt Ochre",
    HEX: "#C99765",
    Collection: "Jotun Lady",
  },
  {
    Name: "11012 Rustic Brown",
    HEX: "#887E6E",
    Collection: "Jotun Lady",
  },
  {
    Name: "12123 Contemporary White",
    HEX: "#E1DACA",
    Collection: "Jotun Lady",
  },
  {
    Name: "8575 Natural Green",
    HEX: "#7A7861",
    Collection: "Jotun Lady",
  },
  {
    Name: "0486 Silke",
    HEX: "#EAE7DC",
    Collection: "Jotun Lady",
  },
  {
    Name: "10430 Savann",
    HEX: "#BCAA8F",
    Collection: "Jotun Lady",
  },
  {
    Name: "2951 Sophisticated Red",
    HEX: "#7C5953",
    Collection: "Jotun Lady",
  },
  {
    Name: "12080 Soft Radiance",
    HEX: "#D9CCB0",
    Collection: "Jotun Lady",
  },
  {
    Name: "20207 Statement Red",
    HEX: "#A6544A",
    Collection: "Jotun Lady",
  },
  {
    Name: "20208 Poetry Red",
    HEX: "#9B6156",
    Collection: "Jotun Lady",
  },
  {
    Name: "5530 Collected Blue",
    HEX: "#99A6A5",
    Collection: "Jotun Lady",
  },
  {
    Name: "2489 Bella",
    HEX: "#D9916D",
    Collection: "Jotun Lady",
  },
  {
    Name: "7038 Dusk Green",
    HEX: "#C4C7B1",
    Collection: "Jotun Lady",
  },
  {
    Name: "8087 Vårluft",
    HEX: "#D6CBA4",
    Collection: "Jotun Lady",
  },
  {
    Name: "1520 Cheerful Peach",
    HEX: "#F0CFA6",
    Collection: "Jotun Lady",
  },
  {
    Name: "1931 Kokos",
    HEX: "#E3DDCD",
    Collection: "Jotun Lady",
  },
  {
    Name: "10678 Space",
    HEX: "#D6CFBF",
    Collection: "Jotun Lady",
  },
  {
    Name: "20186 Lavender Touch",
    HEX: "#BBB2AD",
    Collection: "Jotun Lady",
  },
  {
    Name: "1875 Sans",
    HEX: "#E6DFCE",
    Collection: "Jotun Lady",
  },
  {
    Name: "1303 Observe",
    HEX: "#CEBDA2",
    Collection: "Jotun Lady",
  },
  {
    Name: "0280 Caravan",
    HEX: "#CCB394",
    Collection: "Jotun Lady",
  },
  {
    Name: "1909 Kalkoker",
    HEX: "#C49F71",
    Collection: "Jotun Lady",
  },
  {
    Name: "11130 Shade",
    HEX: "#9D9385",
    Collection: "Jotun Lady",
  },
  {
    Name: "1876 Hvit Te",
    HEX: "#D4CBB9",
    Collection: "Jotun Lady",
  },
  {
    Name: "12179 Embrace",
    HEX: "#B58D6F",
    Collection: "Jotun Lady",
  },
  {
    Name: "1259 Rusty",
    HEX: "#A47251",
    Collection: "Jotun Lady",
  },
  {
    Name: "5516 Iron Blue",
    HEX: "#6F7677",
    Collection: "Jotun Lady",
  },
  {
    Name: "7236 Chi",
    HEX: "#F3F4EE",
    Collection: "Jotun Lady",
  },
  {
    Name: "6383 Imagine",
    HEX: "#ADC0B7",
    Collection: "Jotun Lady",
  },
  {
    Name: "6384 Wish",
    HEX: "#89A5A2",
    Collection: "Jotun Lady",
  },
  {
    Name: "6084 Sjøsmaragd",
    HEX: "#739592",
    Collection: "Jotun Lady",
  },
  {
    Name: "8565 Hope",
    HEX: "#AFAD88",
    Collection: "Jotun Lady",
  },
  {
    Name: "0125 Palmetto",
    HEX: "#758168",
    Collection: "Jotun Lady",
  },
  {
    Name: "20186 Lavender Touch",
    HEX: "#BBB2AC",
    Collection: "Jotun Lady",
  },
  {
    Name: "1775 Fresh Pasta",
    HEX: "#E9DAB1",
    Collection: "Jotun Lady",
  },
  {
    Name: "10235 Sommersol",
    HEX: "#E9CE8D",
    Collection: "Jotun Lady",
  },
  {
    Name: "3377 Slate Lavender",
    HEX: "#8E8687",
    Collection: "Jotun Lady",
  },
  {
    Name: "1376 Frostrøyk",
    HEX: "#E4E0D3",
    Collection: "Jotun Lady",
  },
  {
    Name: "12180 Present",
    HEX: "#B5AB9E",
    Collection: "Jotun Lady",
  },
  {
    Name: "12181 Soft Comfort",
    HEX: "#8A8176",
    Collection: "Jotun Lady",
  },
  {
    Name: "12182 Gentle Whisper",
    HEX: "#D2CBBF",
    Collection: "Jotun Lady",
  },
  {
    Name: "20185 Friendly Pink",
    HEX: "#C5B5A7",
    Collection: "Jotun Lady",
  },
  {
    Name: "20184 Thoughtful",
    HEX: "#B5A395",
    Collection: "Jotun Lady",
  },
  {
    Name: "2011 Antique Brass",
    HEX: "#BC9683",
    Collection: "Jotun Lady",
  },
  {
    Name: "2040 Lys Granitt",
    HEX: "#D3B9A5",
    Collection: "Jotun Lady",
  },
  {
    Name: "8281 Pale Linden",
    HEX: "#C6C6B6",
    Collection: "Jotun Lady",
  },
  {
    Name: "5504 Coastal Blue",
    HEX: "#6D7D83",
    Collection: "Jotun Lady",
  },
  {
    Name: "5503 Natural Blue",
    HEX: "#88959A",
    Collection: "Jotun Lady",
  },
  {
    Name: "4894 Ocean Air",
    HEX: "#9DAAAF",
    Collection: "Jotun Lady",
  },
  {
    Name: "6379 Cityscape",
    HEX: "#8C9893",
    Collection: "Jotun Lady",
  },
  {
    Name: "6378 Iconic",
    HEX: "#A1ABA6",
    Collection: "Jotun Lady",
  },
  {
    Name: "8118 Løvsal",
    HEX: "#C5C6B8",
    Collection: "Jotun Lady",
  },
  {
    Name: "12119 Vintage Brown",
    HEX: "#887D73",
    Collection: "Jotun Lady",
  },
  {
    Name: "1288 Granskygge",
    HEX: "#B9AF9B",
    Collection: "Jotun Lady",
  },
  {
    Name: "1563 Mørk Strandsand",
    HEX: "#CDC6B4",
    Collection: "Jotun Lady",
  },
  {
    Name: "20162 Mellow",
    HEX: "#8B7467",
    Collection: "Jotun Lady",
  },
  {
    Name: "1276 Soft",
    HEX: "#DCD1BA",
    Collection: "Jotun Lady",
  },
  {
    Name: "12126 Silhouette",
    HEX: "#BFB49C",
    Collection: "Jotun Lady",
  },
  {
    Name: "10385 Belgian Brown",
    HEX: "#7F7160",
    Collection: "Jotun Lady",
  },
  {
    Name: "12123 Contemporary White",
    HEX: "#E1DACA",
    Collection: "Jotun Lady",
  },
  {
    Name: "1392 Gul Antikk",
    HEX: "#D4B58B",
    Collection: "Jotun Lady",
  },
  {
    Name: "12118 Hummus",
    HEX: "#DACBB3",
    Collection: "Jotun Lady",
  },
  {
    Name: "20167 Welcoming Red",
    HEX: "#A06C5E",
    Collection: "Jotun Lady",
  },
  {
    Name: "12120 Desert Pink",
    HEX: "#BF9F86",
    Collection: "Jotun Lady",
  },
  {
    Name: "12127 Earthy Brown",
    HEX: "#6F5846",
    Collection: "Jotun Lady",
  },
  {
    Name: "12125 Impression",
    HEX: "#BBA78E",
    Collection: "Jotun Lady",
  },
  {
    Name: "1303 Observe",
    HEX: "#CEBDA2",
    Collection: "Jotun Lady",
  },
  {
    Name: "0486 Silke",
    HEX: "#EAE7DB",
    Collection: "Jotun Lady",
  },
  {
    Name: "1024 Tidløs",
    HEX: "#E0DDD2",
    Collection: "Jotun Lady",
  },
  {
    Name: "12124 Natural Clay",
    HEX: "#B07C5A",
    Collection: "Jotun Lady",
  },
  {
    Name: "7637 Exhale",
    HEX: "#B7BDB3",
    Collection: "Jotun Lady",
  },
  {
    Name: "11174 Curious Mind",
    HEX: "#B7A992",
    Collection: "Jotun Lady",
  },
  {
    Name: "8546 Local Green",
    HEX: "#948A6F",
    Collection: "Jotun Lady",
  },
  {
    Name: "11175 Adventure",
    HEX: "#A38269",
    Collection: "Jotun Lady",
  },
  {
    Name: "20145 Wisdom",
    HEX: "#615957",
    Collection: "Jotun Lady",
  },
  {
    Name: "11173 Humble Yellow",
    HEX: "#D3C5AB",
    Collection: "Jotun Lady",
  },
  {
    Name: "20142 Daydream",
    HEX: "#806A62",
    Collection: "Jotun Lady",
  },
  {
    Name: "4863 Statement Blue",
    HEX: "#395068",
    Collection: "Jotun Lady",
  },
  {
    Name: "5489 Free Spirit",
    HEX: "#6B7E7E",
    Collection: "Jotun Lady",
  },
  {
    Name: "20144 Grounded Red",
    HEX: "#92695A",
    Collection: "Jotun Lady",
  },
  {
    Name: "20143 Lively Red",
    HEX: "#A87362",
    Collection: "Jotun Lady",
  },
  {
    Name: "5490 Serene Blue",
    HEX: "#798484",
    Collection: "Jotun Lady",
  },
  {
    Name: "3377 Slate Lavender",
    HEX: "#8E8687",
    Collection: "Jotun Lady",
  },
  {
    Name: "12074 Peachy",
    HEX: "#C1A082",
    Collection: "Jotun Lady",
  },
  {
    Name: "20118 Amber Red",
    HEX: "#9B6C5C",
    Collection: "Jotun Lady",
  },
  {
    Name: "7613 Northern Mystic",
    HEX: "#696D61",
    Collection: "Jotun Lady",
  },
  {
    Name: "1024 Tidløs",
    HEX: "#E0DDD2",
    Collection: "Jotun Lady",
  },
  {
    Name: "10678 Space",
    HEX: "#D6CFBF",
    Collection: "Jotun Lady",
  },
  {
    Name: "12075 Soothing Beige",
    HEX: "#CAC0AF",
    Collection: "Jotun Lady",
  },
  {
    Name: "12076 Modern Beige",
    HEX: "#BFB3A3",
    Collection: "Jotun Lady",
  },
  {
    Name: "1622 Refleksjon",
    HEX: "#EBE9E1",
    Collection: "Jotun Lady",
  },
  {
    Name: "12077 Sheer Grey",
    HEX: "#CCC7BD",
    Collection: "Jotun Lady",
  },
  {
    Name: "12078 Comfort Grey",
    HEX: "#BEB9AF",
    Collection: "Jotun Lady",
  },
  {
    Name: "0394 Varmgrå",
    HEX: "#AFA99C",
    Collection: "Jotun Lady",
  },
  {
    Name: "7626 Airy Green",
    HEX: "#D8DBCF",
    Collection: "Jotun Lady",
  },
  {
    Name: "7627 Refresh",
    HEX: "#CACFC3",
    Collection: "Jotun Lady",
  },
  {
    Name: "7628 Treasure",
    HEX: "#A3A798",
    Collection: "Jotun Lady",
  },
  {
    Name: "7629 Antique Green",
    HEX: "#8A8F81",
    Collection: "Jotun Lady",
  },
  {
    Name: "12079 Gleam",
    HEX: "#E4DBC4",
    Collection: "Jotun Lady",
  },
  {
    Name: "12080 Soft Radiance",
    HEX: "#D9CCAF",
    Collection: "Jotun Lady",
  },
  {
    Name: "12081 Silky Yellow",
    HEX: "#CCBD9D",
    Collection: "Jotun Lady",
  },
  {
    Name: "12082 Refined Yellow",
    HEX: "#C2B08D",
    Collection: "Jotun Lady",
  },
  {
    Name: "10290 Soft Touch",
    HEX: "#DCD0BF",
    Collection: "Jotun Lady",
  },
  {
    Name: "12083 Devine",
    HEX: "#D2BEA9",
    Collection: "Jotun Lady",
  },
  {
    Name: "12084 Dusky Peach",
    HEX: "#C0A78D",
    Collection: "Jotun Lady",
  },
  {
    Name: "12085 Rural",
    HEX: "#AF957A",
    Collection: "Jotun Lady",
  },
  {
    Name: "20119 Transparent Pink",
    HEX: "#DACEC0",
    Collection: "Jotun Lady",
  },
  {
    Name: "12086 Rustic Pink",
    HEX: "#CAB7A7",
    Collection: "Jotun Lady",
  },
  {
    Name: "2024 Senses",
    HEX: "#BEA795",
    Collection: "Jotun Lady",
  },
  {
    Name: "20120 Organic Red",
    HEX: "#987A6A",
    Collection: "Jotun Lady",
  },
  {
    Name: "2727 Red Maple",
    HEX: "#795956",
    Collection: "Jotun Lady",
  },
  {
    Name: "20055 Dusty Rose",
    HEX: "#A0847A",
    Collection: "Jotun Lady",
  },
  {
    Name: "20054 Silky Pink",
    HEX: "#BDA79F",
    Collection: "Jotun Lady",
  },
  {
    Name: "2588 Myk/Artist Clay",
    HEX: "#CBC0B9",
    Collection: "Jotun Lady",
  },
  {
    Name: "5455 Industrial Blue",
    HEX: "#4E616C",
    Collection: "Jotun Lady",
  },
  {
    Name: "5044 Blåis/Icy Blue",
    HEX: "#808D92",
    Collection: "Jotun Lady",
  },
  {
    Name: "5200 Dusky Blue",
    HEX: "#9EADB2",
    Collection: "Jotun Lady",
  },
  {
    Name: "5452 Nordic Breeze Milano",
    HEX: "#BBC6C8",
    Collection: "Jotun Lady",
  },
  {
    Name: "5454 Dark Teal",
    HEX: "#506364",
    Collection: "Jotun Lady",
  },
  {
    Name: "6352 Evening Green",
    HEX: "#808985",
    Collection: "Jotun Lady",
  },
  {
    Name: "6350 Soft Teal",
    HEX: "#9AA49E",
    Collection: "Jotun Lady",
  },
  {
    Name: "6351 Tender Green",
    HEX: "#B7C1BD",
    Collection: "Jotun Lady",
  },
  {
    Name: "10963 Golden Bronze",
    HEX: "#887760",
    Collection: "Jotun Lady",
  },
  {
    Name: "10962 Sandy",
    HEX: "#A29179",
    Collection: "Jotun Lady",
  },
  {
    Name: "10961 Raw Canvas",
    HEX: "#BEAF97",
    Collection: "Jotun Lady",
  },
  {
    Name: "1024 Tidløs/Timeless",
    HEX: "#E0DDD2",
    Collection: "Jotun Lady",
  },
  {
    Name: "10964 Smoked Oak",
    HEX: "#6C6358",
    Collection: "Jotun Lady",
  },
  {
    Name: "10965 Hipster brown",
    HEX: "#857C6E",
    Collection: "Jotun Lady",
  },
  {
    Name: "10966 Almond Beige",
    HEX: "#A69A88",
    Collection: "Jotun Lady",
  },
  {
    Name: "10678 Space",
    HEX: "#D6CFBF",
    Collection: "Jotun Lady",
  },
  {
    Name: "10981 Norwegian Wood",
    HEX: "#7F5C47",
    Collection: "Jotun Lady",
  },
  {
    Name: "20046 Savanna Sunset",
    HEX: "#AF8A76",
    Collection: "Jotun Lady",
  },
  {
    Name: "20047 Blushing Peach",
    HEX: "#C3A590",
    Collection: "Jotun Lady",
  },
  {
    Name: "10580 Soft Skin",
    HEX: "#CBBBAC",
    Collection: "Jotun Lady",
  },
  {
    Name: "8494 Organic Green",
    HEX: "#646556",
    Collection: "Jotun Lady",
  },
  {
    Name: "8493 Green Tea",
    HEX: "#9F9B7C",
    Collection: "Jotun Lady",
  },
  {
    Name: "8302 Laurbær/Laurel",
    HEX: "#B0AE96",
    Collection: "Jotun Lady",
  },
  {
    Name: "8281 Pale Linden",
    HEX: "#C6C6B6",
    Collection: "Jotun Lady",
  },
  {
    Name: "1434 Elegant",
    HEX: "#605D58",
    Collection: "Jotun Lady",
  },
  {
    Name: "1877 Valmuefrø/Pebblestone",
    HEX: "#96938B",
    Collection: "Jotun Lady",
  },
  {
    Name: "1973 Antikkgrå/Objective",
    HEX: "#BEBCB2",
    Collection: "Jotun Lady",
  },
  {
    Name: "1391 Lys Antikkgrå/Bare",
    HEX: "#D4D3C8",
    Collection: "Jotun Lady",
  },
  {
    Name: "5249 Linblå",
    HEX: "#919DA0",
    Collection: "Jotun Lady",
  },
  {
    Name: "5030 St. Pauls Blue",
    HEX: "#697477",
    Collection: "Jotun Lady",
  },
  {
    Name: "7163 Minty Breeze",
    HEX: "#ACB2A8",
    Collection: "Jotun Lady",
  },
  {
    Name: "1145 Band Stone",
    HEX: "#7F7971",
    Collection: "Jotun Lady",
  },
  {
    Name: "1352 Form",
    HEX: "#9B948A",
    Collection: "Jotun Lady",
  },
  {
    Name: "10679 Washed Linen",
    HEX: "#C9C3B6",
    Collection: "Jotun Lady",
  },
  {
    Name: "9918 Klassisk Hvit",
    HEX: "#F0EFE9",
    Collection: "Jotun Lady",
  },
  {
    Name: "10683 Cashmere",
    HEX: "#B19C80",
    Collection: "Jotun Lady",
  },
  {
    Name: "1624 Letthet",
    HEX: "#F2F1E8",
    Collection: "Jotun Lady",
  },
  {
    Name: "1376 Frostrøyk",
    HEX: "#E4E0D3",
    Collection: "Jotun Lady",
  },
  {
    Name: "2782 Deco Pink",
    HEX: "#DBC4B7",
    Collection: "Jotun Lady",
  },
  {
    Name: "2995 Dusty Red",
    HEX: "#806159",
    Collection: "Jotun Lady",
  },
  {
    Name: "10835 Velvet Grey",
    HEX: "#766F66",
    Collection: "Jotun Lady",
  },
  {
    Name: "8478 Pale Green",
    HEX: "#BABAA5",
    Collection: "Jotun Lady",
  },
  {
    Name: "8479 Evergreen",
    HEX: "#787664",
    Collection: "Jotun Lady",
  },
  {
    Name: "2992 Delightful Pink",
    HEX: "#CBAB99",
    Collection: "Jotun Lady",
  },
  {
    Name: "1625 Sjel",
    HEX: "#ECE8D7",
    Collection: "Jotun Lady",
  },
  {
    Name: "1832 Skandinavisk Lys",
    HEX: "#E7DFCD",
    Collection: "Jotun Lady",
  },
  {
    Name: "10001 Dijon Yellow",
    HEX: "#A98259",
    Collection: "Jotun Lady",
  },
  {
    Name: "2993 Kilim",
    HEX: "#714F44",
    Collection: "Jotun Lady",
  },
  {
    Name: "1362 Olive Brown",
    HEX: "#7F644C",
    Collection: "Jotun Lady",
  },
  {
    Name: "2994 Heat",
    HEX: "#995F4D",
    Collection: "Jotun Lady",
  },
  {
    Name: "10428 Masala",
    HEX: "#C79F6E",
    Collection: "Jotun Lady",
  },
  {
    Name: "3343 Dusty Purple",
    HEX: "#7D6668",
    Collection: "Jotun Lady",
  },
];
export default colorOptions;
