import { Button, Modal, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { BsPhone } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
const PhoneNumberModal = () => {
  const { staticLanguage } = useSelector((state) => state.questionR);
  const [phoneNumberValue, setPhoneNumberValue] = useState(null);

  const [spinner, setSpinner] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    var phoneNumber = phoneNumberValue && phoneNumberValue.phone;
    var trimmedPhoneNumber = phoneNumber.replace(/^0+/, "");
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_ROOT_URL}/sendSMS`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          APIType: "web",
        }),
        body: JSON.stringify({
          url: window.location.href,
          number: `+${phoneNumberValue.dialCode}${trimmedPhoneNumber}`,
          language: JSON.parse(localStorage.getItem("language")) || "eng",
        }),
      })
        .then((response) => {
          response.json().then((response) => {
            if (response && response.status === "SUCCESS") {
              resolve();
              setIsModalOpen(false);
              if (JSON.parse(localStorage.getItem("language")) === "se") {
                message.success("SMS har skickats!");
              } else {
                message.success(response.message);
              }

              setSpinner(false);
            }
            if (response && response.status === "ERROR") {
              message.error(response.message);
              setSpinner(false);
            }
          });
        })
        .catch((err) => {
          setSpinner(false);
          reject();
        });
    }).catch(() => {
      setSpinner(false);
    });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setPhoneNumberValue({ phone: "" });
  }, [isModalOpen]);
  return (
    <>
      <p
        onClick={() => setIsModalOpen(true)}
        className="flex items-center gap-3 w-[fit-content] cursor-pointer"
      >
        <BsPhone /> {staticLanguage.resiveSms}
      </p>
      <Modal
        width="400px"
        centered
        footer={null}
        closable={false}
        title={staticLanguage.sendSMS}
        open={isModalOpen}
      >
        <PhoneInput
          country={"se"}
          masks={{ se: "...-... .. .." }}
          placeholder="070-123 45 67"
          disableCountryCode={true}
          onlyCountries={["se", "dk", "no", "co", "fi"]}
          value={phoneNumberValue && phoneNumberValue.phone}
          onChange={(phone, data) => {
            console.log("===phoneNumberValue==", phone, data);
            setPhoneNumberValue({ phone: phone, dialCode: data.dialCode });
          }}
          inputStyle={{ width: "100%" }}
        />
        <div className="flex gap-3 justify-end mt-5">
          <button
            onClick={() => handleCancel()}
            className="border rounded border-red-500 px-5 py-1 text-red-500"
          >
            {staticLanguage.cancel}
          </button>
          <button
            onClick={() => {
              if (
                phoneNumberValue &&
                phoneNumberValue.phone &&
                phoneNumberValue.phone.length > 6
              ) {
                setSpinner(true);
                handleOk();
              }
            }}
            className={`${
              phoneNumberValue &&
              phoneNumberValue.phone &&
              phoneNumberValue.phone.length > 6
                ? " bg-[#6239FD]"
                : "bg-zinc-300"
            } px-5 py-1 rounded text-[16px] font-bold text-white flex items-center gap-2`}
          >
            {spinner && <Spin size="small" />} {staticLanguage.send}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default PhoneNumberModal;
