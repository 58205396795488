import React, { useEffect, useState } from "react";
import StartOver from "../../assets/images/image.png";
import CementImage from "../../assets/images/download.jpeg";
import { Col, Collapse, Row, Spin, message } from "antd";
import { UpCircleOutlined } from "@ant-design/icons";
import Product from "../../assets/images/product.png";
import Bygghemma from "../../assets/images/bygghemma.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DataGetAction } from "../../redux/actions/CommonHttp";
import { RxCross2 } from "react-icons/rx";
import LogoSvg from "../../components/Logo";
import { BsArrowClockwise, BsFillShareFill } from "react-icons/bs";
import DeleteModal from "./DeleteModal";
import AllStoreIcon from "./allStoreIcon";
import PhoneNumberModal from "./phoneNumberModal";

const Index = () => {
  const [ShowShare, setShowShare] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const resultProps = useSelector((state) => state.questionR);
  const {
    finalResultCalculationObj,
    finalResultSpin,
    orgQData,
    calculatedObj,
    resultGuid,
    staticLanguage,
  } = resultProps;
  const { Panel } = Collapse;
  const panelStyle = {
    marginBottom: 12,
    borderRadius: "10px",
    border: "1px solid #ebebeb",
  };

  function round(num, decimalPlaces) {
    num = Math.round(num + "e" + decimalPlaces);
    num = Number(num + "e" + -decimalPlaces);
    num = num.toLocaleString();
    return num.replace(/,/g, " ");
  }

  const toFixValue = (inputNumber) => {
    if (inputNumber) {
      const decimalPart = inputNumber.toString().split(".")[1];

      if (decimalPart && decimalPart.length > 2) {
        return parseFloat(inputNumber).toFixed(2);
      } else {
        return inputNumber;
      }
    }
  };

  const renderCollapsTableList = (data) => {
    if (data && data.length > 0) {
      return data.map((item) => {
        return (
          <Row className="mb-4">
            <Col xl={16} lg={16} md={16} sm={16} xs={16}>
              <p>{item.title}</p>
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
              <p>
                {item && toFixValue(item.value)} {item.unit}
              </p>
            </Col>
          </Row>
        );
      });
    }
  };

  const renderTitle = (title) => {
    console.log("==== title =====", title);
    if (title && title.includes("/")) {
      return <p className="capitalize">{title}</p>;
    } else {
      return <p className="">{title}</p>;
    }
  };

  const renderPanels = () => {
    if (
      finalResultCalculationObj &&
      finalResultCalculationObj.collapse &&
      finalResultCalculationObj.collapse.length > 0
    ) {
      return finalResultCalculationObj.collapse.map((item, index) => {
        return (
          <Panel
            style={panelStyle}
            header={
              <div className="flex justify-between text-capitalize">
                {renderTitle(item.title)}{" "}
                {finalResultCalculationObj.collapse.length > 1 && (
                  <DeleteModal
                    item={item}
                    calculatedObj={calculatedObj}
                    guid={params.query}
                  />
                )}
              </div>
            }
            key={index}
          >
            <hr />
            {item.detail !== null && (
              <>
                {item.detail && item.detail.SquareMeters && (
                  <p className="mt-8 mb-1 font-semiBold">
                    {staticLanguage.squareMeters}: {item.detail.SquareMeters}
                  </p>
                )}
                {item.detail && item.detail.SpillageMaterial && (
                  <p className="mb-4 font-semiBold">
                    {item.detail.SpillageMaterial}
                  </p>
                )}
                {renderCollapsTableList(item.detail.list)}
              </>
            )}
          </Panel>
        );
      });
    }
  };

  const purchaseList = (data) => {
    if (data && data.length > 0) {
      return data.map((item) => {
        return (
          <>
            <Row className="mb-4">
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={24}
                xs={24}
                className="md:mb-0 mb-3"
              >
                <div className="flex gap-2 items-center">
                  <img
                    width="80px"
                    src={item.imageUrl !== "" ? item.imageUrl : Product}
                    className="border-2 p-2 rounded-lg"
                  />
                  <div>
                    <h4 className="font-semiBold">{item.title}</h4>
                    <p className="text-[#8e8e8e] font-semiBold">
                      {item.productDetail}
                    </p>
                  </div>
                </div>
              </Col>
              <Col
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="md:pl-0 pl-2"
              >
                <p className="md:text-center font-medium">
                  {item.quantity} {staticLanguage.pcs}
                </p>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <p className="text-center font-medium">
                  {round(item.price, 0)} {staticLanguage.sek}
                </p>
              </Col>
            </Row>
            <hr className="md:hidden block mb-4" />
          </>
        );
      });
    }
  };
  const fetchDataApi = () => {
    if (params && params.query) {
      dispatch(
        DataGetAction(
          "getCalculation",
          "getCalculation",
          {
            guid: params.query,
          },
          "getCalculation"
        )
      );
    }
  };
  useEffect(fetchDataApi, [params]);

  function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      message.success("URL is copied! Paste wherever you want");
      return navigator.clipboard.writeText(text);
    }
  }

  const renderTotal = () => {
    if (finalResultCalculationObj && finalResultCalculationObj.total) {
      let total = finalResultCalculationObj.total.toLocaleString();
      total = total.replace(/,/g, " ");

      return total;
    }
  };

  const AddMoreSurface = () => {
    dispatch({
      type: "Add_More_Surface",
      resultObj: calculatedObj,
      guid: resultGuid,
      questionData: orgQData,
    });
    navigate("/questions");
  };

  const addToCartFn = () => {
    if (
      finalResultCalculationObj &&
      finalResultCalculationObj.recommendedList
    ) {
      let url = "https://konkral.se/sv/shared-cart?";
      if (JSON.parse(localStorage.getItem("language")) === "eng") {
        url = "https://konkral.com/en/shared-cart?";
      }
      let parameter = "";
      if (
        finalResultCalculationObj &&
        finalResultCalculationObj.recommendedList
      ) {
        finalResultCalculationObj.recommendedList.map((item, index) => {
          let addAnd = "";
          if (index > 0) {
            addAnd = "&";
          }
          if(item.pc){
            parameter =
            parameter + `${addAnd}rows[${index}][pc]=${item.pc}`
          }
          if (item.color) {
            parameter =
              parameter +
              `${addAnd}rows[${index}][id]=${
                item.variantId
              }&rows[${index}][q]=${item.quantity}${
                item.color &&
                `&rows[${index}][ncs]=${item.color.Name};${item.color.HEX};${item.color.Collection}`
              }`;
            console.log("=====route 1====", parameter);
          }  else {
            parameter =
              parameter +
              `${addAnd}rows[${index}][id]=${item.variantId}&rows[${index}][q]=${item.quantity}`;
          }
        });
      }
      parameter = parameter + "&quote";
      let route = url + new URLSearchParams(parameter);
      window.open(route, "_blank");
    }
  };

  const renderResellerCard = (image, buttonText, route, addToCart = false) => {
    return (
      <div className="bg-white w-full border border-[#E5E5E5] flex flex-col items-center justify-center px-10 rounded hover:border-black">
        <div className="mb-5 mt-10">{image}</div>
        <button
          onClick={() => {
            if (route) {
              window.open(route, "_blank");
            } else {
              if (addToCart) {
                addToCartFn();
              } else {
                message.info("Comming Soon");
              }
            }
          }}
          className="bg-[#6239FD] h-[50px] w-full mb-5 rounded text-white font-bold text-[20px]"
        >
          {buttonText}
        </button>
      </div>
    );
  };
  let languageCheck =
    localStorage.getItem("language") &&
    JSON.parse(localStorage.getItem("language")) === "se"
      ? true
      : false;
  return (
    <>
      {finalResultSpin && (
        <div className="w-full h-screen flex items-center justify-center">
          <Spin size="large" />
        </div>
      )}
      {!finalResultSpin && (
        <div className="xl:container mx-auto px-10">
          <div className="flex justify-between">
            <LogoSvg />
            <p
              onClick={() => {
                dispatch({
                  type: "Finish_Later",
                  payload: orgQData,
                });
                window.open(
                  `${
                    languageCheck ? "https://konkral.se" : "https://konkral.com"
                  }`,
                  "_self"
                );
              }}
              className="font-bold text-[#909599] cursor-pointer flex items-center gap-1"
            >
              {staticLanguage.close} <RxCross2 size={20} />
            </p>
          </div>
          <div className="my-10">
            <div className="flex items-center justify-center">
              <div
                onClick={() => {
                  dispatch({
                    type: "Finish_Later",
                    payload: orgQData,
                  });
                  navigate("/");
                }}
                className="w-[120px] cursor-pointer  flex items-center gap-3"
              >
                <img src={StartOver} alt="" className="" width="30px" />
                <p className="font-medium">{staticLanguage.startOver}</p>
              </div>
            </div>
            <div className="flex md:flex-row flex-col my-10 gap-5 items-center px-10">
              <img
                src={CementImage}
                className="rounded-xl"
                width={150}
                alt=""
              />
              <div>
                <h2 className="text-[24px] font-semiBold">
                  {staticLanguage.resultPageTitle}
                </h2>
                <p className="font-medium text-[16px]">
                  {staticLanguage.resultContent}
                </p>
              </div>
            </div>
          </div>
          <div>
            <h2 className="text-center text-[20px] font-bold">
              {staticLanguage.perArea}
            </h2>
            <div className="my-10">
              <Collapse
                bordered={false}
                style={{
                  background: "transparent",
                }}
                expandIcon={({ isActive }) => (
                  <UpCircleOutlined rotate={isActive ? 180 : 0} />
                )}
                defaultActiveKey={[
                  finalResultCalculationObj &&
                    finalResultCalculationObj.collapse &&
                    finalResultCalculationObj.collapse[
                      finalResultCalculationObj.collapse.length - 1
                    ],
                ]}
              >
                {renderPanels()}
              </Collapse>
            </div>
            <div
              className="flex gap-2 items-center w-[fit-content] cursor-pointer"
              onClick={() => AddMoreSurface()}
            >
              <BsArrowClockwise />
              <p className="font-medium">{staticLanguage.additionalAreas}</p>
            </div>
            <h4 className="text-center text-[20px] font-bold my-10">
              {staticLanguage.purchaseList}
            </h4>
            {purchaseList(
              finalResultCalculationObj &&
                finalResultCalculationObj.recommendedList
            )}
          </div>
          <h4 className="font-regular text-center mt-10 text-[18px]">
            {staticLanguage.total}:{" "}
            <span className="font-semiBold">
              {renderTotal()} {staticLanguage.sek}
            </span>
          </h4>
          <h4 className="font-regular text-center mb-10 text-[16px]">
            {staticLanguage.priceIncl}
          </h4>
          <div className="my-10">
            <h2 className="text-center text-[20px] font-bold mb-10">
              {staticLanguage.resellers}
            </h2>
            <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4">
              {renderResellerCard(
                <div className="h-[100px] flex items-center">
                  <LogoSvg width="180px" />
                </div>,
                staticLanguage.addToCart,
                false,
                true
              )}
              {/* {renderResellerCard(
                <div className="h-[100px] flex items-center">
                  <img src={Bygghemma} width={"100%"} />
                </div>,
                staticLanguage.goToStore,
                "https://www.bygghemma.se/varumarken/konkral"
              )} */}
              {renderResellerCard(
                <AllStoreIcon width="180px" />,
                staticLanguage.showAllResellers,
                "https://konkral.se/sv/page/aterforsaljare"
              )}
            </div>
          </div>
          <div className="mb-5">
            {ShowShare && (
              <>
                <div className="flex flex-col items-center justfu-center gap-2">
                  <p
                    onClick={() => copyTextToClipboard(window.location.href)}
                    className="flex items-center gap-3 w-[fit-content] cursor-pointer"
                  >
                    <BsFillShareFill /> {staticLanguage.copyLink}
                  </p>
                  <PhoneNumberModal />
                </div>
              </>
            )}
          </div>
          <div className="flex justify-center mt-5 mb-5">
            {!ShowShare && (
              <button
                onClick={() => {
                  setShowShare(true);
                }}
                className="bg-white px-3 py-2 font-bold"
              >
                {staticLanguage.shareCollection}
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Index;
