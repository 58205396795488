import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import Services from "./components/Services";
import RadioCard from "./components/RadioCard";
import CheckBoxCard from "./components/CheckBoxCard";
// import BorderButton from "../../components/Button/BorderButton";
import FillButton from "../../components/Button/FillButton";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import SelectRange from "./components/SelectRange";
import CardRadio from "./components/CardRadio";
import CardCheckBox from "./components/CardCheckBox";
import ColorCard from "./components/ColorCard";
import PlaceholderImageCard from "./components/PlaceholderImageCard";

import OtherTypeCards from "./components/OtherTypeCards";
import { DataRequestAction } from "../../redux/actions/CommonHttp";
import { Spin } from "antd";
import LogoSvg from "../../components/Logo";
import SubServices from "./components/SubServices";
const Index = ({ popover, width }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const QuestionsProps = useSelector((state) => state.questionR);
  const {
    SelectedQuestion,
    previousQuestionId,
    ResultObj,
    mainServices,
    currentSubServices,
    wizardComplete,
    totalPage,
    questionNum,
    finalResult,
    addNewCalculation_success,
    materialCalculationLinks,
    addCalculationSpin,
    resultGuid,
    staticLanguage,
    questionArray,
  } = QuestionsProps;
  // const { staticObj } = staticLanguage && staticLanguage[0];
  const CookiesData = Cookies.get("userInfo");
  console.log("======== ResultObj", ResultObj);
  console.log("======== language", staticLanguage);

  console.log("======== ResultObj BackQuestion Array", questionArray, currentSubServices);

  const [ip, setIp] = useState();

  const getIp = async () => {
    // Connect ipapi.co with fetch()
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    // Set the IP address to the constant `ip`
    setIp(data.ip);
  };
  useEffect(() => {
    getIp();
  }, []);

  useEffect(() => {
    if (wizardComplete) {
      let currentDate = new Date();
      const options = {
        timeZone: "Europe/Stockholm", // Swedish time zone (GMT+2)
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };

      const swedishTime = new Intl.DateTimeFormat("sv-SE", options).format(currentDate);

      if (CookiesData === undefined) {
        navigate("/captureEmail");
      } else {
        let obj = JSON.parse(CookiesData);
        const data = {
          calculationObj: finalResult,
          orgObj: ResultObj,
          fullName: obj.fullName,
          email: obj.email,
          userData: {
            ip: ip,
            date: currentDate,
            swedishTime: swedishTime,
          },
        };

        if (resultGuid) {
          data["guid"] = resultGuid;
          dispatch(DataRequestAction("PUT", "updateCalculation", data, "addNewCalculation", "", "", false));
        } else {
          dispatch(DataRequestAction("POST", "addNewCalculation", data, "addNewCalculation", "", "", false));
        }
      }
      dispatch({ type: "wizardComplete_RESET" });
    }
    if (addNewCalculation_success) {
      if (materialCalculationLinks !== null) {
        navigate(`/result/${materialCalculationLinks}`);
        dispatch({ type: "addNewCalculation_RESET" });
      }
    }
  }, [wizardComplete, addNewCalculation_success]);

  useEffect(() => {
    if (!SelectedQuestion && !wizardComplete) {
      console.log("======Finish_Later SelectedQuestion", SelectedQuestion, wizardComplete);
      dispatch({
        type: "Finish_Later",
      });
    }
  }, [SelectedQuestion]);

  const showPageNumbers = () => {
    if (totalPage && totalPage > 0) {
      return (
        <span className="font-semiBold text-stone-500 text-lg md:block hidden">
          {staticLanguage.question} {questionNum} / {totalPage}
        </span>
      );
    }
  };
  const showMobilePageNumber = () => {
    if (totalPage && totalPage > 0) {
      return (
        <span className="font-semiBold text-stone-500 text-lg md:hidden block">
          {questionNum} / {totalPage}
        </span>
      );
    }
  };
  const showBackArrow = () => {
    if (totalPage && totalPage > 0) {
      return (
        <div
          onClick={() => {
            dispatch({
              type: "PreviousQuestion",
            });
          }}
          className="flex items-center gap-2 cursor-pointer"
        >
          <BiArrowBack size={20} />
          <span className="text-lg font-bold">{staticLanguage.goBack}</span>
        </div>
      );
    }
  };

  const showProgressBar = () => {
    if (totalPage && totalPage > 0) {
      return (
        <div className="w-full bg-stone-300 rounded-full h-2.5 dark:bg-stone-300 my-10">
          <div
            className="bg-[#6239FD] h-2.5 rounded-full"
            style={{
              width: `${questionNum < 10 ? questionNum * 10 : 100}%`,
              transition: "all 2s",
            }}
          ></div>
          <p
            style={{
              paddingLeft: `${questionNum < 10 ? questionNum * 10 - 3 : 100}%`,
              transition: "all 2s",
            }}
          >
            {questionNum < 10 ? questionNum * 10 : 100}%
          </p>
        </div>
      );
    }
  };
  console.log("====== ip =======", ip);

  const redirectPageOtherWeb = () => {
    if (localStorage.getItem("language")) {
      if (JSON.parse(localStorage.getItem("language")) === "se") {
        window.open("https://konkral.se", "_self");
      } else {
        window.open("https://konkral.com", "_self");
      }
    }
  };

  const header = (data) => {
    return (
      <div className="pb-5">
        <div className="flex items-center justify-between flex-wrap">
          <LogoSvg />
          {showPageNumbers()}
          {!popover && (
            <span
              onClick={() => {
                dispatch({ type: "Finish_Later" });
                redirectPageOtherWeb();
              }}
              className="font-semiBold text-stone-500 text-lg md:flex gap-2 cursor-pointer"
            >
              <span className="md:block hidden ">{staticLanguage.close}</span>{" "}
              <span
                onClick={() => {
                  dispatch({ type: "Finish_Later" });
                  redirectPageOtherWeb();
                }}
              >
                x
              </span>
            </span>
          )}
        </div>
        {showProgressBar()}
        <div className="flex items-center justify-between flex-wrap">
          {showBackArrow()}

          {showMobilePageNumber()}
        </div>
      </div>
    );
  };
  console.log("===== SelectedQuestion 1 =====", SelectedQuestion);
  const showNextQuestion = () => {
    if (SelectedQuestion && SelectedQuestion.value) {
      dispatch({
        type: `${SelectedQuestion.reduxType}_SUCCESS`,
        payload: SelectedQuestion,
      });
    }
  };

  const renderButtons = () => {
    console.log("====== SelectedQuestion", SelectedQuestion);
    if (SelectedQuestion && SelectedQuestion.type)
      return (
        <div className="pt-5 mb-10 flex gap-3 items-center">
          <div className="sm:w-[370px] w-full">
            <FillButton
              disabled={SelectedQuestion && !SelectedQuestion.value}
              onClick={() => showNextQuestion()}
              className="customButton w-full"
            >
              {staticLanguage.nextQuestion}
            </FillButton>
          </div>
          {SelectedQuestion.showSkip && (
            <div>
              <p className="font-medium underline cursor-pointer" onClick={() => skipQuestion()}>
                {staticLanguage.skipButton}
              </p>
            </div>
          )}
        </div>
      );
  };

  const renderQuestion = (data) => {
    if (data) {
      console.log("===== data.type ====", data);
      if (data.type === "Dynamic_subService") {
        return <SubServices detail={data} renderButtons={renderButtons} />;
      } else if (data.type === "CardCheckBox") {
        return <Services detail={data} renderButtons={renderButtons} />;
      } else if (data.type === "radio") {
        return <RadioCard detail={data} renderButtons={renderButtons} />;
      } else if (data.type === "radioCards") {
        return <CardRadio detail={data} renderButtons={renderButtons} />;
      } else if (data.type === "checkBoxCards") {
        return <CardCheckBox detail={data} renderButtons={renderButtons} />;
      } else if (data.type === "colorCards") {
        return <ColorCard detail={data} renderButtons={renderButtons} />;
      } else if (data.type === "PlaceholderImageCard") {
        return <PlaceholderImageCard detail={data} renderButtons={renderButtons} />;
      } else if (data.type === "checkbox") {
        return <CheckBoxCard detail={data} renderButtons={renderButtons} />;
      } else if (data.type === "range") {
        return <SelectRange detail={data} renderButtons={renderButtons} />;
      } else if (data.type === "selectOther") {
        return <OtherTypeCards detail={data} renderButtons={renderButtons} />;
      } else {
        return (
          <div className="w-full h-full flex items-center justify-center">
            <Spin />
          </div>
        );
      }
    }
  };

  const skipQuestion = () => {
    dispatch({
      type: `justMoveToNext_SUCCESS`,
      payload: SelectedQuestion,
      skip: true,
    });
  };

  const showNav = (data) => {
    const renderType = (bathroomTypeShow) => {
      let bathroomType = bathroomTypeShow;
      if (localStorage.getItem("language")) {
        if (JSON.parse(localStorage.getItem("language")) === "se") {
          if (bathroomType === "new") {
            bathroomType = "Nytt";
          } else if (bathroomType === "existing") {
            bathroomType = "Befintligt";
          }
        } else {
          if (bathroomType === "new") {
            bathroomType = "New";
          } else if (bathroomType === "existing") {
            bathroomType = "Existing";
          }
        }
      }
      return bathroomType;
    };
    if (
      ResultObj &&
      ResultObj[mainServices] &&
      ResultObj[mainServices]["ServiceTitle"] &&
      ResultObj[mainServices]["bathroomType"] &&
      currentSubServices &&
      currentSubServices.title
    ) {
      return (
        <p className="flex gap-1 capitalize font-semiBold text-lg mb-3">
          <span>{ResultObj[mainServices]["ServiceTitle"]}</span> /
          <span>{renderType(ResultObj[mainServices]["bathroomType"])}</span> /<span>{currentSubServices.title}</span>
        </p>
      );
    }
  };

  return (
    <div className={`px-10 ${width ? width : "xl:w-[1172px]"} lg:w-full md:w-[700px] mx-auto`}>
      {header(SelectedQuestion)}
      {showNav(SelectedQuestion)}
      {renderQuestion(SelectedQuestion)}
    </div>
  );
};

export default Index;
