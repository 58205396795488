import React from "react";

const DeleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="-70px"
      viewBox="0 0 16 15"
      width={"20px"}
      height={"20px"}
    >
      <g>
        <path d="M8.5,15C4.916,15,2,12.084,2,8.5S4.916,2,8.5,2S15,4.916,15,8.5S12.084,15,8.5,15z M8.5,3C5.467,3,3,5.467,3,8.5   C3,11.532,5.467,14,8.5,14c3.032,0,5.5-2.468,5.5-5.5C14,5.467,11.532,3,8.5,3z" />
        <path d="M6,11.5c-0.128,0-0.256-0.049-0.354-0.146c-0.195-0.195-0.195-0.512,0-0.707l5-5c0.195-0.195,0.512-0.195,0.707,0   s0.195,0.512,0,0.707l-5,5C6.256,11.451,6.128,11.5,6,11.5z" />
        <path d="M11,11.5c-0.128,0-0.256-0.049-0.354-0.146l-5-5c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0l5,5   c0.195,0.195,0.195,0.512,0,0.707C11.256,11.451,11.128,11.5,11,11.5z" />
      </g>
      <text
        x="0"
        y="31"
        fill="#000000"
        font-size="5px"
        font-weight="bold"
        font-family="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"
      >
        Created by icon 54
      </text>
      <text
        x="0"
        y="36"
        fill="#000000"
        font-size="5px"
        font-weight="bold"
        font-family="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"
      >
        from the Noun Project
      </text>
    </svg>
  );
};

export default DeleteIcon;
