import { Checkbox, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import colorOptions from "./colorOptions";
const ColorCard = ({ detail, renderButtons }) => {
  const { question, content, checkBoxLabel, placeholder } = detail;
  const dispatch = useDispatch();

  const { SelectedQuestion } = useSelector((state) => state.questionR);

  const staticOption = [
    {
      Name: "Sand",
      HEX: "#BFB3A3",
      Collection: "Konkral",
    },
    {
      Name: "Mineral",
      HEX: "#E2D7C5",
      Collection: "Konkral",
    },
    {
      Name: "Dream",
      HEX: "#C4C1B9",
      Collection: "Konkral",
    },
    {
      Name: "Balance",
      HEX: "#ABAAA8",
      Collection: "Konkral",
    },
    {
      Name: "Night",
      HEX: "#505051",
      Collection: "Konkral",
    },
    {
      Name: "Spirit",
      HEX: "#8A8F81",
      Collection: "Konkral",
    },
    {
      Name: "Royal",
      HEX: "#6D7D83",
      Collection: "Konkral",
    },
    {
      Name: "Peach",
      HEX: "#B58D6F",
      Collection: "Konkral",
    },
  ];
  const onChangeSetValue = (data) => {
    dispatch({
      type: "SetValueInSelectedQuestion",
      payload: data,
    });

    dispatch({
      type: "justMoveToNext_SUCCESS",
      payload: SelectedQuestion,
    });
  };

  const radioButtons = (Name, HEX, Collection) => {
    console.log("====== SelectedQuestion ====", SelectedQuestion);
    let checked =
      SelectedQuestion &&
      SelectedQuestion.value &&
      SelectedQuestion.value.Name === Name
        ? true
        : false;

    return (
      <div
        onClick={() => onChangeSetValue({ Name, HEX, Collection })}
        className={`border rounded-lg w-full hover:border-black flex gap-2 cursor-pointer p-3 flex items-center  gap-2 ${
          checked
            ? "border-black  border-2 bg-[#E6EEF5]"
            : "bg-white border border-[#00000033]"
        }`}
      >
        <span
          style={{ background: HEX }}
          className={`w-10 h-10   rounded-[10px] border`}
        ></span>
        <div>
          <p className="text-[16px] font-medium mb-[-5px]">{Name}</p>
          <p className="text-[12px] text-[#959595] font-medium">{Collection}</p>
        </div>
      </div>
    );
  };

  const renderButton = () => {
    if (staticOption && staticOption.length > 0) {
      return staticOption.map((Item) => {
        const { Name, HEX, Collection, checked } = Item;
        return radioButtons(Name, HEX, Collection, checked);
      });
    }
  };

  const renderOptions = () => {
    if (colorOptions && colorOptions.length > 0) {
      return colorOptions.map((item) => {
        return (
          <Select.Option className="flex flex-col ml-[35px]" value={item.HEX}>
            {item.Name}
            <br />
            {item.Collection}
            <div
              style={{ backgroundColor: item.HEX }}
              className="h-[35px] w-[35px] border rounded absolute top-2 left-[-30px]"
            ></div>
          </Select.Option>
        );
      });
    }
  };

  return (
    <div className="roll-out-div-fade">
      <h2 className="md:text-[32px] text-[24px] font-bold roll-out-text">
        {question}
      </h2>
      <p className="text-[#00000099] mt-4 roll-out-text">{content}</p>
      <div className="roll-out-div">
        <div className="grid gap-4 lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-10">
          {renderButton()}
        </div>
        <div className="flex flex-col gap-5 mt-10">
          <Select
            placeholder={placeholder}
            showSearch={true}
            optionFilterProp="children"
            className="md:w-[250px] w-full"
            onChange={(e) => {
              let selectedValue = colorOptions.filter((Item) => Item.HEX === e);
              onChangeSetValue(selectedValue[0]);
            }}
          >
            {renderOptions()}
          </Select>
          <Checkbox
            onChange={(e) => onChangeSetValue({ skip: true })}
            value={"skip"}
          >
            {checkBoxLabel}
          </Checkbox>
        </div>
        {renderButtons()}
      </div>
    </div>
  );
};

export default ColorCard;
