import React from "react";

const FillButton = ({
  children,
  className = "",
  onClick,
  disabled,
  bg = "bg-[#6239FD]",
}) => {
  const bgColor = disabled ? "bg-[#E7EEF5]" : bg;
  const colorValue = disabled ? "text-[#CFD5DC]" : "text-white";
  const activeColor = disabled ? "active:[#E7EEF5]" : "active:bg-zinc-800";
  return (
    <button
      disabled={disabled}
      onClick={() => onClick()}
      className={`${bgColor} ${colorValue} ${activeColor} ${
        disabled ? "" : className
      }   text-[18px] font-bold rounded h-12 w-full`}
    >
      {children}
    </button>
  );
};

export default FillButton;
