import perarea from "./perarea.json";
import recommendationArray from "./recomendedList.json";
import questionDataEn from "./question.json";
import questionDataSe from "./questionSe.json";
import staticLanguageEng from "./staticLanguageEng.json";
import staticLanguageSe from "./staticLanguageSe.json";
export const addDataInQuestionArray = (
  mainServices,
  subServices,
  qId,
  oldData
) => {
  let newData = null;
  if (oldData) {
    if (mainServices === 1 && subServices) {
      if (oldData[mainServices][subServices.title]) {
        oldData[mainServices][subServices.title].push(qId);
      } else {
        oldData[mainServices][subServices.title] = [qId];
      }
    } else {
      if (mainServices === 1) {
        if (oldData[mainServices] && oldData[mainServices]["questionData"]) {
          oldData[mainServices]["questionData"].push(qId);
        } else {
          oldData[mainServices] = { questionData: [qId] };
        }
      } else {
        if (oldData[mainServices]) {
          oldData[mainServices].push(qId);
        } else {
          oldData[mainServices] = [qId];
        }
      }
    }
    newData = oldData;
  } else {
    if (mainServices === 1) {
      newData = { [mainServices]: { questionData: [qId] } };
    } else {
      newData = { [mainServices]: [qId] };
    }
  }
  return newData;
};
export const getCopyOfOriginalQuestion = (language) => {
  let data = [];
  if (language === "eng") {
    data = structuredClone(questionDataEn);
  } else {
    data = structuredClone(questionDataSe);
  }

  return [...data];
};
export const getStaticObj = (language) => {
  let data = null;
  if (language === "eng") {
    data = structuredClone(staticLanguageEng);
  } else {
    data = structuredClone(staticLanguageSe);
  }

  return data;
};
export const findClosestAndSmallest = (arr, number) => {
  let closestValue = Infinity;
  let smallestValue = Infinity;

  for (let i = 0; i < arr.length; i++) {
    const diff = Math.abs(arr[i] - number);

    if (diff < Math.abs(closestValue - number)) {
      closestValue = arr[i];
    }

    if (arr[i] < smallestValue) {
      smallestValue = arr[i];
    }
  }

  return { closestValue, smallestValue };
};

export const findRecommendation = (weightRequired, packageSizes) => {
  packageSizes.sort((a, b) => a - b);

  let totalPackages = 0;
  let remainingWeight = weightRequired;
  const usedPackages = {};

  for (let i = packageSizes.length - 1; i >= 0; i--) {
    const packageSize = packageSizes[i];

    if (packageSize <= remainingWeight) {
      const packagesUsed = Math.floor(remainingWeight / packageSize);
      totalPackages += packagesUsed;
      remainingWeight -= packagesUsed * packageSize;

      if (usedPackages[packageSize]) {
        usedPackages[packageSize] += packagesUsed;
      } else {
        usedPackages[packageSize] = packagesUsed;
      }
    }
  }

  // If there's still remaining weight and the smallest package size can fulfill it, use it
  if (remainingWeight > 0) {
    let ClosetSmallValue = findClosestAndSmallest(
      packageSizes,
      remainingWeight
    );
    let extrapackage = ClosetSmallValue.closestValue;
    if (extrapackage > 0 && usedPackages[extrapackage]) {
      usedPackages[extrapackage] = usedPackages[extrapackage] + 1;
    } else {
      usedPackages[extrapackage] = 1;
    }
  }

  return { totalPackages, usedPackages };
};

const addValuesByTitle = (arr) => {
  console.log("===== arr =====", arr);
  const result = {};

  arr.forEach((item) => {
    console.log("===== item ====", item);
    let color = "";
    if (item.color) {
      color = item.color.Name;
    }
    if (result[`${item.title}${color}`]) {
      result[`${item.title}${color}`].value += item.value;
    } else {
      result[`${item.title}${color}`] = { ...item };
    }
  });

  return Object.values(result);
};

function calculateTotalPrice(items) {
  let totalPrice = 0;

  items.forEach((item) => {
    const price = parseFloat(item.price); // Convert price to a number
    totalPrice += price;
  });

  return totalPrice; // Convert the result to a string with 2 decimal places
}

export const performRecommendation = (data) => {
  console.log("=====totalRecommended data", data);
  let totalServicesSelect = Object.keys(data);
  let listOfArray = [];
  let recommendationList = [];
  let totalPrice = 0;

  if (totalServicesSelect && totalServicesSelect.length > 0) {
    totalServicesSelect.map((Item) => {
      let listArray = data[Item]["detail"]["list"];
      listOfArray = [...listOfArray, ...listArray];
    });
  }

  console.log("======= listOfArray ======");
  if (listOfArray && listOfArray.length > 0) {
    listOfArray = addValuesByTitle(listOfArray);
  }

  if (listOfArray && listOfArray.length > 0) {
    console.log("======totalRecommended listOfArray", listOfArray);

    listOfArray.map((Item) => {
      let getRecommendationFilter = recommendationArray.filter(
        (x) => x.productName === Item.title
      );
      console.log(
        "====== getRecommendationFilter",
        getRecommendationFilter,
        Item
      );
      let color = "";

      if (Item.color) {
        color = Item.color.Name;
      }
      if (getRecommendationFilter && getRecommendationFilter.length > 0) {
        let weightRequired = Item.value;
        let getRecommendationObj = getRecommendationFilter[0];
        let packageSizes = getRecommendationObj["packaging"];
        console.log("======totalRecommended packageSizes", packageSizes);
        let recommendationResult = findRecommendation(
          weightRequired,
          packageSizes
        );
        console.log(
          "======= totalRecommended recommendationResult",
          recommendationResult
        );

        let totalRecommended = Object.keys(recommendationResult.usedPackages);
        console.log(
          "======= totalRecommended",
          getRecommendationObj,
          totalRecommended
        );
        if (totalRecommended && totalRecommended.length > 0) {
          totalRecommended.map((single) => {
            let findObjValue = getRecommendationObj[single];
            let title = findObjValue.name;
            if (color !== "") {
              title = title + " - " + color;
            }
            let obj = {
              imageUrl: findObjValue.imageUrl,
              variantId: findObjValue.variantId,
              title: title,
              productDetail: `SKU  ${findObjValue.artNo} `,
              quantity: recommendationResult.usedPackages[single],
              price:
                parseInt(recommendationResult.usedPackages[single]) *
                parseFloat(findObjValue.price),
            };

            if (color) {
              obj["color"] = Item.color;
            }

            if(findObjValue.pc){
              obj["pc"] = findObjValue.pc;
            }

            recommendationList.push(obj);
          });
        }
      }
    });
  }

  if (recommendationList && recommendationList.length > 0) {
    totalPrice = calculateTotalPrice(recommendationList);
  }

  return { recommendationList, totalPrice };
};

const commonCalculation = (serviceData) => {
  console.log("====== serviceData", serviceData);
  let calculatedObj = { title: `${serviceData.ServiceTitle}` };
  let detail = { SquareMeters: serviceData.sqm };
  let list = [];
  let recommendationList = [];
  let testList = [];
  let primerValue = perarea[0];
  let primerValueCalculation = primerValue.value * serviceData.sqm;
  let extraMaterialObj = null;
  let primerObj = {
    title: `${primerValue.ProductType}`,
    value: primerValueCalculation,
    unit: primerValue.Measurement,
  };

  //====== Preparation Layer Calculation =======
  let preparation_layer = serviceData.preparation_layer;
  let filterPreparationPerArea = perarea.filter(
    (Item) => Item["Key"] === preparation_layer
  );

  if (filterPreparationPerArea && filterPreparationPerArea.length > 0) {
    filterPreparationPerArea.map((Item) => {
      let calObj = Item.value * serviceData.sqm;
      let color = "";

      let obj = {
        title: Item.ProductType,
        value: calObj,
        unit: Item.Measurement,
      };

      if (
        Item.addColor &&
        serviceData.colorSelected &&
        serviceData.colorSelected.Name
      ) {
        obj["color"] = serviceData.colorSelected;
      }
      list.push(obj);
    });
  }

  //====== microcement_sil Calculation =======
  if (serviceData.microcement_sil) {
    let microcement_sil = serviceData.microcement_sil;

    let filtermicrocementSil = perarea.filter(
      (Item) => Item["Key"] === microcement_sil
    );
    let filtermicrocementSilForAll = perarea.filter(
      (Item) => Item["Key"] === "P1-P6"
    );

    let totalSil = [...filtermicrocementSil, ...filtermicrocementSilForAll];
    console.log("===== totalSil", totalSil);
    if (totalSil && totalSil.length > 0) {
      totalSil.map((Item) => {
        let calObj = Item.Usage;
        let unit = JSON.parse(localStorage.getItem("language")) === "se" ? Item.Measurement_se : Item.Measurement;

        let obj = {
          variantId: Item.variantId,
          title: Item.ProductType,
          value: calObj,
          unit: unit,
        };
        list.push(obj);
      });
    }
  }

  //============ moreKonkralBase value base on B ========
  let moreKonkralBase = serviceData.moreKonkralBase;
  if (serviceData.moreKonkralBase && moreKonkralBase !== "skip") {
    let filterMoreKonkralBase = perarea.filter((Item) => Item["Key"] === "B");

    if (filterMoreKonkralBase && filterMoreKonkralBase.length > 0) {
      filterMoreKonkralBase.map((Item) => {
        let calObj = parseFloat(moreKonkralBase) * parseFloat(serviceData.sqm);
        let obj = {
          title: Item.ProductType,
          value: calObj,
          unit: Item.Measurement,
        };
        list.push(obj);
      });
    }
  }

  //========= Final Finish =======
  let finalFinish = serviceData.finalFinish;
  if (finalFinish !== "skip") {
    let filterFinalFinish = perarea.filter(
      (Item) => Item["Key"] === finalFinish
    );

    if (filterFinalFinish && filterFinalFinish.length > 0) {
      filterFinalFinish.map((Item) => {
        let calObj = Item.value * serviceData.sqm;
        let obj = {
          title: Item.ProductType,
          value: calObj,
          unit: Item.Measurement,
        };

        if (
          Item.addColor &&
          serviceData.colorSelected &&
          serviceData.colorSelected.Name
        ) {
          obj["color"] = serviceData.colorSelected;
        }

        list.push(obj);
      });
    }
  }

  //========== Make Application easier =====
  let makeApplicationEasier = serviceData.makeApplicationEasier;

  if (makeApplicationEasier !== "skip") {
    let getValueOfSelected = [];
    if (makeApplicationEasier && makeApplicationEasier.length > 0) {
      makeApplicationEasier.map((Item) => {
        if (Item.checked) {
          getValueOfSelected.push(Item.value);
        }
      });
    }

    if (getValueOfSelected && getValueOfSelected.length > 0) {
      getValueOfSelected.map((singleMakeApplicationEasier) => {
        let filterMakeApplicationEasier = perarea.filter(
          (Item) => Item["Key"] === singleMakeApplicationEasier
        );

        if (
          filterMakeApplicationEasier &&
          filterMakeApplicationEasier.length > 0
        ) {
          filterMakeApplicationEasier.map((Item) => {
            let calObj = Item.value * serviceData.sqm;
            let obj = {
              title: Item.ProductType,
              value: calObj,
              unit: Item.Measurement,
            };
            list.push(obj);
          });
        }
      });
    }
  }

  //=================== surfaceProtection ============

  let filterPreSealer = perarea.filter((Item) => Item["Key"] === "P");

  if (filterPreSealer && filterPreSealer.length > 0) {

    filterPreSealer.map((Item) => {
      let calObj = Item.value * serviceData.sqm;
      let obj = {
        title: Item.ProductType,
        value: calObj,
        unit: Item.Measurement,
      };
      list.push(obj);
    });
  }

  let surfaceProtection = serviceData.surfaceProtection;
  if (surfaceProtection !== "skip") {
    let filterSurfaceProtection = perarea.filter(
      (Item) => Item["Key"] === surfaceProtection
    );

    if (filterSurfaceProtection && filterSurfaceProtection.length > 0) {
      filterSurfaceProtection.map((Item) => {
        let calObj = Item.value * serviceData.sqm;
        let obj = {
          title: Item.ProductType,
          value: calObj,
          unit: Item.Measurement,
        };
        list.push(obj);
      });
    }
  }
  //=================== extraMaterial ============
  let extraMaterial = serviceData.extraMaterial;
  console.log("========= serviceData", serviceData);

  if (extraMaterial !== "skip") {
    let filterExtraMaterial = perarea.filter(
      (Item) => Item["Key"] === extraMaterial
    );

    if (filterExtraMaterial && filterExtraMaterial.length > 0) {
      filterExtraMaterial.map((Item) => {
        //======= This is in percentage
        extraMaterialObj = {
          title: "Spillage material",
          value: Item.value,
          unit: "%",
        };
        testList = structuredClone(list);
        testList.map((singleItem) => {
          if (Item.addPercentageFor.includes(singleItem.title)) {
            let percentageCal = (Item.value / 100) * singleItem.value;
            let newValue = singleItem.value + percentageCal;
            singleItem.value = newValue;
          }
        });
      });
    }
  } else {
    testList = list;
  }

  let ListObj = [primerObj];

  //======== Modify List =========
  console.log("========== listxxx", extraMaterialObj);
  detail["list"] = [...ListObj, ...testList];

  detail["list"] = addValuesByTitle(detail["list"]);

  let checkLenguagae =
    JSON.parse(localStorage.getItem("language")) === "se" ? true : false;

  let title = checkLenguagae
    ? "Extra för spill - Ej valt"
    : "Extra material: Not selected";

  if (extraMaterialObj) {
    let extValue = {
      L: checkLenguagae
        ? "5% av enbart microcement"
        : "5% extra of all products",
      M: checkLenguagae
        ? "10% av enbart microcement"
        : "10% extra of all products",
      N: checkLenguagae
        ? "5% av alla produkter"
        : "5% extra of only the microcement",
      o: checkLenguagae
        ? "10% av alla produkter"
        : "10% extra of only the microcement",
    };
    detail["SpillageMaterial"] = `${
      checkLenguagae ? "Extra för spill" : "Extra material"
    }: ${extValue[serviceData.extraMaterial]}`;
  } else {
    detail["SpillageMaterial"] = title;
  }

  calculatedObj["detail"] = detail;
  console.log("===== detail =====", detail);
  return calculatedObj;
};

const groupKeysByServiceTitle = (serviceArray, obj) => {
  const groupedKeys = {};

  serviceArray.forEach((service) => {
    const serviceTitle = service.title;
    const keysContainingServiceTitle = Object.keys(obj).filter((key) =>
      key.includes(serviceTitle)
    );

    const groupedObj = {};
    keysContainingServiceTitle.forEach((key) => {
      const newKey = key.replace(`_${serviceTitle}`, "");
      groupedObj[newKey] = obj[key];
    });

    groupedKeys[serviceTitle] = groupedObj;
  });

  return groupedKeys;
};

export const calculateMaterial = (data) => {
  console.log("====== calculateMaterial", data);
  const getServiceKEy = Object.keys(data);

  let FinalResult = [];

  if (getServiceKEy && getServiceKEy.length > 0) {
    getServiceKEy.map((Item) => {
      let serviceData = null;
      if (Item == 1) {
        let servicesSelected = data[Item].bathroomService;
        let bathroomType = data[Item].bathroomType;
        console.log("==== bathroomType", data[Item].bathroomType);
        if (localStorage.getItem("language")) {
          if (JSON.parse(localStorage.getItem("language")) === "se") {
            if (bathroomType === "new") {
              bathroomType = "Nytt";
            } else if (bathroomType === "existing") {
              bathroomType = "Befintligt";
            }
          }
        }

        let serviceObj = groupKeysByServiceTitle(servicesSelected, data[Item]);
        let keysFromObj = Object.keys(serviceObj);

        if (keysFromObj && keysFromObj.length > 0) {
          keysFromObj.map((x) => {
            serviceData = serviceObj[x];
            let ServiceTitle = `${
              data[Item] && data[Item].ServiceTitle
            } / ${bathroomType} / ${x}`;
            serviceData.ServiceTitle = ServiceTitle;
            const calculatedObj = commonCalculation(serviceData);

            FinalResult.push(calculatedObj);
          });
        }
      } else {
        serviceData = data[Item];
        const calculatedObj = commonCalculation(serviceData);
        FinalResult.push(calculatedObj);
      }
    });
  }
  console.log("=======calculateMaterial ", FinalResult);
  return FinalResult;
};

export const mergeObj = (oldData, newItem) => {
  let data = [];
  if (oldData) {
    data = { ...oldData, ...newItem };
  } else {
    data = { ...newItem };
  }
  return data;
};

export const addDataInArray = (oldData, newItem) => {
  let data = [];
  if (oldData && oldData.length > 0) {
    data = [...oldData, newItem];
  } else {
    data = [newItem];
  }
  return data;
};
export const getNextQuestionByNextId = (
  questionData,
  nextQuestionId,
  value
) => {
  let nextQuestion = null;
  if (questionData && questionData.length > 0) {
    questionData.map((Item) => {
      if (Item._id === nextQuestionId) {
        nextQuestion = Item;
      }
    });
  }
  return nextQuestion;
};
export const addSelectedData = (oldData, newData) => {
  let selectedDataArray = [];
  if (oldData && oldData.length > 0) {
    selectedDataArray = oldData;
    selectedDataArray.push(newData);
  } else {
    selectedDataArray.push(newData);
  }
  return selectedDataArray;
};
export const changeRangeValue = (oldData, newData) => {
  if (oldData._id === newData.id) {
    oldData.value = newData.value;
  }
  return oldData;
};

export const setValueInQuestion = (selectedQuestion, value) => {
  selectedQuestion["value"] = value;
  return selectedQuestion;
};
export const changeSelectedRadioValue = (oldData, questionData, newData) => {
  let selectedOption = null;
  let selectedQuestion = oldData;
  oldData.options.map((Item) => {
    if (Item.value === newData) {
      Item.checked = true;
      selectedOption = Item;
    } else {
      Item.checked = false;
    }
  });
  let newSelectedQuestion = null;
  if (selectedQuestion) {
    questionData.map((Item) => {
      if (Item._id === selectedQuestion.nextQuestionId) {
        newSelectedQuestion = Item;
      }
    });
  }

  return { newSelectedQuestion, previousId: selectedQuestion._id };
};
export const changeSelectedCheckValue = (oldData, newData) => {
  oldData.options.map((Item) => {
    if (Item._id === newData.id) {
      Item.checked = newData.checked;
    }
  });
  return oldData;
};
export const getCheckBoxValue = (selectedQuestion) => {
  let selectedValue = [];
  selectedQuestion.options.map((Item) => {
    if (Item.checked) {
      selectedValue.push(Item);
    }
  });
  return selectedValue;
};
export const setCheckBoxValue = (selectedQuestion, value) => {
  selectedQuestion.options.map((Item) => {
    if (Item.value === value) {
      if (Item["checked"]) {
        Item["checked"] = false;
      } else {
        Item["checked"] = true;
      }
    }
  });
  return selectedQuestion;
};

export const getNextQuestion = (
  SelectedQuestion,
  startedServices,
  selectedServices,
  selectedServicesQuestionAndOption,
  questionData
) => {
  let newQuestion = null;
  let serviceStartedId = null;
  let selectedServicesQuestionAndOptionNew = null;
  if (startedServices) {
    serviceStartedId = startedServices;
    //======= get Services Options
    let questionsArray = questionData.options.filter(
      (x) => x._id === serviceStartedId
    );
    let getIndexOfSelectedQuestion = -1;
    if (SelectedQuestion.parentId) {
      getIndexOfSelectedQuestion = questionsArray[0].questions.findIndex(
        (x) => x._id === SelectedQuestion.parentId
      );
    } else {
      getIndexOfSelectedQuestion = questionsArray[0].questions.findIndex(
        (x) => x._id === SelectedQuestion._id
      );
    }
    if (getIndexOfSelectedQuestion > -1) {
      let nextQuestionIndex = getIndexOfSelectedQuestion + 1;

      if (nextQuestionIndex < questionsArray[0].questions.length) {
        if (
          selectedServicesQuestionAndOption &&
          selectedServicesQuestionAndOption[serviceStartedId]
        ) {
          selectedServicesQuestionAndOption[serviceStartedId].push(
            SelectedQuestion
          );
          selectedServicesQuestionAndOptionNew =
            selectedServicesQuestionAndOption;
        } else {
          selectedServicesQuestionAndOptionNew = {
            [serviceStartedId]: [SelectedQuestion],
          };
        }
        newQuestion = questionsArray[0].questions[nextQuestionIndex];
        if (newQuestion && newQuestion.dependencyId) {
          let parentQuestion = selectedServicesQuestionAndOption[
            serviceStartedId
          ].filter((x) => x._id === newQuestion.dependencyId);
          let dependentValue = parentQuestion[0].options.filter(
            (x) => x.checked
          );
          if (newQuestion && newQuestion.dependencyIdValue) {
            let filterDependancyByValue = dependentValue.filter(
              (x) => x.value === newQuestion.dependencyIdValue
            );
            if (filterDependancyByValue.length === 0) {
              if (nextQuestionIndex + 1 < questionsArray[0].questions.length) {
                newQuestion =
                  questionsArray[0].questions[nextQuestionIndex + 1];
              } else {
                console.log("======== Question Array Complete =======");
              }
            } else {
              newQuestion = questionsArray[0].questions[nextQuestionIndex];
              newQuestion = newQuestion[newQuestion.dependencyIdValue];
            }
          } else {
            let valueFind = dependentValue[0].value;
            newQuestion = newQuestion[valueFind];
          }
        }
      }
    }
  } else {
    serviceStartedId = selectedServices[0];
    let questionsArray = questionData.options.filter(
      (x) => x._id === serviceStartedId
    );
    selectedServicesQuestionAndOptionNew = { serviceStartedId: [] };
    newQuestion = questionsArray[0].questions[0];
  }

  return {
    newQuestion,
    serviceStartedId,
    selectedServicesQuestionAndOptionNew,
  };
};
