import React from "react";
import { useSelector } from "react-redux";

const SubServices = ({ detail, renderButtons }) => {
  const { question, options, topContent_1 } = detail;
  const { currentSubServices, staticLanguage } = useSelector(
    (state) => state.questionR
  );
  console.log("===== currentSubServices ====", currentSubServices);
  return (
    <div className="roll-out-div-fade">
      <h2 className="md:text-[32px] text-[24px] font-bold roll-out-text">
        {currentSubServices && currentSubServices.title}{" "}
        {staticLanguage && staticLanguage.SubAreabathroom}
      </h2>
      <p className="text-[#00000099] w-full mb-5 mt-4">
        {staticLanguage && staticLanguage.SubAreaContent_1}{" "}
        {currentSubServices && currentSubServices.title}{" "}
        {staticLanguage && staticLanguage.SubAreaContent_2
          ? staticLanguage.SubAreaContent_2
          : ""}
      </p>

      <div className="roll-out-div">{renderButtons()}</div>
    </div>
  );
};

export default SubServices;
