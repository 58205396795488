import React from "react";
import { useDispatch } from "react-redux";
import placeholderImage from "../../../assets/images/placeholderImage.png";
import Card from "../../../components/Card";
const PlaceholderImageCard = ({ detail, renderButtons }) => {
  const { question, content, options, linkDescription, linkUrl } = detail;
  const dispatch = useDispatch();
  const radioButtons = (content, value, description, note, imageUrl) => {
    return (
      <div
        className="w-full mb-10 p-3 cursor-pointer"
        onClick={() => {
          let type = "SelectedValueRadio";
          dispatch({
            type: type,
            payload: value,
            detail,
          });
        }}
      >
        <Card
          bg="bg-[#fefefe]"
          className="h-full"
          imageUrl={
            imageUrl ? require(`../../../assets/images/${imageUrl}`) : ""
          }
          Item={{ title: content, cardDescription: description }}
        />
      </div>
    );
  };
  const renderButton = () => {
    if (options && options.length > 0) {
      return options.map((Item) => {
        const { title, value, description, note, imageUrl } = Item;
        return radioButtons(title, value, description, note, imageUrl);
      });
    }
  };
  return (
    <div className="roll-out-div-fade">
      <h2 className="md:text-[32px] text-[24px] font-bold mb-6 roll-out-text">
        {question}
      </h2>
      <div className="roll-out-div">
        <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 my-10">
          {renderButton()}
        </div>
        {linkDescription !== "" && (
          <p
            onClick={() => {
              if (linkUrl) {
                window.open(linkUrl, "_blank");
              }
            }}
            className="text-blue-500 font-medium cursor-pointer mb-10"
          >
            {linkDescription}
          </p>
        )}
        <p className="text-[#00000099]  mb-10">{content}</p>
        {renderButtons()}
      </div>
    </div>
  );
};

export default PlaceholderImageCard;
