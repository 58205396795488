import React from "react";
import { Radio } from "antd";
import { useDispatch } from "react-redux";

const RadioCard = ({ detail, renderButtons }) => {
  const {
    question,
    content,
    options,
    defaultChecked,
    reduxType,
    topContent_1,
    topContent_2,
    linkDescription,
    linkUrl,
  } = detail;

  const dispatch = useDispatch();

  const radioButtons = (content, radioName, checked, topContect) => {
    return (
      <div
        className={`rounded-lg w-full px-6  gap-2 cursor-pointer  ${
          checked
            ? "border-black  border-2 bg-[#E6EEF5]"
            : "bg-white border border-[#00000033]"
        }`}
      >
        <Radio
          value={radioName}
          className="w-full h-[60px] items-center relative  flex"
        >
          <p
            className={`text-blue-500 bg-[#F0F8FF]  font-semiBold px-2 rounded absolute z-[1px] left-[-10px] top-[-12px] cursor-pointer `}
          >
            {topContect}
          </p>
          <span>{content}</span>
        </Radio>
      </div>
    );
  };

  const renderButton = () => {
    if (options && options.length > 0) {
      return options.map((Item) => {
        const { title, value, checked, topContect } = Item;
        return <>{radioButtons(title, value, checked, topContect)}</>;
      });
    }
  };

  return (
    <div className="roll-out-div-fade">
      <h2
        className={`md:text-[32px] text-[24px] font-bold roll-out-text ${
          topContent_1 ? "mb-5" : "mb-10"
        }`}
      >
        {question}
      </h2>
      {topContent_1 && (
        <p className="text-[#00000099] w-full mb-4 roll-out-text">
          {topContent_1}
        </p>
      )}
      {topContent_2 && (
        <p className="text-[#00000099] w-full mb-10 roll-out-text">
          {topContent_2}
        </p>
      )}
      <div className="roll-out-div">
        <Radio.Group
          defaultValue={defaultChecked}
          onChange={(e) => {
            let type = "SelectedValueRadio";
            if (reduxType) {
              type = `${reduxType}_SUCCESS`;
            }
            dispatch({
              type: type,
              payload: e.target.value,
              detail,
            });
          }}
          className="grid gap-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mb-10"
        >
          {renderButton()}
        </Radio.Group>

        {content !== "" && (
          <p className="text-[#00000099] w-full mb-10">{content}</p>
        )}
        {linkDescription !== "" && (
          <p
            onClick={() => {
              if (linkUrl) {
                window.open(linkUrl, "_blank");
              }
            }}
            className="text-blue-500 font-medium cursor-pointer mb-10"
          >
            {linkDescription}
          </p>
        )}
        {renderButtons()}
      </div>
    </div>
  );
};

export default RadioCard;
