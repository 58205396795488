import { Modal } from "antd";
import React from "react";
import {
  calculateMaterial,
  performRecommendation,
} from "../../redux/reducers/commonFunctions";
import { DataGetAction } from "../../redux/actions/CommonHttp";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "./deleteIcon";
const DeleteModal = ({ item, calculatedObj, guid }) => {
  const dispatch = useDispatch();
  const resultProps = useSelector((state) => state.questionR);
  const { staticLanguage } = resultProps;
  const error = (item) => {
    Modal.error({
      maskClosable: true,
      title: staticLanguage.removeCollection,
      okText: staticLanguage.delete,
      okType: "danger",
      cancelText: staticLanguage.cancel,
      confirmLoading: true,
      content: staticLanguage.deleteContent,
      onOk() {
        let filteredData = null;
        let filteredJsonData = null;

        if (item.title && item.title.includes("/")) {
          let removeKey = item.title.split("/")[2];
          let serviceObj = calculatedObj[1];
          for (var key in serviceObj) {
            if (
              serviceObj.hasOwnProperty(key) &&
              key.endsWith(removeKey.trim())
            ) {
              delete serviceObj[key];
            }
          }
          let newServices = [];
          serviceObj.bathroomService.map((Item) => {
            if (Item.title !== removeKey.trim()) {
              newServices.push(Item);
            }
          });
          serviceObj.bathroomService = newServices;
          if (newServices.length > 0) {
            calculatedObj[1] = serviceObj;
            filteredData = calculatedObj;
            filteredJsonData = calculatedObj;
          } else {
            delete calculatedObj[1];
            filteredData = calculatedObj;
            filteredJsonData = calculatedObj;
          }
        } else {
          filteredData = Object.keys(calculatedObj)
            .filter((key) => calculatedObj[key].ServiceTitle !== item.title)
            .map((key) => ({ [key]: calculatedObj[key] }));

          filteredJsonData = Object.assign({}, ...filteredData);
        }

        let calculatedMaterial = calculateMaterial(filteredJsonData);
        let getRecommendationList = performRecommendation(calculatedMaterial);

        let final = {
          serviceName: "Bathroom",
          collapse: calculatedMaterial,
          recommendedList: getRecommendationList.recommendationList,
          total: getRecommendationList.totalPrice,
        };

        const dataFinal = {
          calculationObj: final,
          orgObj: filteredJsonData,
          guid: guid,
        };

        return new Promise((resolve, reject) => {
          //     let calculatedMaterial = calculateMaterial(data);
          // let getRecommendationList = performRecommendation(calculatedMaterial);

          fetch(`${process.env.REACT_APP_ROOT_URL}/updateCalculation`, {
            method: "PUT",
            headers: new Headers({
              "Content-Type": "application/json",
              APIType: "web",
            }),
            body: JSON.stringify(dataFinal),
          })
            .then((response) => {
              response.json().then((response) => {
                dispatch(
                  DataGetAction(
                    "getCalculation",
                    "getCalculation",
                    {
                      guid: guid,
                    },
                    "getCalculation"
                  )
                );

                resolve();
              });
            })
            .catch((err) => {
              reject();
            });
        }).catch(() => {});
      },
      onCancel() {},
    });
  };
  return (
    <div onClick={() => error(item)} className="cursor-pointer">
      <DeleteIcon />
    </div>
  );
};

export default DeleteModal;
