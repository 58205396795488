import React from "react";
import { useDispatch } from "react-redux";
import Card from "../../../components/Card";
import { BsCheckCircleFill } from "react-icons/bs";
const CardCheckBox = ({ detail, renderButtons }) => {
  const { question, content, options, linkDescription, topContent_1, linkUrl } =
    detail;
  const dispatch = useDispatch();

  const onChange = (value) => {
    let type = "checkBoxCardCheck_SUCCESS";
    dispatch({
      type: type,
      payload: value,
      detail,
    });
  };

  const renderCardRadioButtons = () => {
    if (options && options.length > 0) {
      return options.map((Item) => {
        const { value, checked, cardTopLink, imageUrl } = Item;
        return (
          <div className="relative mt-10">
            <p className="text-blue-500 font-semiBold  mb-2">{cardTopLink}</p>
            <Card
              className={
                checked
                  ? "drop-shadow-2xl bg-white border-black h-full"
                  : "border-zinc-200 h-full"
              }
              imageUrl={
                imageUrl ? require(`../../../assets/images/${imageUrl}`) : ""
              }
              onClick={() => onChange(value)}
              Item={Item}
            />
            {checked && (
              <div className="absolute top-10 right-2">
                <BsCheckCircleFill size={20} />
              </div>
            )}
          </div>
        );
      });
    }
  };
  return (
    <div className="roll-out-div-fade">
      <h2 className="md:text-2xl text-lg font-bold mb-4 roll-out-text">
        {question}
      </h2>
      {topContent_1 !== "" && (
        <p className="text-[#00000099] w-full roll-out-text">{topContent_1}</p>
      )}
      <div className="roll-out-div">
        <div className="grid gap-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mb-10">
          {renderCardRadioButtons()}
        </div>

        {content !== "" && (
          <p className="text-[#00000099] w-full mb-10">{content}</p>
        )}

        {linkDescription !== "" && (
          <p
            onClick={() => {
              if (linkUrl) {
                window.open(linkUrl, "_blank");
              }
            }}
            className="text-blue-500 font-semiBold cursor-pointer py-7"
          >
            {linkDescription}
          </p>
        )}
        {renderButtons()}
      </div>
    </div>
  );
};

export default CardCheckBox;
