import React from "react";
import { useDispatch } from "react-redux";
import Card from "../../../components/Card";

const CardRadio = ({ detail, renderButtons }) => {
  const {
    question,
    content,
    options,
    linkDescription,
    reduxType,
    topContent_1,
    topContent_2,
    topContent_3,
    linkUrl,
  } = detail;
  const dispatch = useDispatch();

  const onChange = (value) => {
    let type = "SelectedValueRadio";
    if (reduxType) {
      type = `${reduxType}_SUCCESS`;
    }
    dispatch({
      type: type,
      payload: value,
      detail,
    });
  };

  const renderCardRadioButtons = () => {
    if (options && options.length > 0) {
      return options.map((Item) => {
        const { value, checked, cardTopLink, imageUrl } = Item;

        return (
          <div>
            <p className="text-blue-500 font-semiBold  mb-2">{cardTopLink}</p>
            <Card
              className={
                checked
                  ? "drop-shadow-2xl bg-white border-black h-full"
                  : "border-zinc-200 h-full"
              }
              imageUrl={
                imageUrl ? require(`../../../assets/images/${imageUrl}`) : ""
              }
              onClick={() => onChange(value)}
              Item={Item}
            />
          </div>
        );
      });
    }
  };
  return (
    <div className="roll-out-div-fade">
      <h2 className="md:text-2xl text-lg font-bold roll-out-text">
        {question}
      </h2>
      {topContent_1 !== "" && (
        <p className="text-[#00000099] w-full mb-5 mt-5 roll-out-text">
          {topContent_1}
        </p>
      )}
      {topContent_2 !== "" && (
        <p className="text-[#00000099] w-full mb-5 mt-5 roll-out-text">
          {topContent_2}
        </p>
      )}
      {topContent_3 !== "" && (
        <p className="text-[#00000099] w-full mb-5 mt-5 roll-out-text">
          {topContent_3}
        </p>
      )}
      <div className="roll-out-div">
        <div className="grid gap-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 my-10">
          {renderCardRadioButtons()}
        </div>
        {content !== "" && (
          <p className="text-[#00000099] w-full mb-10">{content}</p>
        )}
        {linkDescription !== "" && (
          <p
            onClick={() => {
              if (linkUrl) {
                window.open(linkUrl, "_blank");
              }
            }}
            className="text-blue-500 font-semiBold cursor-pointer my-8"
          >
            {linkDescription}
          </p>
        )}
        {renderButtons()}
      </div>
    </div>
  );
};

export default CardRadio;
